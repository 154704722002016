<div [class.sidebar-close]="!openSidebar">
  <ul class="nav-links" id="nav-links">
    <li class="hamburger">
      <div class="dropdown-title" style="padding-top: 10px">
        <ng-template [ngIf]="mobile" [ngIfElse]="hamburger">
          <mat-icon (click)="closeNav()"  aria-label="Side nav toggle icon">close</mat-icon>
        </ng-template>
        <ng-template #hamburger>
          <mat-icon style="cursor:none" aria-label="Side nav toggle icon">menu</mat-icon>
        </ng-template>
      </div>
    </li>
    <li class="nav-link" *ngFor="let item of menu"  routerLinkActive="active">
      <div (mouseenter)="activateSubmenu($event,subMenu)" *ngIf="item.child.length > 0" class="dropdown-title">
        <mat-icon>{{ item.icon }}</mat-icon>
        <span class="label">{{ getNewMenuName(item.header) }}</span>
        <!-- <i class='bx bxs-chevron-down arrow'></i> -->
      </div>
      <ul class="sub-menu nav-links" #subMenu>
        <!--<li class="top-line"><span class="header">{{item.header}}</span></li>-->
        <li *ngFor="let item_sub of item.child" routerLinkActive="active" (click)="closeNav()" class="nav-link">
          <span *ngIf="item_sub.name === 'Amazon'" class="label"
                (click)="performMarketPlaceRouting(item_sub)">
            Authorize {{item_sub.name}}
          </span>
          <span *ngIf="item_sub.name !== 'Amazon' " class="label" (click)="performRouting(item_sub)">
            {{item_sub.name}}
          </span>
        </li>
      </ul>
    </li>
  </ul>
</div>
