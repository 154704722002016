<div class="fs-section-block fs-margin-0 header-background ">
  <div class="d-flex justify-content-between align-items-center">
    <div [ngClass]="currentClass" [ngStyle]="getHeadingStyle()">
      <span class="mat-headline">{{Heading}}</span>
      <p *ngIf="helpText" class="fs-hint-text">{{helpText}}</p>
    </div>
    <div class="d-flex align-items-center" style="gap: 10px;">
      <button *ngIf="showFilterButton" style="height: fit-content;" (click)="showFilters()"
        class="mat-button mat-primary mat-raised-button">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
        Filters
      </button>
      <button *ngIf="Heading === 'BSPL Store Replenishment Plan'" style="height: fit-content;" (click)="regenerate()"
      class="mat-button mat-primary mat-raised-button"  [disabled]="isButtonDisabled">Generate In-Store Replenishment
    </button>
      <app-upload-molecule *ngIf="showTemplateBtn"
        [templateData]="templateData"></app-upload-molecule>
    </div>
  </div>
</div>