export const subHeadings=
    {
        "Rate of Sale - Style": "Sales Momentum",
        "Distribution Plan":"Actionable report on maintaining right inventory across channels",
        "Buffer Penetration Report":"A comprehensive report on System Inventory Health",
        "Rationing Report":"Actionable report on maintaining right inventory across channels",
        "Inventory Health (SOH) By Design": "Macroscopic view of availability across the Supply Chain",
        "Inventory Trends": "Macroscopic view of availability across the Supply Chain",
        "Dynamic Norm Review": "Optimized Inventory Design across the System",
        "Broken Set Report": "Analyze NOOS (Never Out of Stock) products",
        "Inventory Health (SOH+PO) By Design": "Macroscopic view of availability across the Supply Chain",
        "Production Intent Planning": "A Production Planning guide for Maximum Availability",
        "Production Intent": "An Optimized Production Plan based on RM Availability",
        "RM Procurement Plan": "RM Procurement Plan for the planned Work Orders",
        "Purchase Order Report": "Procurement Guide for Maximum Availability & Inventory Turns",
        "Supplier Dispatch Report": "A Prioritized plan for Suppliers to Availability",
        "Supplier Network Design": "A Complete Supplier Network Design",
        "Revenue Analyzer - Partners": "Macroscopic view of retail points performance",
        "Sales Order Prioritization": "Optimizing Sales Strategy for Sales Agents"
    }