<div class="sync-fusion-main-container">
  <app-header-content-help-text [Heading]="displayNameForLeftGraph.header"
    [helpText]="subHeadings[displayNameForLeftGraph.header]"></app-header-content-help-text>

  <div class="text-right fs-margin-0-1">
    <span>Reports are generated as on <strong>{{ generatedDate | date }}</strong></span>
  </div>
  <div class="grid-container">
    <app-syncfusion-grid *ngIf="dynamicHeaders" (dataEmitForSalesEvent)="chartTypeRecived($event)"
      [dynamic_headers]="dynamicHeaders" [data]="gridData" [reportName]="displayNameForLeftGraph.header"
      (navigateGrid)="navigateGrid($event)"></app-syncfusion-grid>
  </div>

  <ejs-dialog class="modal rounded" *ngIf="dialogVisible" id="dialog" #ejDialog [isModal]="true" width="90%"
    height="100%" [visible]="dialogVisible">
    <h1 class="header-background">Rate of Sale and Discount offered <button (click)="onOverlayClick()" type="button"
        class="close" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button></h1>
    <app-pu-ros-molecule [data]="rowData" [code]="rowData.code"></app-pu-ros-molecule>
  </ejs-dialog>

  <ejs-dialog class="modal rounded" *ngIf="dialogVisibleForAccordion" id="dialog" #ejDialog [isModal]="true" width="90%"
    height="100%" [visible]="dialogVisibleForAccordion">
    <button (click)="onOverlayClick()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="accordion">
      <app-pu-accordian [data]="rowData"></app-pu-accordian>
    </div>
  </ejs-dialog>
</div>