import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ReportsService } from "src/app/services/reports.service";

@Component({
  selector: "page-not-authorised",
  templateUrl: "./page-not-authorised.html",
  styleUrls: ["./page-not-authorised.scss"],
})
export class PageNotAuthorisedMoleculeComponent implements OnInit {
  

  constructor() { }
  ngOnInit(): void {
  }

}
