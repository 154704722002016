<div class="reconciliation-container">
    <app-header-content-help-text [Heading]="reportNameWithSpace"
        [helpText]="'Track your uploaded file status here'"></app-header-content-help-text>
    <ejs-grid #grid class="full-width-table m-3" [dataSource]='dataSource' [allowPaging]='true' [allowFiltering]='true'
        [allowSorting]='true' [filterSettings]='filterOption' [allowSelection]='false' [enableHover]='false'
        [enableHeaderFocus]='true'>
        <e-columns>
            <ng-container *ngFor="let header of dynamicHeaders">
                <div *ngIf="header.actions;then actionsTemplate else default"></div>
                <ng-template #actionsTemplate>
                    <e-column [field]="header.field" [headerText]="header.headerText"
                        [headerTextAlign]="header.field === 'Download' ? 'center' : 'left'"
                        [allowFiltering]="header.allowFiltering" [width]="header.width">
                        <ng-template #template let-data>
                            <div *ngIf="header.field === 'Download'" class="download-column cursor-pointer">
                                <i *ngIf="!data.status.includes('Delete')" class="bi bi-download"
                                    style="cursor: pointer;" (click)="downloadFile(data)"></i>
                                <i *ngIf="data.status.includes('Delete')" class="bi bi-ban" title="Cannot download"></i>
                            </div>
                        </ng-template>
                    </e-column>
                </ng-template>
                <ng-template #default>
                    <e-column *ngIf="header.field !== 'recordDate'" [field]="header.field"
                        [headerText]="header.headerText" [width]="header.width" [filter]="header.filter"
                        [allowFiltering]="header.allowFiltering">
                        <ng-template #template let-data>
                            <div matTooltip="{{data[header.field]}}" class="tooltip-text">
                                {{data[header.field]}}
                            </div>
                        </ng-template>
                    </e-column>
                    <e-column *ngIf="header.field == 'recordDate'" [field]="header.field"
                        [headerText]="header.headerText" [width]="header.width"
                        [allowFiltering]="header.allowFiltering"> <ng-template #template let-data>
                            {{ formatDate(data.recordDate) }}
                        </ng-template>
                    </e-column>
                </ng-template>
            </ng-container>
        </e-columns>
    </ejs-grid>
</div>