<div class="sync-fusion-main-container partners-main-container">
    <div class="fs-section-block fs-margin-0 header-background ">
        <div class="toolbar-container">
            <div [ngClass]="currentClass" [ngStyle]="getHeadingStyle()">
                <span class="mat-headline">{{Heading}}</span>
                <p class="fs-hint-text">{{helpText}}</p>
            </div>
            <div class="search-container" *ngIf="partnersListHeaders.length > 0">
                <input matInput [(ngModel)]="searchPartnerText" (input)="filterPartners()"
                    placeholder="Search Partners">
                <mat-icon style="cursor: pointer;" matSuffix
                    (click)="searchPartnerText ? clearPartnerSearch() : filterPartners()">{{
                    searchPartnerText ? 'close' : 'search'}}</mat-icon>
            </div>
        </div>
    </div>
    <div class="grid-container" *ngIf="partnersListHeaders?.length > 0">
        <app-syncfusion-grid [reportName]="" [dynamic_headers]="partnersListHeaders" [count]="count" [data]="partnersList"
        *ngIf="tableDataFetched" [freezeColumns]="0" (navigateGrid)="navigateGrid($event)"></app-syncfusion-grid>
   </div>
</div>