import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { api } from "../properties/endpoints";
@Injectable({
  providedIn: 'root'
})
export class Distribution2Service {
  private baseUrl = `${api.host}/planning/in-store/distribution/config`;
  private valueUrl = `${api.host}/material/filter-values/attribute/`

  constructor(private http: HttpClient) {}

  // Fetch list of configurations
  getDistributionConfigList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/list`);
  }

  // Fetch configuration details by ID
  getDistributionConfigById(configId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${configId}`);
  }
  // deleteDistributionConfigById(configId: string): Observable<any> {
  //   return this.http.delete<any>(`${this.baseUrl}/${configId}` );
  // }
  deleteDistributionConfigById(configId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${configId}`, { responseType: 'text' });
  }

  // Save (POST) configuration
  saveDistributionConfig(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/save`, payload);
  }

  getCategoryValues(value:string): Observable<any>{
    return this.http.get<any>(`${this.valueUrl}${value}`)
  }

  // Method to transform flat list into tree structure
  transformToTreeStructure(data: any[]): any[] {
    const tree: any[] = [];

    data.forEach(item => {
      const parts = item.attribute.split('L'); // "CategoryL2" => ["Category", "2"]
      const categoryLevel = parseInt(parts[1], 10);

      let parent = tree.find(node => node.attribute === `CategoryL${categoryLevel - 1}`);
      if (categoryLevel === 1) {
        tree.push({ ...item, children: [] });
      } else if (parent) {
        parent.children.push({ ...item, children: [] });
      }
    });

    return tree;
  }
}
