import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FormBase } from '../../../interfaces/formInterface';
import { api } from 'src/app/properties/endpoints';

@Component({
  selector: 'app-lookup-autocomplete-molecule',
  templateUrl: './lookup-autocomplete-molecule.component.html',
  styleUrls: ['./lookup-autocomplete-molecule.component.scss']
})
export class LookupAutocompleteMoleculeComponent implements OnInit {
  @Input() lookupUrl!: string; 
  @Input() limit: number = 10; 
  @Input() placeholder: string = 'Search...'; 
   @Input() fieldData: FormBase<any>;
   @Input() control!: FormControl;  
  @Output() valueSelected = new EventEmitter<any>();
  filteredOptions$!: Observable<any[]>;
  form!: FormGroup;


  constructor(private http: HttpClient,private fb: FormBuilder) {}

  ngOnInit() {
    this.form = this.fb.group({}); 

    this.filteredOptions$ = this.control.valueChanges.pipe(
      debounceTime(300), 
      switchMap(value => this.fetchLookupEntries(value)), 
      catchError(() => of([])) 
    );
  }

  fetchLookupEntries(search: string): Observable<any[]> {
    if (!search || search.trim() === '') {
      return of([]); 
    }
    
    const requestUrl = `${api.host}/material/lookup/${this.lookupUrl}?limit=${this.limit}&search=${search}`;
    return this.http.get<any[]>(requestUrl).pipe(
      map(response => response),
      catchError(() => of([]))
    );
  }

  onOptionSelected(option: any) {
    this.control.setValue(option.code);
    this.valueSelected.emit(option); 
  }
}
