import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LayoutModule } from "@angular/cdk/layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  GridModule,
  FilterService,
  PageService,
  SortService,
  GroupService,
  ColumnChooserService,
  GridAllModule,
} from "@syncfusion/ej2-angular-grids";
import { MaterialModule } from "../../material.module";
import { ServicesModule } from "../../services/services.module";
import { LoginMoleculeComponent } from "./login-molecule/login-molecule.component";
import { RegisterMoleculeComponent } from "./register-molecule/register-molecule.component";
import { MaterialTableMoleculeComponent } from "./material-management/material-table-molecule/material-table-molecule.component";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { DynamicFormMoleculeComponent } from "./dynamic-form-molecule/dynamic-form-molecule.component";
import { DynamicFormFieldMoleculeComponent } from "./dynamic-form-field-molecule/dynamic-form-field-molecule.component";
import { ParameterTableMoleculeComponent } from "./parameter-management/parameter-table-molecule/parameter-table-molecule.component";
import { ReportsMoleculeComponent } from "./reports-molecule/reports-molecule.component";
import { StockCriteriaTableComponent } from "./stock-management/stock-criteria-table/stock-criteria-table.component";
import { ShareOfBusinessTableMoleculeComponent } from "./shareofbusiness-management/shareofbusiness-table-molecule/shareofbusiness-table-molecule.component";
import { DynamicNormTableMoleculeComponent } from "./dynamicnorm-management/dynamicnorm-table-molecule/dynamicnorm-table-molecule.component";
import { LocationsTableMoleculeComponent } from "./locations-management/locations-table-molecule/locations-table-molecule.component";
import { TableMoleculeComponent } from "./table-molecule/table-molecule.component";
import { UserTableMoleculeComponent } from "./user-managment/user-table-molecule/user-table-molecule.component";
import { BulkUploadMoleculeComponent } from "./bulk-upload-molecule/bulk-upload-molecule.component";
import { ReportsFilterMoleculeComponent } from "./reports-filter-molecule/reports-filter-molecule.component";
import { LoaderComponent } from "./loader/loader.component";
import { ForgetPasswdComponent } from "./forget-passwd/forget-passwd.component";
import { PlanningStockTableMoleculeComponent } from "./planning-stock-table-molecule/planning-stock-table-molecule.component";
import { InnerPlanningstockComponent } from "./inner-planningstock/inner-planningstock.component";
import { BprStatusHistoryComponent } from "./bpr-status-history/bpr-status-history.component";
import { StockPlanningTabComponent } from "./stock-planning-tab/stock-planning-tab.component";
import { StockPlanningTableComponent } from "./stock-planning-table/stock-planning-table.component";
import { TableSkeltonLoaderComponent } from "./table-skelton-loader/table-skelton-loader.component";
import { SyncfusionGridComponent } from "./syncfusion-grid/syncfusion-grid.component";
import { VariantRatioMoleculeComponent } from "./variant-ratio-molecule/variant-ratio-molecule.component";
import { PageNotAuthorisedMoleculeComponent } from './page-not-authorised/page-not-authorised';
import {
  DropDownListAllModule,
  DropDownListModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { BufferTrendMoleculesComponent } from "./buffer-trend-molecules/buffer-trend-molecules.component";
import { UploadFilesMoleculesComponent } from "./upload-files-molecules/upload-files-molecules.component";
import { DialogModule } from "@syncfusion/ej2-angular-popups";
import { HistoricalReportsMoleculesComponent } from "./historical-reports-molecules/historical-reports-molecules.component";
// import { NgChartjsModule } from 'ng-chartjs';
import { NgChartsModule } from "ng2-charts";
import { DashboardChatjsMoleculeComponent } from "./dashboard-chatjs-molecule/dashboard-chatjs-molecule.component";
import { DashboardGridsMoleculeComponent } from "./dashboard-grids-molecule/dashboard-grids-molecule.component";
import { TabContainerMoleculeComponent } from "./tab-container-molecule/tab-container-molecule.component";
import { FilterMoleculeComponent } from "./filter-bay-molecule/filter-molecule.component";
import { BusinessScoreCardComponent } from "./business-score-card/business-score-card.component";
import { SalesBreakdownMoleculeComponent } from "./sales-breakdown-molecule/sales-breakdown-molecule.component";

import { PipesModule } from "../pipes/pipes.module";
import { SalesVisualizationMoleculeComponent } from "./sales-visualization-molecule/sales-visualization-molecule.component";
import { GridListMoleculeComponent } from "./grid-list-molecule/grid-list-molecule.component";
import { DetailPageMoleculeComponent } from "./detail-page-molecule/detail-page-molecule.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { GridDetailSalesForecastMoleculeComponent } from "./grid-detail-sales-forecast-molecule/grid-detail-sales-forecast-molecule.component";
import { FilterBayReusableComponent } from "./filter-bay-reusable/filter-bay-reusable.component";
import { CachingMoleculeComponent } from "./caching-molecule/caching-molecule.component";
import { ProductCardMoleculeComponent } from "./product-card-molecule/product-card-molecule.component";
import { PuRosMoleculeComponent } from "./pu-ros-molecule/pu-ros-molecule.component";
import { PuStockTrendMoleculeComponent } from "./pu-stock-trend-molecule/pu-stock-trend-molecule.component";
import { PuBprMoleculeComponent } from "./pu-bpr-molecule/pu-bpr-molecule.component";
import { DetailPageAccordianMoleculeComponent } from './detail-page-accordian-molecule/detail-page-accordian-molecule.component';
import { DateMonthRangeMoleculeComponent } from './date-month-range-molecule/date-month-range-molecule.component';
import { MaterialMasterFilterMoleculeComponent } from './material-master-filter-molecule/material-master-filter-molecule.component';
import { ForecastingFormMoleculeComponent } from './forecasting-form-molecule/forecasting-form-molecule.component';
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { OrganismsModule } from "../organisms/organisms.module";
import { BOMAvailabilityState } from './bom-availability-state-molecule/bom-availability-state.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WorkflowConfigureModalMoleculeComponent } from './workflow-configure-modal-molecule/workflow-configure-modal-molecule.component';
import { DownloadMoleculeComponent } from './download-molecule/download-molecule.component';
import { PinsTemplateMoleculeComponent } from './pins-template-molecule/pins-template-molecule.component';
import { OverlayModule } from "@angular/cdk/overlay";
import { InquiriesMoleculeComponent } from './inquiries-molecule/inquiries-molecule.component';
import { ForecastgynoFormMoleculeComponent } from './forecastgyno-form-molecule/forecastgyno-form-molecule.component';
import { PivotTableMoleculeComponent } from './pivot-table-molecule/pivot-table-molecule.component';
import { PivotViewAllModule, PivotViewModule } from '@syncfusion/ej2-angular-pivotview';
import { FloorPipe } from "../pipes/floor.pipe";
import { PuAccordianComponent } from "../organisms/pu-accordian/pu-accordian.component";
import { FormatPricePipe } from "../pipes/formatPrice.pipe";
import { FilterListingBayMoleculeComponent } from './filter-listing-bay-molecule/filter-listing-bay-molecule.component';
import { CustomObjectsListTypeMoleculeComponent } from './custom-objects-list-type-molecule/custom-objects-list-type-molecule.component';
import { CustomObjectsObjectTypeMoleculeComponent } from './custom-objects-object-type-molecule/custom-objects-object-type-molecule.component';
import { MatDateFormats, MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE, } from "@angular/material/core";
import { NativeDateAdapter } from "@angular/material/core";
import { CustomOverlayTemplateComponent } from './custom-overlay-template/custom-overlay-template.component';
import { ReusableFilterMoleculeComponent } from './reusable-filter-molecule/reusable-filter-molecule.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LookupAutocompleteMoleculeComponent } from './lookup-autocomplete-molecule/lookup-autocomplete-molecule.component';
import { BusinessPartnerOverviewMoleculeComponent } from './business-partner-overview-molecule/business-partner-overview-molecule.component';
import { BusinessPartnerListMoleculeComponent } from './business-partner-list-molecule/business-partner-list-molecule.component';
import { UploadMoleculeComponent } from './upload-molecule/upload-molecule.component';
import { InstoreConfigMoleculeComponent } from './instore-config-molecule/instore-config-molecule.component';
import { InstoreConfigDetailMoleculeComponent } from './instore-config-detail-molecule/instore-config-detail-molecule.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';

export const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
export class CustomDateAdapter extends NativeDateAdapter {
  override parse(value: any): Date | null {
    if (typeof value === "string" && value.length > 0) {
      const [day, month, year] = value.split("/").map(Number);
      return new Date(year, month - 1, day);
    }
    return null;
  }

  override format(date: Date, displayFormat: string): string {
    if (!date) {
      return "";
    }
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
}


const components = [
  LoginMoleculeComponent,
  RegisterMoleculeComponent,
  MaterialTableMoleculeComponent,
  DynamicFormMoleculeComponent,
  DynamicFormFieldMoleculeComponent,
  ParameterTableMoleculeComponent,
  ReportsMoleculeComponent,
  StockCriteriaTableComponent,
  LocationsTableMoleculeComponent,
  TableMoleculeComponent,
  UserTableMoleculeComponent,
  ShareOfBusinessTableMoleculeComponent,
  DynamicNormTableMoleculeComponent,
  BulkUploadMoleculeComponent,
  ReportsFilterMoleculeComponent,
  LoaderComponent,
  SyncfusionGridComponent,
  LoaderComponent,
  BufferTrendMoleculesComponent,
  UploadFilesMoleculesComponent,
  HistoricalReportsMoleculesComponent,
  DashboardChatjsMoleculeComponent,
  DashboardGridsMoleculeComponent,
  FilterMoleculeComponent,
  BusinessScoreCardComponent,
  SalesVisualizationMoleculeComponent,
  SalesBreakdownMoleculeComponent,
  FilterBayReusableComponent,
  CachingMoleculeComponent,
  ProductCardMoleculeComponent,
  PuRosMoleculeComponent,
  PuStockTrendMoleculeComponent,
  PuBprMoleculeComponent,
  DetailPageMoleculeComponent,
  MaterialMasterFilterMoleculeComponent,
  BOMAvailabilityState,
  ForecastingFormMoleculeComponent,
  WorkflowConfigureModalMoleculeComponent,
  DownloadMoleculeComponent,
  InquiriesMoleculeComponent,
  ForecastgynoFormMoleculeComponent,
  PivotTableMoleculeComponent,
  CustomObjectsListTypeMoleculeComponent,
  CustomObjectsObjectTypeMoleculeComponent,
  VariantRatioMoleculeComponent,
  CustomOverlayTemplateComponent,
  ReusableFilterMoleculeComponent,
  BusinessPartnerListMoleculeComponent,
  BusinessPartnerOverviewMoleculeComponent,
  PageNotAuthorisedMoleculeComponent,
  UploadMoleculeComponent,
];
@NgModule({
  declarations: [
    ...components,
    ForgetPasswdComponent,
    PlanningStockTableMoleculeComponent,
    InnerPlanningstockComponent,
    BprStatusHistoryComponent,
    StockPlanningTabComponent,
    StockPlanningTableComponent,
    TableSkeltonLoaderComponent,
    SyncfusionGridComponent,
    BufferTrendMoleculesComponent,
    UploadFilesMoleculesComponent,
    HistoricalReportsMoleculesComponent,
    DashboardChatjsMoleculeComponent,
    DashboardGridsMoleculeComponent,
    TabContainerMoleculeComponent,
    FilterMoleculeComponent,
    BusinessScoreCardComponent,
    SalesVisualizationMoleculeComponent,
    GridListMoleculeComponent,
    DetailPageMoleculeComponent,
    GridDetailSalesForecastMoleculeComponent,
    FilterBayReusableComponent,
    CachingMoleculeComponent,
    PuRosMoleculeComponent,
    PuStockTrendMoleculeComponent,
    PuBprMoleculeComponent,
    DetailPageAccordianMoleculeComponent,
    DateMonthRangeMoleculeComponent,
    MaterialMasterFilterMoleculeComponent,
    ForecastingFormMoleculeComponent,
    WorkflowConfigureModalMoleculeComponent,
    PinsTemplateMoleculeComponent,
    FloorPipe,
    FormatPricePipe,
    FilterListingBayMoleculeComponent,
    LookupAutocompleteMoleculeComponent,
    InstoreConfigMoleculeComponent,
    InstoreConfigDetailMoleculeComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule,
    MaterialModule,
    ServicesModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DropDownListModule,
    DialogModule,
    NgChartsModule,
    PipesModule,
    InfiniteScrollModule,
    MatSelectFilterModule,
    MatSelectModule,
    MatFormFieldModule,
    OverlayModule,
    PivotViewAllModule,
    PivotViewModule,
    BrowserAnimationsModule
  ],
  providers: [
    GridAllModule,
    DropDownListAllModule,
    PageService,
    SortService,
    FilterService,
    GroupService,
    ColumnChooserService,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ],
  exports: [...components],
})
export class MoleculesModule { }
