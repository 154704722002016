import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class CustomObjectsService {

  constructor(private http: HttpClient) { }

  createCustomObj(name,payload:any){
    const url = `${api.host}/stockcriteria/c-objects/${name}`;
    return this.http.put(url,payload);
  }
  deleteCustomObj(name,id: any){
    const url = `${api.host}/stockcriteria/c-objects/${name}/${id}`;
    return this.http.delete(url,{responseType: 'text'});
  }
  updateCustomObj(name,payload:any,id:any){
    const url = `${api.host}/stockcriteria/c-objects/${name}/${id}`;
    return this.http.post(url,payload);
  }
  getCustomObjectbyId(name,id:any){
    const url = `${api.host}/stockcriteria/c-objects/${name}/${id}`;
    return this.http.get(url);
  }
  getPaginatedCustomObjList(name,pageData:any){
    const url = `${api.host}/stockcriteria/c-objects/${name}`;
    return this.http.post(url,pageData);
  }

  // Brandstudio Replenishment plan APIs

  getTabsSkuList(parent:any,location:any){
    const url = `${api.host}/planning/production_intent/${parent}/${location}`;
    return this.http.get(url);
  }

  saveWorkOrder(payload:any){
    const url = `${api.host}/planning/production_intent/update`;
    return this.http.post(url,payload);
  }
}
