import { Component, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Distribution2Service } from 'src/app/services/distribution2.service';
import { MaterialsService } from 'src/app/services/materials.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-instore-config-detail-molecule',
  templateUrl: './instore-config-detail-molecule.component.html',
  styleUrls: ['./instore-config-detail-molecule.component.scss']
})
export class InstoreConfigDetailMoleculeComponent {
  structure: any = null;
  isAttributeSelected = false;
  newChildName = new FormControl('', Validators.required);
  newChildShareValue: number
  currentPath: string = '';
  structureId: string = '';
  rootLevelStructure: any;
  showInput: boolean = false;
  breadcrumbs: { name: string, path: string }[] = [];
  metaData: any[] = [];
  selectedAttribute = new FormControl('', Validators.required);
  dropdownOptions: { label: string; identifier: string }[] = [];
  // editedName:string = '';
  shareValue:number = 0;
  @ViewChild('editDialog') editDialog!: TemplateRef<any>;
  selectedCategory = new FormControl();
  categoryValues: string[] = [];
  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private snack: MatSnackBar,
    private distributionService: Distribution2Service, private materialService: MaterialsService) { }
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.structureId = params['params']['id'];
      this.currentPath = params.has('path') ? decodeURIComponent(params.get('path')) : 'root'
      this.getStructureById(this.structureId);
      // this.generateBreadcrumbs();
    });
    this.getMetaData();
  //   this.selectedAttribute.valueChanges.subscribe(value => {
  //     this.distributionService.getCategoryValues(this.selectedAttribute.value).subscribe((response:any)=>{
  //       console.log(response,'categoryValues')
  //     })
  //   });
  this.selectedAttribute.valueChanges.subscribe(value => {
    if (value) {
      this.isAttributeSelected = true;
      // Fetch category values from API
      this.distributionService.getCategoryValues(value).subscribe((response: any) => {
        console.log(response, 'categoryValues');
        
        // Assign values to categoryValues array
        this.categoryValues = response.values || [];
      });
    } else {
      this.isAttributeSelected = false;
      this.categoryValues = [];
    }
  });
  }
  getMetaData() {
    this.materialService.getMaterialForm().subscribe((response: any) => {
      console.log('values3', response)
      this.metaData = response['metaData'].filter((item: any) => item.inStoreMerchandise);
      console.log(this.metaData, "metaData");
      this.dropdownOptions = this.metaData.map((item) => ({
        label: item.label,
        identifier: item.identifier,
      }));
    });
  }
  getStructureById(id: any) {
    this.distributionService.getDistributionConfigById(id).subscribe((response: any) => {
      this.rootLevelStructure = response;
      // console.log('path1', this.rootLevelStructure)
      this.structure = this.findStructureByPath(response, this.currentPath.split('/'));
      // console.log('path', this.structure)
      this.selectedAttribute.setValue(this.structure?.children?.[0]?.attribute || '');
      console.log('selected2', this.selectedAttribute)
      if(this.selectedAttribute.value != ""){
          this.isAttributeSelected = true;
          this.distributionService.getCategoryValues(this.selectedAttribute.value).subscribe((response:any)=>{
            console.log(response,'categoryValues')
            this.categoryValues = response.values || [];
          })
      } else {
      this.isAttributeSelected = false;
      this.categoryValues = [];
    }
      console.log('structureid', this.structure)
      this.generateBreadcrumbs();
    })
    
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  findStructureByPath(structure: any, pathArray: string[]): any {
    if (structure.name !== pathArray[0]) {
      return null; // If root doesn't match, return null
    }
    let currentLevel = structure;
    for (let i = 1; i < pathArray.length; i++) {
      currentLevel = currentLevel?.children?.find(child => child.name === pathArray[i]);
      if (!currentLevel) return null;
    }
    return currentLevel;
  }
  goBack() {
    const pathArray = this.currentPath.split('/').slice(0, -1);
    if (pathArray.length) {
      const parentPath = pathArray.join('/')
      this.router.navigate(['/instore-config', this.structureId, encodeURIComponent(parentPath)]);
    } else {
      this.router.navigate(['/instore-config']);
    }
  }
  getTotalShareValue(structure: any): number {
    console.log(structure,"strucucucucucuc")
    return structure.children.reduce((sum: number, child: any) => sum + (child.shareValue || 0), 0);
  }
  addChild() {
    console.log('path2', this.structure)
    if (!this.structure || !this.selectedAttribute.value) return;
    // Log the shareValue of children before updating the structure
    // if (this.structure.children && this.structure.children.length > 0) {
    //   console.log("Children shareValues before update:", this.structure.children.map(child => child.shareValue));
    // }
    // console.log('newsharevalue', this.newChildShareValue)
    let existingShareValues = this.structure.children?.map(child => child.shareValue) || [];
    console.log("Children shareValues before update:", existingShareValues);
    console.log('newsharevalue', this.newChildShareValue);
    const totalShareValue = existingShareValues.reduce((sum, val) => sum + val, 0) + (this.newChildShareValue || 0);
    console.log(totalShareValue, 'totalshare')
    const remainingShare =  100 - totalShareValue;
    console.log(remainingShare, 'remainingshare')
    if (totalShareValue > 100) {
      console.error("Total share value exceeds 100. Cannot add child.");
      // alert("Total share value exceeds 100. Cannot add child.")
     remainingShare < 0 ? this.showSnackbar(`Total share value exceeds 100.You can allocate up to ${-remainingShare}%. Reduce other shares if you need more.`) : this.showSnackbar(`No shares to spare. Reduce other shares if you need more`)
      return;
    }
    // const totalShare = this.getTotalShareValue(this.structure);
    // const remainingShare = 100 - totalShare;
    // console.log('remaining', remainingShare)
    // if (remainingShare <= 0) {
    //   this.showSnackbar(`You can allocate up to ${remainingShare}%. Reduce other shares if you need more.`);
    //   return;
    // }
    const updatedStructure = this.updateStructureWithNewChild(
      this.rootLevelStructure,
      this.currentPath.split('/'),
      {
        name: this.newChildName.value,
        attribute: this.selectedAttribute.value,
        shareValue: this.newChildShareValue, // Added shareValue
        children: []
      }
    );
    this.isAttributeSelected = true;
    if (!updatedStructure) {
      console.error('Failed to update structure.');
      return;
    }
    this.newChildName.setValue('')
    this.newChildShareValue = null; // Reset after adding
    this.distributionService.saveDistributionConfig(updatedStructure).subscribe((res: any) => {
      this.structureId = res.id;
      this.selectedAttribute.setValue('')
      this.router.navigate(['/instore-config', this.structureId, encodeURIComponent(this.currentPath)]); // Stay in the same place
    });
    this.showInput = false;
  }
  // deleteConfig() {
  
  //     this.distributionService.deleteDistributionConfigById(this.structureId).subscribe(
  //       response => {
  //         console.log('Deleted Successfully:', response);
  //       },
  //       error => {
  //         console.error('Error deleting:', error);
  //       }
  //     );
    
  // }
  // addChild() {
  //   if (!this.structure || !this.selectedAttribute.value || this.newChildShareValue == null) return;
  //   // Find the current level in the structure
  //   const currentLevel = this.getCurrentLevelStructure(this.rootLevelStructure, this.currentPath.split('/'));
  //   if (!currentLevel) {
  //     console.error('Failed to find the correct level.');
  //     return;
  //   }
  //   // Calculate total shareValue at this level
  //   const totalShareValue = (currentLevel.children || []).reduce((sum, child) => sum + (child.shareValue || 0), 0);
  //   // Check if adding this child's shareValue exceeds 100
  //   if (totalShareValue + this.newChildShareValue > 100) {
  //     alert('Total share value at this level cannot exceed 100.');
  //     return;
  //   }
  //   const updatedStructure = this.updateStructureWithNewChild(
  //     this.rootLevelStructure,
  //     this.currentPath.split('/'),
  //     { 
  //       name: this.newChildName, 
  //       attribute: this.selectedAttribute.value, 
  //       shareValue: this.newChildShareValue, // Added shareValue
  //       children: [] 
  //     }
  //   );
  //   if (!updatedStructure) {
  //     console.error('Failed to update structure.');
  //     return;
  //   }
  //   this.newChildName = '';
  //   this.newChildShareValue = null; // Reset after adding
  //   console.log(updatedStructure, "payloaddddddddd");
  //   this.distributionService.saveDistributionConfig(updatedStructure).subscribe((res: any) => {
  //     this.structureId = res.id;
  //     this.selectedAttribute.setValue('');
  //     this.router.navigate(['/instore-config', this.structureId, encodeURIComponent(this.currentPath)]); // Stay in the same place
  //   });
  //   this.showInput = false;
  // }
  /**
   * Finds the current level structure based on the path
   */
  // getCurrentLevelStructure(root: any, pathSegments: string[]): any {
  //   let current = root;
  //   for (const segment of pathSegments) {
  //     if (!segment || !current.children) break;
  //     current = current.children.find(child => child.name === segment);
  //     if (!current) return null;
  //   }
  //   return current;
  // }
  // addChild() {
  //   if (!this.structure || !this.selectedAttribute.value) return;
  //   // const currentLevel = parseInt(this.structure.attribute.replace('CategoryL', ''), 10);
  //   // const nextLevelAttribute = this.selectedAttribute.value;
  //   const updatedStructure = this.updateStructureWithNewChild(
  //     this.rootLevelStructure,
  //     this.currentPath.split('/'),
  //     { name: this.newChildName, attribute: this.selectedAttribute.value, children: [] }
  //   );
  //   if (!updatedStructure) {
  //     console.error('Failed to update structure.');
  //     return;
  //   }
  //   this.newChildName = '';
  //   console.log(updatedStructure, "payloaddddddddd")
  //   this.distributionService.saveDistributionConfig(updatedStructure).subscribe((res: any) => {
  //     this.structureId = res.id;
  //     this.selectedAttribute.setValue('')
  //     this.router.navigate(['/instore-config', this.structureId, encodeURIComponent(this.currentPath)]); // Stay in the same place
  //   });
  //   this.showInput = false;
  // }
  updateStructureWithNewChild(structure: any, pathArray: string[], newChild: any): any {
    // If path starts with "root", remove it since root is not in children
    if (pathArray.length > 0 && pathArray[0] === "root") {
      pathArray = pathArray.slice(1); // Remove "root" from path
    }
    // If pathArray is now empty, add child to the root level
    if (pathArray.length === 0) {
      return { ...structure, children: [...structure.children, newChild] };
    }
    const [currentPath, ...remainingPath] = pathArray;
    return {
      ...structure,
      children: structure.children.map((child: any) => {
        console.log(child, "child")
        if (child.name === currentPath) {
          // Recursively update the correct child node
          return this.updateStructureWithNewChild(child, remainingPath, newChild);
        }
        return child;
      })
    };
  }
  navigateToChild(childName: string) {
    console.log(this.currentPath, "currentPath");
    const newPath = this.currentPath ? `${this.currentPath}/${childName}` : childName;
    this.router.navigate([
      '/instore-config',
      this.structureId,
      encodeURIComponent(newPath) // Encode special characters like `/`
    ]);
  }
  openEditDialog(child: any, event: Event) {
    event.stopPropagation();
    this.dialog.open(this.editDialog, {
      width: '400px',
      data: { child },
    });
  }
  editChild(child: any, event: any) {
    // event.stopPropogation();
    child.isEditing = true;
    this.newChildName.setValue(child.name)  ; // Store the original name for editing
    this.shareValue = child.shareValue;
  }
  saveChildName(child: any, event: any) {
    event.stopPropagation();
    if (!this.newChildName.value.trim()) return;
    console.log(child,"childinsaveedit",event.target);
    const totalShare = this.getTotalShareValue(this.structure);
    console.log(totalShare,"totalShare")
    const remainingShare = 100 - (totalShare - (child.shareValue || 0));
    console.log(remainingShare,"remaining share",child.shareValue)
    if (this.shareValue > remainingShare) {
      this.showSnackbar(`Only ${remainingShare}% is available. Adjust other share values.`);
      return;
    }
    child.name = this.newChildName.value;
    child.shareValue = this.shareValue;
    child.isEditing = false;
    // Update the structure and persist via API
    const updatedStructure = this.updateChildName(
      this.rootLevelStructure,
      this.currentPath.split('/'),
      child
    );
    console.log(updatedStructure,"upp")
    this.distributionService.saveDistributionConfig(updatedStructure).subscribe((res: any) => {
      this.structureId = res.id;
      this.shareValue = 0;
      this.newChildName.setValue('');
      this.router.navigate(['/instore-config', this.structureId, encodeURIComponent(this.currentPath)]);
    });
    this.dialog.closeAll();
  }
  closeDialog() {
    this.dialog.closeAll(); // Close the dialog without saving
  }
  cancelEdit(child: any, event: Event) {
    event.stopPropagation();
    child.isEditing = false;
  }
  // Update structure recursively
  updateChildName(structure: any, pathArray: string[], updatedChild: any): any {
    if (pathArray.length > 0 && pathArray[0] === "root") {
      pathArray = pathArray.slice(1);
    }
    if (pathArray.length === 0) {
      return {
        ...structure,
        children: structure.children.map((child: any) =>
          child.name === updatedChild.name ? { ...child, name: updatedChild.name } : child
        ),
      };
    }
    const [currentPath, ...remainingPath] = pathArray;
    return {
      ...structure,
      children: structure.children.map((child: any) => {
        if (child.name === currentPath) {
          return this.updateChildName(child, remainingPath, updatedChild);
        }
        return child;
      }),
    };
  }
  // Generate Breadcrumbs
  generateBreadcrumbs() {
    console.log('structure', this.structure)
    const pathSegments = this.currentPath.split('/');
    let accumulatedPath = '';
    this.breadcrumbs = pathSegments.map((segment, index) => {
      accumulatedPath += (index === 0 ? '' : '/') + segment;
      if (segment === 'root') {
        segment = this.rootLevelStructure.code;
        console.log('segment', segment)
      }
      return { name: segment, path: accumulatedPath };
    });
  }
  // Navigate to Breadcrumb
  navigateToBreadcrumb(path: string) {
    this.router.navigate(['/instore-config', this.structureId, encodeURIComponent(path)]);
  }
  cancelAdd(){
    this.showInput = !this.showInput;
    this.newChildName.setValue('')
    this.newChildShareValue = 0;
  }
}