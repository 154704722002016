<button *ngIf="!partnerIdFromGrid" class="mat-primary fs-margin-bottom-1 fs-margin-top-1" style="margin-left: 15px;" mat-raised-button
  (click)="navigateBack()">
  <mat-icon>arrow_back</mat-icon>Go back
</button>
<mat-tab-group (selectedTabChange)="onTabChange($event)" fitInkBarToContent class="custom-tab-group">
  <mat-tab label="Overview">
    <ng-container *ngIf="tabInitialized[0]">
      <div *ngIf="!partnerData">
        <div class="product-overview fs-margin-bottom-1">
          <div class="product-detail-card fs-padding-0-1"
            style="border-radius:10px;background-color: rgb(211, 211, 211, 0.2)">
            <div style="font-weight: lighter;width: 60%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 40%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
          </div>
          <div class="mat-elevation-z4 fs-padding-1 chart-section">
            <div style="font-weight: lighter;width: 60%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 40%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
          </div>
        </div>
      </div>
      <div *ngIf="detailViewFields" class="detailedView-container">
        <div *ngFor="let field of detailViewFields">
          <label>{{ field.label }}</label>
          <span>{{field.value}}</span>
        </div>
      </div>
    </ng-container>
  </mat-tab>
  <mat-tab label="Revenue">
    <ng-container *ngIf="tabInitialized[1]">
      <div class="info"><span>*Drive smarter strategies with revenue and quantity trend analysis.</span></div>
      <div class="fields-container">
        <!-- Dropdown for Date Range Selection -->
        <mat-form-field appearance="outline">
          <mat-label>Date Range</mat-label>
          <mat-select [(ngModel)]="selectedRange" (selectionChange)="onDateRangeChange($event.value)">
            <mat-option *ngFor="let option of dateRangeOptions" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="selectedRange === 'custom'" class="custom-date-container">
          <mat-form-field appearance="fill">
            <mat-label>From</mat-label>
            <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fromDate" (dateChange)="validateCustomDates()"
              placeholder="From Date" required>
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>To</mat-label>
            <input matInput [matDatepicker]="toPicker" [(ngModel)]="toDate" (dateChange)="validateCustomDates()"
              placeholder="To Date">
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="charts-container" [class.full-width]="isExpanded || isExpanded == null">
      <div class="container-box">
        <div class="charts-header">
          <span>{{ partnerId ? partnerId : partnerIdFromGrid }} &nbsp;&nbsp; Sales Momentum
          </span>
          <mat-icon (click)="toggleFullWidth()" *ngIf="!isExpanded" matTooltip="Full Screen View">open_in_full</mat-icon>
          <mat-icon (click)="toggleFullWidth()" *ngIf="isExpanded" matTooltip="Exit Full Screen">fullscreen_exit</mat-icon>
        </div>
    
        <app-dashboard-chatjs-molecule *ngIf="chartData"
          [chartData]="chartData"
          chartType="{{ chartData.type }}"
          enableZoom="true"
        ></app-dashboard-chatjs-molecule>
    
      </div>
    </div>
    </ng-container>
  </mat-tab>
</mat-tab-group>