import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BusinessPartnerService } from 'src/app/services/business-partner.service';

@Component({
  selector: 'app-business-partner-overview-molecule',
  templateUrl: './business-partner-overview-molecule.component.html',
  styleUrls: ['./business-partner-overview-molecule.component.scss'],
  providers:[DatePipe]
})
export class BusinessPartnerOverviewMoleculeComponent {
  @Input() partnerIdFromGrid:any
  tabInitialized: { [key: number]: boolean } = {0:true,1:false};
  partnerData: any;
  partnerId: any;
  metaData: any;
  detailViewFields:any[] = [];
  chartData:any;
  isExpanded: boolean = null;
  dateRangeOptions = [
    { label: 'Last 30 Days', value: 'last30' },
    { label: 'Last 90 Days', value: 'last90' },
    { label: 'Last 120 Days', value: 'last120' },
    { label: 'Custom Date Range', value: 'custom' }
  ];
  selectedRange: string = 'last90'; // Default selection
  fromDate: Date | null = null;
  toDate: Date | null = null;

  constructor(private businessPartnerService: BusinessPartnerService, public datepipe: DatePipe,
    private route: ActivatedRoute, private snack: MatSnackBar,) {
  }
  ngOnInit(): void {
    this.partnerId = this.route.snapshot.queryParams['id'] || this.partnerIdFromGrid;
    if (this.partnerId) {
      this.getDetails(this.partnerId);
      this.setDefaultRange();
    }
  }
  setDefaultRange() {
    const today = new Date();
    this.fromDate = new Date();
    this.fromDate.setDate(today.getDate() - 90);
    this.toDate = today;
    this.fetchChartDetails();
  }
  getDetails(id: any) {
    this.businessPartnerService.getPartnerDetails(id).subscribe((res: any) => {
      this.partnerData = res;
      this.getAttributes();
    }, (error) => {
      this.showSnackbar('Error while fetching partner details');
    })
  }
  getAttributes() {
    this.businessPartnerService.getMetaData().subscribe((response: any) => {
      this.metaData = response["metaData"];
      this.mapLabelsWithData();
    }, (error) => {
      this.showSnackbar('Error while fetching attributes');
    })
  }
  mapLabelsWithData(){
    this.detailViewFields = this.metaData?.filter((item:any)=> item.detailView).map((item:any)=>({
      label: item.label,
      value: this.partnerData[item.identifier] || 'N/A'
    }))
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  onTabChange(event: any): void {
    const index = event.index;
    if (!this.tabInitialized[index]) {
      this.tabInitialized[index] = true;
    }
  }
  navigateBack() {
    window.history.back();
  }
  toggleFullWidth() {
    this.isExpanded = !this.isExpanded;
  }

  onDateRangeChange(value: string) {
    const today = new Date();

    if (value === 'last30' || value === 'last90' || value === 'last120') {
      const days = value === 'last30' ? 30 : value === 'last90' ? 90 : 120;
      this.fromDate = new Date();
      this.fromDate.setDate(today.getDate() - days);
      this.toDate = today;
      this.fetchChartDetails();
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }
  validateCustomDates() {
    const today = new Date();

    if (this.fromDate && this.toDate || this.fromDate) {
      if (this.fromDate > this.toDate) {
        return this.showSnackbar('From Date cannot be later than To Date');
      }else {
        this.fetchChartDetails();
      }
    } if ((this.fromDate && this.fromDate > today) || (this.toDate && this.toDate > today)) {
      return this.showSnackbar('Invalid date');
    } if(!this.fromDate && this.toDate){
      return this.showSnackbar("Please select From Date");
    }
  }
  getFormattedDate(date: Date | null): string | null {
    return date ? this.datepipe.transform(date, 'yyyy-MM-dd') : null;
  }
  fetchChartDetails(){
    const payload = {
      partnerId: this.partnerId, 
      startDate: this.getFormattedDate(this.fromDate),
      endDate: this.getFormattedDate(this.toDate),
    };
    this.businessPartnerService.getChartDetails(payload).subscribe((response:any)=>{
      if(response){
          this.chartData = this.chartData = {
            header: response["displayName"],
            type: response["type"],
            data: response["data"],
            options: {
              pointRadius: 0.5,
              pointStyle: "cross",
              scales: {
                y: {
                  grace: 1,
                  position: "right",
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Revenue ₹",
                    font: {
                      weight: "bold",
                    },
                  },
                },
                "secondary-y-axis": {
                  // type: "linear",
                  position: "left",
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Qty Sold",
                    font: {
                      weight: "bold",
                    },
                  },
                },
              },
              tooltips: {
                enabled: true,
                mode: "index",
                intersect: false,
              },
            },
            plugins: [],
          };
      }
    })
  }
}
