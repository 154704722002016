import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  constructor(private http: HttpClient) { }

  downloadSampleTemplate(code: any) {
    const url = `${api.host}/reports/bulk-upload/download-template/${code}`;
    return this.http.get(url, { responseType: 'blob' });
  }
  downloadFile(id: any,name:any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/octet-stream'
    });

    return this.http.get(`${api.host}/reports/bulk-upload/download/${id}`, {
      headers: headers,
      responseType: 'blob'
    }).pipe(
      map((blob: Blob) => {
        return {
          blob: blob,
          filename: name
        };
      })
    );
  }
  uploadFiles(file:any,code:any) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('templateCode', code);
    const url = `${api.host}/reports/bulk-upload/upload`
    return this.http.post(url, formData, { responseType: 'text' })
  }
  getFilesList(){
    const url=`${api.host}/reports/bulk-upload/list`;
    return this.http.get<any>(url);
  }
  fetchTemplateDetails(code:any){
    const url = `${api.host}/reports/bulk-upload/template/${code}`;
    return this.http.get(url);
  }

  // Brandstudio regenerate

  generateReplenishment(payload:any){
    const url = `${api.host}/ai-assistant/generate/in_store_replenishment`;
    return this.http.post(url,payload);
  }
}
