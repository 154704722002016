import { Component, ElementRef, EventEmitter, Inject, Input, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { CustomObjectsService } from 'src/app/services/custom-objects.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-custom-overlay-template',
  templateUrl: './custom-overlay-template.component.html',
  styleUrls: ['./custom-overlay-template.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease',
          style({ opacity: 1 })
        ),
        query("@*", [animateChild()], { optional: true })
      ]),
      transition(':leave', [
        query("@*", [animateChild()], { optional: false }),
      ]),
    ]),
    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('300ms ease',
          style({
            transform: 'translateX(100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)'
          }
          )
        )
      ])
    ])
  ],
  providers: [DatePipe],
})
export class CustomOverlayTemplateComponent {
  tabInitialized: Record<number, boolean> = { 0: true, 1: false };
  workOrders: any;
  unassignedWO: any[] | null = null;
  assignedWO: any[] | null = null;
  assignedGroups: { [key: string]: any[] } = {};
  displayedUnassignedColumns = [
    { key: 'materialCode', label: 'Material Code' },
    { key: 'locationCode', label: 'Location Code' },
    { key: 'fulfilledQty', label: 'Fulfilled Qty' },
    { key: 'qty', label: 'Quantity' },
    { key: 'code', label: 'Code' }
  ];
  selection = new SelectionModel<any>(true, []);
  formGroupsAssigned: { [key: string]: FormGroup } = {};
  poForm!: FormGroup;
  displayedUnassignedColumnKeys = ['select', ...this.displayedUnassignedColumns.map(col => col.key)];
  displayedUnassignedColumnsWithExpectedQty = [...this.displayedUnassignedColumns.map(col => col.key), 'estimatedDeliveryQty'];
  public assignedGroupKeys: string[] = [];
  earliestOrders: any;


  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<CustomOverlayTemplateComponent>, private syncfusion: SyncfusionService,
    @Inject(MAT_DIALOG_DATA) public data: any, private snack: MatSnackBar,
    private customService: CustomObjectsService) { }
  ngOnInit() {
    if (this.data) {
      this.fetchWorkOrders();
      this.syncfusion.setReload(false);
    }
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.unassignedWO.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.unassignedWO);
    }
  }

  toggleRow(row: any) {
    this.selection.toggle(row);
  }
  fetchWorkOrders(): void {
    this.customService.getTabsSkuList(this.data.parent, this.data.location).subscribe(response => {
      this.workOrders = response;
      this.unassignedWO = this.workOrders.filter(order => !order.estimatedDeliveryDate);
      this.assignedWO = this.workOrders.filter(order => order.poCode);
      this.assignedGroups = this.assignedWO.reduce((groups: any, order) => {
        if (!groups[order.poCode]) {
          groups[order.poCode] = [];
        }
        groups[order.poCode].push(order);
        return groups;
      }, {});
      this.getEarliestOrders(this.assignedGroups);
      this.updateAssignedGroupKeys();
      this.initializeForms();
    }, (error) => {
      this.unassignedWO = [];
      this.showSnackbar('Error while fetching work orders')
    });
  }
  initializeForms() {
    this.poForm = new FormGroup({
      poCode: new FormControl('', Validators.required),
      estimatedDeliveryDate: new FormControl('', Validators.required)
    });

    Object.keys(this.assignedGroups)?.forEach(poCode => {
      this.assignedGroups[poCode].forEach(order => {
        this.formGroupsAssigned[order.code] = this.fb.group({
          estimatedDeliveryDate: [order.estimatedDeliveryDate || ''],
          estimatedDeliveryQty: [order.estimatedDeliveryQty || order.qty || ''],
          remark: [order.remark || ''],
          // poCode: [order.poCode || '']
          status: [order.status || '']

        });
      });
    });
  }

  getAssignedForm(orderCode: string): FormGroup {
    return this.formGroupsAssigned[orderCode];
  }

  private updateAssignedGroupKeys(): void {
    this.assignedGroupKeys = this.assignedGroups ? Object.keys(this.assignedGroups) : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['assignedGroups'] && this.assignedGroups) {
      this.updateAssignedGroupKeys();
    }
  }

  createForm(order: any): FormGroup {
    return this.fb.group({
      estimatedDeliveryDate: [order.estimatedDeliveryDate || '', Validators.required],
      estimatedDeliveryQty: [order.estimatedDeliveryQty || '', Validators.required],
      remark: [order.remark || ''],
      // poCode: [order.poCode || '', Validators.required],
      status: [order.status || ''],
    });
  }
  saveWorkOrders(code: any): void {
    const updatedOrders = [];
    const form = this.getAssignedForm(this.earliestOrders[code].code)?.value;
    const deliveryDate = new Date(form.estimatedDeliveryDate).setHours(0, 0, 0, 0);
    const today = new Date().setHours(0, 0, 0, 0);
    if (deliveryDate < today) {
      this.showSnackbar('Invalid delivery date');
      return;
    }

    this.assignedGroups[code].forEach(ele => {
      const formValues = this.getAssignedForm(ele.code)?.value;
      const updatedData = {
        code: ele.code,
        poCode: ele.poCode,
        estimatedDeliveryDate: new Date(deliveryDate).toLocaleDateString('en-CA') || '',
        estimatedDeliveryQty: formValues?.estimatedDeliveryQty || '',
        remark: formValues?.remark || '',
        // poCode: formValues?.poCode || '',
        status: formValues?.status || '',
      };


      if (updatedData.estimatedDeliveryQty < 0) {
        this.showSnackbar('Invalid Ouantity');
        return;
      }
      updatedOrders.push(updatedData);
    });

    this.customService.saveWorkOrder(updatedOrders).subscribe(() => {
      this.showSnackbar('Saved succesfully!');
      this.fetchWorkOrders();
      this.syncfusion.setReload(true);
    }, (error) => {
      this.showSnackbar('Error while saving data.Please try again later');
    });
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
  onTabChange(event: any): void {
    const index = event.index;
    if (!this.tabInitialized[index]) {
      this.tabInitialized[index] = true;
    }
  }

  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  assignPo() {
    if (this.poForm.invalid) return;
    const formValues = this.poForm.value;
    const deliveryDate = new Date(formValues.estimatedDeliveryDate).getTime();
    const today = new Date().setHours(0, 0, 0, 0);
    if (deliveryDate < today) {
      this.showSnackbar('Invalid delivery date');
      return;
    }
    const selectedData = this.selection.selected.map(row => ({
      ...row,
      poCode: formValues.poCode,
      estimatedDeliveryDate: formValues.estimatedDeliveryDate ? new Date(formValues.estimatedDeliveryDate).toLocaleDateString('en-CA')
        : null
    }));
    this.customService.saveWorkOrder(selectedData).subscribe(() => {
      this.showSnackbar('Saved succesfully!');
      this.fetchWorkOrders();
      this.syncfusion.setReload(true);
      this.poForm.reset();
      this.selection.clear();
    }, (error) => {
      this.showSnackbar('Error while saving data.Please try again later');
    });
  }
  getEarliestOrders(assignedGroups: Record<string, any[]>): void {
    this.earliestOrders = {};

    for (const poCode in assignedGroups) {
      const orders = assignedGroups[poCode];
      if (Array.isArray(orders) && orders.length > 0) {
        const earliestOrder = orders.reduce((earliest, order) =>
          new Date(order.estimatedDeliveryDate) < new Date(earliest.estimatedDeliveryDate) ? order : earliest
        );
        this.earliestOrders[poCode] = earliestOrder;
      }
    }
  }
  isAnyFieldDirty(poCode: string): boolean {
    return this.assignedGroups[poCode]?.some((order: any) => {
      const form = this.getAssignedForm(order.code);
      return form && Object.values(form.controls).some(control => control.dirty);
    });

  }


}