<div class="sync-fusion-main-container" style="height: 94vh;">
<mat-toolbar class="header display-flex justify-content-center">
  <span>{{reportNameWithSpace}}</span>
</mat-toolbar>
<div class="text-right fs-margin-0-1"><span>Reports are generated as on <strong>{{generatedDate | date}}</strong></span>
</div>
<div *ngIf="dataList" class="grid-container">
  <app-historical-reports-molecules [reportName]="reportName" [dynamic_headers]="dynamicColumns" [gridData]="dataList"
    [isGrouping]=false (navigateGrid)="navigateGrid($event)"></app-historical-reports-molecules>
</div>
</div>