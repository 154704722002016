import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  TemplateRef,
  ChangeDetectorRef
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { RowDataBoundEventArgs } from "@syncfusion/ej2-grids";
import {
  FilterSearchBeginEventArgs,
  FilterSettingsModel,
  GridComponent,
  TextWrapSettingsModel,
  DataResult,
  DataStateChangeEventArgs
} from "@syncfusion/ej2-angular-grids";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { EmitType } from "@syncfusion/ej2-base";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { Observable, of, Subject, Subscription } from "rxjs";
import { AppSettingService } from "src/app/services/app-setting.service";
import { PinsService } from "src/app/services/pins.service";
import { catchError, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { DataSharingService } from "src/app/services/data-sharing.service";
import { FormatPricePipe } from "../../pipes/formatPrice.pipe";
import { CustomOverlayTemplateComponent } from "../custom-overlay-template/custom-overlay-template.component";
import { CustomObjectsService } from "src/app/services/custom-objects.service";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-syncfusion-grid",
  templateUrl: "./syncfusion-grid.component.html",
  styleUrls: ["./syncfusion-grid.component.scss"],
})
export class SyncfusionGridComponent implements OnInit, AfterViewInit {
  showImage: boolean = false;
  dialogVisible: boolean = false;
  infoData: any;
  ELEMENT_DATA: any;
  imgUrl: any;
  editedData: any[] = [];
  dynamicHeaders: any[] = [];

  @Output() editedDataChange = new EventEmitter<any[]>();
  unavailableData: any;
  unavailableFlag: boolean;
  imgFlag: boolean;
  skuLineGraphFlag: boolean = false;
  chartData: any;
  labels: any;
  formData: any;
  addFlag: boolean;
  selected_bomdata: any;
  bomDialogVisible: boolean;
  createWODialogVisible: boolean;
  pagination: boolean = true;
  public targetElement!: HTMLElement;
  public loadingIndicator;
  public toolbar: string[];
  public isInitial: Boolean = true;
  public pageSettings: object;
  public filterOption: FilterSettingsModel = { type: "Excel" };
  public datePipe = new DatePipe("en-US");
  @ViewChild("grid", { static: false })
  public grid: GridComponent;
  @ViewChild("ejDialog")
  ejDialog!: DialogComponent;
  @ViewChild("container", { read: ElementRef, static: true })
  container!: ElementRef;
  @ViewChild(CustomOverlayTemplateComponent, { static: false })
  dataSourcePurchaseOrder = new MatTableDataSource<any>([]);
  @Input() aggregatesConfig: [] = [];
  @Input() isOptionRequired: boolean = false;
  @Input() reportName: any;
  @Input() dynamic_headers: any;
  @Input() count: any;
  @Input() data: any;
  @Input() dynamicNormForm: FormGroup;
  @Input() returnsAnalyzerOptions: boolean;
  @Input() freezeColumns: number = 2;
  @Input() readOnlyMode: boolean = true;
  @Input() saveTemplate: TemplateRef<any>;
  @Output() navigateGrid = new EventEmitter<DataStateChangeEventArgs>();
  @Output() dataEmitted = new EventEmitter<string>();
  @Output() dataEmitForSalesEvent = new EventEmitter<{}>();
  @Output() dataEmitForDynamicNormReview = new EventEmitter<{}>();
  @Output() dataEmitForPOPUP = new EventEmitter<{}>();
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() reloadPageEvent = new EventEmitter<void>();
  skuStockDetail: any;
  showOrderQTY: { code: string; shortage: number }[];
  objectQTYHeader: string[];
  userWorkOrders: number;
  createWorkOrdersFromUser: {
    materialCode: string;
    locationCode: string;
    qty: number;
    fulfilledQty?: string;
  };
  public editSettings;
  getConfigDataForSpecificRow: { pendingQty: any; piCreatedToday: number; piToBeCreated: number; };
  verifyToEnableIconColor: { id: string } = { id: '' }
  public wrapSettings: TextWrapSettingsModel = { wrapMode: 'Both' };
  customOperators = {
    stringOperator: [
      { value: 'startswith', text: 'Starts with' },
      { value: 'equal', text: 'Equal' },
      { value: 'contains', text: 'Contains' }
    ],
    numberOperator: [
      { value: 'equalto', text: 'Equal to' },
      { value: 'notequalto', text: 'Not Equal to' },
      { value: 'greaterthan', text: 'Greater than' },
      { value: 'lessthan', text: 'Less than' },
      { value: 'greaterthanorequal', text: 'Greater than Or Equal' },
      { value: 'lessthanorequal', text: 'Less than Or Equal' }
    ]
  };
  uniqueCodes: any[] = [];
  cachedPinCounts: any = {};
  cachedGridData: any;
  isMobile: boolean = false;
  isLoading = false;
  private destroy$: Subject<void> = new Subject<void>();
  settings: any;
  customObj: any;
  showOverlay: boolean = false;
  cObjData: any = {};
  customObjList: any[] = [];
  skuList: any[] = [];
  skuListHeaders: any[] = [];
  skuParentCode:any;
  skuTableFetched: boolean = false;
  selectedIndex: any;
  selectedDataObject: any = {};
  poCode = new FormControl('', Validators.required);
  deliveryDate = new FormControl(null, [Validators.required, this.dateValidator]);
  private triggerRestoreSubscription!: Subscription;
  dateError: boolean = false;
  bulkWorkOrdersPayload: any[] = [];
  excessWorkOrderDetails: { [sku: string]: number } = {};
  excessWorkOrderKeys: string[] = [];
  @Input() workOrderData: { [sku: string]: string } = {};


  constructor(private dataSharingService: DataSharingService, private syncfusion: SyncfusionService, private clickStream: ClickStreamAnalyticsService, private pinsService: PinsService,
    private snack: MatSnackBar, private settingService: AppSettingService, private customService: CustomObjectsService, private cdr: ChangeDetectorRef, private dialog: MatDialog,) { }
  ngOnInit() {
    if (!this.syncfusion.getReload()) {
      this.clearPersistenceData();
    }
    this.triggerRestoreSubscription = this.dataSharingService.triggerRestore$.subscribe(() => {
      this.restoreGridScrollPosition();
    });
    this.checkScreenSize();
    this.editSettings = {
      allowEditing: true,
      mode: 'Normal',
    };
    this.addFlag = true;
    if (this.dynamic_headers && this.data) {
      this.cachedGridData = this.data;
      this.dynamic_headers.map((r) => {
        if (
          r.field == "imageUrl" || "image_url" ||
          r.field == "unavailableSkuCodes" ||
          r.field == "skuStockDetail"
        ) {
          if (this.data instanceof Array) {
            this.data.map((row) => {
              row.showImage = false;
              row.showSkus = false;
              row.showSkuDetails = false;
              row.showOrders = false;
            });
          } else if (this.data && this.data.result) {
            this.data.result.map((row) => {
              row.showImage = false;
              row.showSkus = false;
              row.showSkuDetails = false;
              row.showOrders = false;
            });
          }
        }
        if (r.field === "createWorkOrder") {
          if (this.data instanceof Array) {
            this.data.map((row) => {
              const suggestedWorkOrderTextBox = this.reportName === 'Brandstudio_Replenishment_SKU'
                ? row.suggested_wo : row.piToBeCreated - row.piCreatedToday;
              row.userWorkOrders =
                suggestedWorkOrderTextBox > 0 ? suggestedWorkOrderTextBox : 0;
              return row;
            });
          } else if (this.data && this.data.result) {
            this.data.result.map((row) => {
              const suggestedWorkOrderTextBox = this.reportName === 'Brandstudio_Replenishment_SKU'
                ? row.suggested_wo : row.piToBeCreated - row.piCreatedToday;
              row.userWorkOrders =
                suggestedWorkOrderTextBox > 0 ? suggestedWorkOrderTextBox : 0;
              return row;
            });
          }
        }
      });
    } else {
      // Handle the case where either this.dynamic_headers or this.data is undefined
      console.error("Dynamic headers or data is undefined");
    }
    let settings = this.settingService.getAppSetting('grid.pageSizes', [50, 100]);
    this.pageSettings = {
      pageSizes: settings,
      pageSize: settings[0],
      locale: "en-US"
    };
    // this.toolbar = ["ExcelExport", "CsvExport", "ColumnChooser"];
    this.updateToolbarBasedOnGridMode();
    this.loadingIndicator = { indicatorType: "Shimmer" };
    if (this.isOptionRequired) {
      this.pageSettings = {};
      this.toolbar = [];
      this.skuLineGraphFlag = true;
      this.pagination = false;
    }
    if (this.isOptionRequired) {
      this.pageSettings = {};
    }
    if (this.returnsAnalyzerOptions) {
      this.isOptionRequired = true;
    }
  }
  @HostListener('window:resize', [])
  onResize() {
    this.checkScreenSize();
  }
  ngAfterViewInit() {
    // if (this.reportName === 'Brandstudio_Replenishment') {
    //   this.settings = this.settingService?.getSettings() || {};
    //   this.customObj = this.settings['custom_entity']['replenishment_remarks'] || {};
    // }
    this.getUniqueCodes(this.cachedGridData);
  }
  updateToolbarBasedOnGridMode(): void {
    const isRemote = this.settingService.isRemoteGridMode();
    this.toolbar = isRemote ? ['ExcelExport', 'ColumnChooser'] : ['ExcelExport', 'CsvExport', 'ColumnChooser'];
  }
  public dataStateChange(state: DataStateChangeEventArgs): void {
    this.navigateGrid.emit(state);
  }
  checkScreenSize() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth < 768;
    this.freezeColumns = this.isMobile ? 1 : 2;
    if (this.reportName == "promotions-planner") {
      this.freezeColumns = 2;
    }
  }
  actionBegin(args: FilterSearchBeginEventArgs) {
    if (args.requestType != 'filterchoicerequest') {
      this.clickStream.publishGAEvent(EventType.GRID_COLUMN_ACTION, {
        actionType: args.requestType,
        value: args.columnName,
        section: this.reportName
      })
    }
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = this.count;
    }
    if (args.requestType === "filterbeforeopen") {
      setTimeout(() => {
        const filterDialog = (args as any).filterModel?.dlgDiv;
        if (filterDialog) {
          const filterElement = filterDialog.querySelector('.e-autocomplete');
          if (filterElement && filterElement.ej2_instances) {
            filterElement.ej2_instances[0].actionBegin = (args) => {
              args.cancel = true;
            }
          };
        }
      }, 0);
      if (this.settingService.isRemoteGridMode()) {
        if (args.filterModel['flMuiObj'] && args.filterModel['flMuiObj'].customFilterOperators) {
          args.filterModel['flMuiObj'].customFilterOperators.stringOperator = this.customOperators.stringOperator;
          args.filterModel['flMuiObj'].customFilterOperators.numberOperator = this.customOperators.numberOperator;
        }
        if (args.filterModel['cBox']) {
          const div = args.filterModel['cBox'];
          div.style.display = 'none';
        }
      }

    }
  }
  // onCellEdit(event: any, field: string, row: any) {
  //   console.log("cell edited")
  //   const editedRow = this.editedData.find(item => item.materialCode === row.materialCode);
  //   if (editedRow) {
  //     editedRow[field] = event.target.value;
  //   } else {
  //     const newEdit = { ...row, [field]: event.target.value };
  //     this.editedData.push(newEdit);
  //   }
  //   // this.editedDataChange.emit(this.editedData);
  //   this.dataSharingService.setEditedData(this.editedData);
  // }
  selectText(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }

  onCellEdit(event: any, field: string, row: any) {
    if (!row._editedFields) {
      row._editedFields = {};
    }

    row._editedFields[field] = true;
    const editedRow = this.editedData.find(item => item.id === row.id);
    var position = this.grid.element.querySelectorAll('.e-movablecontent')[0].scrollLeft || 0;

    if (editedRow) {
      // Update the field if the row is already being tracked
      editedRow[field] = event.target.value;
    } else {
      // Add new edited row data to editedData array
      const newEdit = { id: row.id, [field]: event.target.value };
      this.editedData.push(newEdit);
    }
    // Update shared edited data
    this.dataSharingService.setEditedData(this.editedData);
    this.dataSharingService.updatePosition(position);
  }
  restoreGridScrollPosition() {
    this.dataSharingService.currentPosition$.pipe(take(1)).subscribe(position => {
      setTimeout(() => {
        if (this.grid && this.grid.element) {
          let movableContent = this.grid.element.querySelector('.e-movablecontent') as HTMLElement;
          if (movableContent) {
            movableContent.scrollTo({
              left: position,
              behavior: 'smooth'
            });
          }
          if (this.reportName === "Brandstudio_Replenishment") {
            this.selectedIndex = window.localStorage.getItem("selectedRowIndex");
            let rowElement = this.grid.getRowByIndex(this.selectedIndex);
            if (rowElement) {
              rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            window.localStorage.setItem("selectedRowIndex", "");
          }
        }
      }, 500);
    });
  }

  toolbarClick(args: ClickEventArgs): void {
    this.clickStream.publishGAEvent(EventType.GRID_COLUMN_ACTION, {
      actionType: args.item.text,
      section: this.reportName
    })
    switch (args.item.text) {
      case "Excel Export":
        // this.grid.excelExport(this.getExcelExportProperties());
        if (this.settingService.isRemoteGridMode()) {
          this.triggerExcelExportApi();
        }
        else {
          this.grid.excelExport(this.getExcelExportProperties());
        }
        break;
      case "CSV Export":
        this.grid.csvExport(this.getCSVExportProperties());
        break;
    }
  }

  private triggerExcelExportApi(): void {
    this.syncfusion?.exportData$?.pipe(takeUntil(this.destroy$)).subscribe((exportData) => {
      this.isLoading = true;
      if (exportData !== null && exportData?.headerContent && exportData?.pageData) {
        const headerContent = JSON.parse(exportData.headerContent);
        const columnsMapping = headerContent.reduce((acc: { [key: string]: string }, item: any) => {
          if (item.field && item.headerText) {
            acc[item.field] = item.headerText;
          }
          return acc;
        }, {});

        const data = {
          ...exportData.pageData,
          columns: columnsMapping,
          downloadEntity: false
        };
        this.syncfusion.exportDataToExcel(data, this.reportName).subscribe((response) => {
          if (response.status === 307) {
            this.grid.excelExport(this.getExcelExportProperties()).then(() => {
              setTimeout(() => {
                this.isLoading = false;
              }, 1000);
            }).catch(() => {
              this.isLoading = false;
              console.error('Error exporting to Excel');
            });
          } else {
            // Handle successful response
            this.isLoading = false;
            this.snack.open("Download triggered Successfully", "close", {
              duration: 4000,
              horizontalPosition: "center",
              verticalPosition: "top",
            });
          }
        }, (error) => {
          this.isLoading = false;
          console.error('Error in Excel Export:', error);
        });
      } else {
        this.isLoading = true;
        this.grid.excelExport(this.getExcelExportProperties()).then(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }).catch(() => {
          this.isLoading = false;
          console.error('Error exporting to Excel');
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.syncfusion.resetExportData();
    if (this.triggerRestoreSubscription) {
      this.triggerRestoreSubscription.unsubscribe();
    }
  }
  private getExcelExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: this.reportName + "_" + currentDateTime + ".xlsx",
    };
  }
  private getCSVExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: this.reportName + "_" + currentDateTime + ".csv",
    };
  }

  public initilaizeTarget: EmitType<object> = () => {
    this.targetElement = this.container.nativeElement.parentElement;
  };
  public onOpenDialog = (event: any, input): void => {
    if (input === "openPOQty") {
      this.infoData = event;
      this.syncfusion
        .getWithCache(
          event.materialCode || event.sku,
          event.locationCode || event.location,
          event.supplierCode
        )
        .then((response) => {
          this.ELEMENT_DATA = response;
          this.dataSourcePurchaseOrder = new MatTableDataSource<any>(
            this.ELEMENT_DATA
          );
          this.dialogVisible = true;
        })
        .catch((error) => {
          this.dialogVisible = false;
        });
    } else if (input === "unavailable") {
      if (event.unavailableSkuCodes.includes(",")) {
        this.dialogVisible = true;
        this.unavailableFlag = true;
        this.unavailableData = event.unavailableSkuCodes.split(",");
      } else {
        this.unavailableData = event.unavailableSkuCodes;
      }
    } else if (input === "canOrderQty") {
      this.showOrderQTY = JSON.parse(event.shortage_json);
      this.objectQTYHeader = Object.keys(this.showOrderQTY);
      event.showOrders = true;
    } else {
      this.imgUrl = event.imageUrl || event.image_url;
      this.imgFlag = true;
      this.dialogVisible = true;
    }
  };
  public onOverlayClick: EmitType<object> = () => {
    this.infoData = [];
    this.dataSourcePurchaseOrder = new MatTableDataSource<any>([]);
    this.unavailableFlag = false;
    this.bomDialogVisible = false;
    this.imgFlag = false;
    this.createWorkOrdersFromUser ? delete this.createWorkOrdersFromUser : '';
    if (this.dialogVisible) {
      this.dialogVisible = false;
      return;
    }
    if (this.createWODialogVisible) {
      if (this.syncfusion.getReload()) {
        this.reloadPageEvent.emit();
        this.syncfusion.setReload(false);
      }
      this.createWODialogVisible = false;
      this.selectedDataObject = {};
      this.skuParentCode = '';
      this.poCode.setValue(null);
      this.deliveryDate.setValue(null);
    }
  };
  onRowSelected(event) {
    this.dataEmitted.emit(event);
  }
  closeImagePopup() {
    this.dialogVisible = false;
  }
  SKUs(event) {
    if (event?.unavailableSkuCodes?.includes(",")) {
      this.unavailableData = event.unavailableSkuCodes.split(",");
    } else if (event?.skuStockDetail) {
      this.skuStockDetail = JSON.parse(event.skuStockDetail);
    } else {
      this.unavailableData = event.unavailableSkuCodes;
    }
  }
  remove() {
    this.unavailableData = [];
    this.skuStockDetail = null;
  }
  showMore(data, column?) {
    if (this.skuLineGraphFlag) {
      this.dataEmitForSalesEvent.emit({ type: "sku", data: data });
    } else if (column) {
      this.dataEmitForSalesEvent.emit({ type: null, data: data });
    } else {
      this.dataEmitForSalesEvent.emit({ type: "product", data: data });
    }
  }

  action(actionType, controlId) {
    if (actionType === "update") {
      this.dataEmitForDynamicNormReview.emit({
        status: "MODIFIED",
        id: controlId,
        newNormValue: this.dynamicNormForm.get(controlId).value,
      });
    } else {
      this.dataEmitForDynamicNormReview.emit({
        status: actionType,
        id: controlId,
      });
    }
  }

  onEdit(id: any, event: MouseEvent) {
    this.edit.emit({ id, event });
  }

  onDelete(id: any, event: MouseEvent) {
    this.delete.emit({ id, event });
  }

  showBOMStatus(data) {
    this.bomDialogVisible = true;
    this.selected_bomdata = data;
  }
  showCreateWODialog(data) {
    if (this.grid) {
      var position = this.grid.element.querySelectorAll('.e-movablecontent')[0].scrollLeft || 0;
      this.selectedIndex = +data.index;
      window.localStorage.setItem("selectedRowIndex", this.selectedIndex);
      this.dataSharingService.updatePosition(position);
    }
    this.skuTableFetched = false;
    this.selectedDataObject = data;
    this.skuParentCode = data.parent;
    this.syncfusion.getSkuList(data.parent, data.location).subscribe((response: any) => {
      this.skuList = response;
      this.skuListHeaders = [
        {
          "field": "sku",
          "headerText": "EAN Code",
          "allowFiltering": false,
          "width": "180",
        },
        {
          "field": "size",
          "headerText": "Size",
          "allowFiltering": false,
          "width": "160",
        },
        {
          "field": "suggested_wo",
          "headerText": "Suggested Work Order",
          "allowFiltering": false,
          "width": "160",
        },
        {
          "field": "createWorkOrder",
          "headerText": "Create Work Order",
          "allowFiltering": false,
          "width": "160"
        },
        {
          "field": "colour",
          "headerText": "Colour",
          "allowFiltering": false,
          "width": "160"
        },
      ];
      this.workOrderData = {}; // Reset it first

      this.skuList.forEach(item => {
        if (item.sku) {
          this.workOrderData[item.sku] = item.suggested_wo;
          this.workOrderData[item.sku + '_fullData'] = { ...item };
        }
      });
      this.skuTableFetched = true;
      this.createWODialogVisible = true;
    });
  }
  dateValidator(control: FormControl) {
    const selectedDate = control?.value;
    if (!selectedDate) {
      return { required: true };
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (new Date(selectedDate) < today) {
      return { invalidDate: true };
    }

    return null;
  }

  updateWorkOrderData(data: any, value: any) {
    if (data?.sku) {
      if (!this.workOrderData[data.sku]) {
        this.workOrderData[data.sku] = value;
      }
      this.workOrderData[data.sku + '_fullData'] = { ...data };
    }
  }
  saveWorkOrders() {
    if (this.deliveryDate.invalid || this.poCode.invalid) {
      this.snack.open('Please select a valid delivery date and PO code', "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });;
      return;
    }
    this.bulkWorkOrdersPayload = [];
    this.excessWorkOrderDetails = {};
    for (const sku of Object.keys(this.workOrderData)) {
      if (!this.workOrderData[sku] || sku.includes('_fullData')) {
        continue;
      }
      const data: any = this.workOrderData[sku + '_fullData'] || {};
      const noNeedPopUP = data.suggested_wo >= this.workOrderData[sku];
      const quantity = this.workOrderData[sku];
      const workOrder = {
        materialCode: data.sku,
        locationCode: data.locationCode || data.location,
        qty: +quantity,
        poCode: this.poCode.value,
        estimatedDeliveryDate: this.deliveryDate.value.toLocaleDateString('en-CA')
      };

      this.bulkWorkOrdersPayload.push(workOrder);

      this.createWorkOrdersFromUser = {
        materialCode: data.materialCode || data.sku,
        locationCode: data.locationCode || data.location,
        qty: +quantity,
      };
      if (!noNeedPopUP && workOrder.qty > 0) {
        this.excessWorkOrderDetails[data.sku] = workOrder.qty - (+data.suggested_wo);
      }
    }
    if (Object.keys(this.excessWorkOrderDetails).length > 0) {
      this.excessWorkOrderKeys = Object.keys(this.excessWorkOrderDetails);
      this.dialogVisible = true;
    } else {
      if (this.bulkWorkOrdersPayload.length > 0) {
        this.handleCreateSkuWorkOrders();
      }
    }

  }

  handleConfirmation(data) {
    this.createWorkOrdersFromUser = {
      materialCode: data.materialCode || data.sku,
      locationCode: data.locationCode || data.location,
      qty: +data.userWorkOrders,
    };
    this.getConfigDataForSpecificRow = {
      pendingQty: data?.pendingQty ?? data?.pending_wo_qty ?? 0,
      piCreatedToday: data.piCreatedToday != null ? +data.piCreatedToday :  0,
      piToBeCreated: data.piToBeCreated != null ? +data.piToBeCreated : +data.suggested_wo
    }
    // const noNeedPopUP = data.piToBeCreated >= +(Number(data.piCreatedToday) + Number(data.userWorkOrders));
    const noNeedPopUP = data.piToBeCreated !== undefined ? data.piToBeCreated >= +(Number(data.piCreatedToday) + Number(data.userWorkOrders))
      : data.suggested_wo >= +Number(data.userWorkOrders);
    if (noNeedPopUP) {
      if (data.userWorkOrders > 0) {
        this.verifyToEnableIconColor.id = data.materialCode || data.sku;
        this.handleCreateWorkOrders()
        setTimeout(() => {
          this.verifyToEnableIconColor.id = "";
        }, 1000);
      }
    }
    else {
      if (data.userWorkOrders > 0) {
        this.dialogVisible = true;
      }
    }

  }
  determineWorkOrderAction() {
    if (this.reportName === 'Brandstudio_Replenishment') {
      this.handleCreateSkuWorkOrders();
    } else {
      this.handleCreateWorkOrders();
    }
  }

  handleCreateSkuWorkOrders() {
    this.skuTableFetched = false;
    this.poCode.setValue(null);
    this.deliveryDate.setValue(null);
    this.skuList = [];
    this.skuListHeaders = [];
    this.syncfusion.createSkuWorkOrders(this.bulkWorkOrdersPayload).pipe(switchMap(() =>
      this.syncfusion.getSkuList(this.selectedDataObject.parent, this.selectedDataObject.location))).subscribe({
        next: (response: any) => {
          this.syncfusion.setReload(true);
          this.skuList = [...response];
          this.skuListHeaders = [
            {
              "field": "sku",
              "headerText": "EAN Code",
              "allowFiltering": false,
              "width": "180",
            },
            {
              "field": "size",
              "headerText": "Size",
              "allowFiltering": false,
              "width": "160",
            },
            {
              "field": "suggested_wo",
              "headerText": "Suggested Work Order",
              "allowFiltering": false,
              "width": "160",
            },
            {
              "field": "createWorkOrder",
              "headerText": "Create Work Order",
              "allowFiltering": false,
              "width": "160"
            },
            {
              "field": "colour",
              "headerText": "Colour",
              "allowFiltering": false,
              "width": "160"
            },
          ];
          this.workOrderData = {};

          this.skuList.forEach(item => {
            if (item.sku) {
              this.workOrderData[item.sku] = item.suggested_wo;
              this.workOrderData[item.sku + '_fullData'] = { ...item };
            }
          });
          this.skuTableFetched = true;
          this.snack.open("Created Successfully", "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          delete this.createWorkOrdersFromUser;
        },
        error: (error) => {
          this.snack.open("Error while creating work orders", "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      });
    this.dialogVisible = false;
  }
  handleCreateWorkOrders() {
    this.syncfusion.createWorkOrders(this.createWorkOrdersFromUser).subscribe(
      (res) => {
        const material = this.createWorkOrdersFromUser.materialCode;
        const pendingQty = +this.createWorkOrdersFromUser.qty + this.getConfigDataForSpecificRow.pendingQty;
        const piCreatedToday = +this.createWorkOrdersFromUser.qty + this.getConfigDataForSpecificRow.piCreatedToday;
        const suggestedWorkOrderTextBox = this.getConfigDataForSpecificRow.piToBeCreated - piCreatedToday;
        const userWorkOrders = suggestedWorkOrderTextBox > 0 ? suggestedWorkOrderTextBox : 0;
        const normalizedData = Array.isArray(this.data) ? this.data : this.data?.result || [];
        normalizedData.map((m: any, index: number) => {
          if (m.materialCode === this.createWorkOrdersFromUser.materialCode || m.sku === this.createWorkOrdersFromUser.materialCode) {
            m.userWorkOrders = userWorkOrders;
          }
          return m;
        })

        this.grid.setCellValue(material, 'piCreatedToday', piCreatedToday);
        this.grid.setCellValue(material, 'pendingQty', pendingQty);
        this.grid.setCellValue(material, 'createWorkOrder', userWorkOrders);

        const message = `Created Successfully`;
        this.snack.open(message, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      },
      (error) => {
        const message = `${error.error.error}`;
        this.snack.open(message, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      },
      () => {
        delete this.createWorkOrdersFromUser;
      }
    );
    this.dialogVisible = false;
  }

  @HostListener("document:keydown.escape", ["$event"])
  handleEscKey(event: KeyboardEvent) {
    if (this.dialogVisible) {
      this.dialogVisible = false;
    }
    if (this.bomDialogVisible) {
      this.bomDialogVisible = false;
    }
    if (this.createWODialogVisible) {
      this.createWODialogVisible = false;
    }
  }

  handlePOPUP(fieldData, header) {
    console.log(fieldData, header)
    this.dataEmitForPOPUP.emit({ header, fieldData });
  }

  modifyName(name) {
    if (name == "qty") {
      return "Quantity";
    } else if (name == "code") {
      return "Order Number";
    } else if (name == "purchaseOrderDate") {
      return "Order Date";
    } else {
      return "Supplier";
    }
  }
  public rowDataBound(args: RowDataBoundEventArgs) {
    args.rowHeight = 48;
  }
  getUniqueCodes(data: any[]): void {
    const codes = new Set();
    // this.uniqueCodes = Array.from(codes);
    // this.fetchPinCounts(this.uniqueCodes).subscribe(pinCounts => {
    //   this.cachedPinCounts = pinCounts;
    //   console.log(this.cachedPinCounts, "Fetched pin counts");
    // });
    const gridData: any = data && data['result'] ? data['result'] : data;
    gridData?.forEach((item: any) => {
      this.dynamic_headers?.forEach((header: any) => {
        if (header.pins && item[header.field] !== undefined) {
          codes.add(item[header.field]);
        }
      });
    });
    this.uniqueCodes = Array.from(codes);
    this.fetchPinCounts(this.uniqueCodes).subscribe(pinCounts => {
      this.cachedPinCounts = pinCounts;
    });

  }

  fetchPinCounts(codes: string[]): Observable<any> {
    return this.pinsService.getPinsCount(codes).pipe(
      tap(pinCounts => {
      }),
      catchError(error => {
        console.error('Error fetching pin counts:', error);
        return of({});
      })
    );
  }
  refreshPinCount() {
    this.fetchPinCounts(this.uniqueCodes).subscribe(pinCounts => {
      this.cachedPinCounts = pinCounts;
    });
  }
  scrollVertical: boolean = false;
  onEditCobject(data: any, event: any) {
    if (this.grid) {
      var position = this.grid.element.querySelectorAll('.e-movablecontent')[0].scrollLeft || 0;
      this.selectedIndex = +data.index;
      window.localStorage.setItem("selectedRowIndex", this.selectedIndex);
    }
    this.dataSharingService.updatePosition(position);
    const dialogRef = this.dialog.open(CustomOverlayTemplateComponent, {
      width: '1000px',
      data: { parent: data.parent, location: data.location },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.syncfusion.getReload()) {
          this.getPersistentData()
          setTimeout(() => {
            this.restore();
          }, 5000);
        }
      }
    });
  }
  getPersistentData() {
    if (this.syncfusion.getReload()) {
      var persistData = (this.grid as GridComponent).getPersistData();
      window.localStorage.setItem("gridOrders", persistData);
      this.reloadPageEvent.emit();
      setTimeout(() => {
        this.restore();
      }, 5000);
    }
  }
  restore() {
    let value: string = window.localStorage.getItem("gridOrders") as string;
    const state = JSON.parse(value);
    if (state && this.grid) {
      (this.grid as GridComponent).setProperties(state);
      this.dataSharingService.triggerGridAction();
      this.syncfusion.setReload(false);
    }
  }
  clearPersistenceData() {
    window.localStorage.setItem("gridOrders", "");
    if (this.grid) {
      this.grid.destroy()
    }
  }

  // handleOverlayClose() {
  //   this.cObjData = {};
  // }
  // updateGridData() {
  //   this.data.result = this.data.result.map((row: any) => {
  //     const customObj = this.customObjList.find((item) => item.code === row.sku); // Match based on SKU
  //     if (customObj) {
  //       row = {
  //         ...row,
  //         plannedETA: customObj.plannedETA,
  //         remarks: customObj.remarks,
  //         remark: customObj.remarks,
  //       };
  //     }
  //     return row;
  //   });
  //   if (this.grid) {
  //     this.grid.setProperties({
  //       dataSource: { result: [...this.data.result], count: this.grid.dataSource['count'] },
  //     });
  //   }
  // }
  // onCustomObjListUpdated(newList: any[]) {
  //   this.customObjList = newList;
  //   this.updateGridData();
  // }
}
