import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { api } from '../properties/endpoints';


@Injectable({
  providedIn: 'root'
})
export class ForecastingService {
  getProductDemand(code: any) {
    const url=`${api.host}/material/forecast/gynoveda/product_demand/${code}`;
    return this.http.get(url);
  }
  
  constructor(private http: HttpClient) { }

  getLineGraph(code: string, filter?: string) {
    let url = `${api.host}/material/forecast/visualize/qty_sold?code=${code}`;
    
    if (filter) {
      url += `&category=${encodeURIComponent(filter)}`;
    }
  
    return this.http.get(url);
  }
  getListScenarios(){
    const url=`${api.host}/material/forecast/list-scenarios`;
    return this.http.get(url);
  }

  createForecastScenario(payload){
    const url=`${api.host}/material/forecast/save-scenario`;
    return this.http.put(url,payload);
  }
  updateForecastScenario(payload){
    const url=`${api.host}/material/forecast/save-scenario`;
    return this.http.post(url,payload);
  }
  deleteForecastScenario(code: string){
    const url = `${api.host}/material/forecast/delete-forecast?code=${code}` ;
    return this.http.delete(url,{responseType: 'text'})
  }
  generateForecast(code){
    const url=`${api.host}/material/forecast/generate-forecast`;
    return this.http.post(url,{code: code});
  }

  generateGynoChannelRevenueForecast(code){
    const url=`${api.host}/material/forecast/gynoveda/channel_revenue/generate`;
    return this.http.post(url,{code: code});
  }

  approveGynoForecast(code) {
    const url=`${api.host}/material/forecast/gynoveda/scenario/approve`;
    return this.http.post(url,{code: code});
  }


  getForecastStatus(code){
    const url=`${api.host}/material/forecast/scenario-status?code=${code}`;
    return this.http.get(url);
  }
  getGridResults(code){
    const url=`${api.host}/material/forecast/result?code=${code}`;
    return this.http.get(url);
  }
  uploadFile(file,uploadedBy,code){
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('uploadedBy', uploadedBy);
    formData.append('code',code)
    const url=`${api.host}/material/forecast/projection-update/upload`;
    return this.http.post(url,formData,{responseType: 'text'});
  }
  createComment(payload: any) {
    const url = `${api.host}/material/pins/comments/create`;
    return this.http.put<Comment>(url, payload);
  }
  getCommentsList(pinId: any){
    const url = `${api.host}/material/pins/comments/list/${pinId}`;
    return this.http.get<any>(url);  
  }
  getChannelRevenueForecast(code:any){
    const url = `${api.host}/material/forecast/gynoveda/channel_revenue/${code}`
    return this.http.get<any>(url);
  }
  // getChannelRevenueForecast(code: any){
  //   const url = `${api.host}/material/forecast/gynoveda/channel_revenue/${code}`
  //   return this.http.get<any>(url);
  // }
  getClusterPerformances(){
    const url = `${api.host}/material/forecast/gynoveda/cluster_performance`
    return this.http.get<any>(url)
  }
  overrideForecastedValue(payload: any){
    const url = `${api.host}/material/forecast/gynoveda/channel_revenue/update/`
    return this.http.post(url , payload)
  }
}
