<div class="bom-container" *ngIf="isLoading">
  <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="holder-card mat-elevation-z4">
      <div style="font-weight: lighter; width: 60%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 95%; height: 1em" class="flowsmart-placeholder"></div>
    </div>
  </ng-container>
</div>
<div *ngIf="salesChannelList" class="fs-flex justify-content-end">
  <!--<span class="fs-margin-0">Rate of sale for</span>-->
  <select class="selection-input" [(ngModel)]="label" (change)="handleValueChange($event)">
    <option value="all">All Sales Channel</option>
    <option *ngFor="let item of salesChannelList" [value]="item">{{
      item
      }}</option>
  </select>
  <!-- </mat-form-field> -->
</div>


<div class="charts-container" [class.full-width]="isExpanded || isExpanded == null">
  <div class="container-box" *ngIf="oldChart">
    <div class="charts-header">
      <span>{{ data?.parentProductCode ? data?.parentProductCode:data?.code }} &nbsp;&nbsp;{{
        data?.itemDescription?data?.itemDescription:data?.name }}
        {{ data?.materialDescription }}
      </span>
      <mat-icon (click)="toggleFullWidth()" *ngIf="!isExpanded" matTooltip="Full Screen View">open_in_full</mat-icon>
      <mat-icon (click)="toggleFullWidth()" *ngIf="isExpanded" matTooltip="Exit Full Screen">fullscreen_exit</mat-icon>
    </div>

    <app-dashboard-chatjs-molecule *ngIf="chartData" [chartData]="chartData" [defaultChartLabels]="defaultChartLabels"
      chartType="{{ chartData.type }}" enableZoom="true"></app-dashboard-chatjs-molecule>
  </div>
  <div class="container-box" *ngIf="isNewChartLoaded">
    <div class="charts-header">
      <span>{{ newChartDataToHeader?.data.code}} &nbsp;&nbsp;
        {{ newChartDataToHeader?.data.name }}
      </span>
      <mat-icon (click)="toggleFullWidth()" *ngIf="!isExpanded" matTooltip="Full Screen View">open_in_full</mat-icon>
      <mat-icon (click)="toggleFullWidth()" *ngIf="isExpanded" matTooltip="Exit Full Screen">fullscreen_exit</mat-icon>
    </div>
    <app-dashboard-chatjs-molecule *ngIf="chartData" [chartData]="newChartData"
      [defaultChartLabels]="defaultChartLabels" chartType="{{ chartData.type }}"
      enableZoom="true"></app-dashboard-chatjs-molecule>
  </div>
  <div class="full-width">
    <app-syncfusion-grid *ngIf="innerDynamicHeaders" (dataEmitForSalesEvent)="chartTypeRecived($event)"
      [dynamic_headers]="innerDynamicHeaders" [data]="innerGridData" [isOptionRequired]="true"></app-syncfusion-grid>
  </div>
</div>