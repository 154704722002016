import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadFilesService } from 'src/app/services/upload-files.service';

@Component({
  selector: 'app-upload-molecule',
  templateUrl: './upload-molecule.component.html',
  styleUrls: ['./upload-molecule.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease',
          style({ opacity: 1 })
        ),
        query("@*", [animateChild()], { optional: true })
      ]),
      transition(':leave', [
        query("@*", [animateChild()], { optional: false }),
      ]),
    ]),
    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('300ms ease',
          style({
            transform: 'translateX(100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)'
          }
          )
        )
      ])
    ])
  ],
})
export class UploadMoleculeComponent implements OnInit {
  @Input() templateData: any;
  @ViewChild('overlayTemplate', { static: false }) overlayTemplate!: TemplateRef<any>;
  overlayRef!: OverlayRef;
  file: any;
  public datePipe = new DatePipe("en-US");

  constructor(private snack: MatSnackBar, private overlay: Overlay, private viewContainerRef: ViewContainerRef,private uploadService:UploadFilesService) { }
  ngOnInit() { 
  }

  createOverlay() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'overlay-backdrop',
        positionStrategy: this.overlay.position().global().right('0px').top('0px'),
        scrollStrategy: this.overlay.scrollStrategies.block(),
        height: '100vh',
        width: '400px'
      });
      const templatePortal = new TemplatePortal(this.overlayTemplate, this.viewContainerRef);
      Promise.resolve().then(() => this.overlayRef.attach(templatePortal));
      this.overlayRef.backdropClick().subscribe(() => {
        this.closeOverlay();
      });
    }
  }
  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null!;
    }
  }
  getFile(event: any) {
    this.file = event.target.files[0];
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  formatFileSize(size: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return size.toFixed(0) + ' ' + units[index];
  }
  submit() {
    if (this.file.name) {
      this.uploadService.uploadFiles(this.file, this.templateData.code).subscribe(res => {
        this.showSnackbar(`${this.file.name} uploaded Successfully`);
        this.file = undefined;
        this.closeOverlay();
      }, (error) => {
        this.showSnackbar(`Failed to upload ${this.file.name}`);
      })
    }
  }
  cancel() {
    this.file = undefined;
    this.closeOverlay();
  }

  upload() {
    this.createOverlay();
  }
  downloadTemplate(){
    this.uploadService.downloadSampleTemplate(this.templateData.code).subscribe((res) => {
      this.downLoadFile(res, this.templateData.name, "application/ms-excel")
      const msg = 'Downloaded Successfully';
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    },
      (error) => {
        const msg = `Download failed please try again later`;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      })
  }
  downLoadFile(data: any, name: any, type: string) {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    let fileName = name + "_" + currentDateTime + ".xlsx";
    const blob: any = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}
