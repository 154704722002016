import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataResult, DataStateChangeEventArgs, FilterSearchBeginEventArgs, RowDataBoundEventArgs, TextWrapSettingsModel } from '@syncfusion/ej2-angular-grids';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { BusinessPartnerService } from 'src/app/services/business-partner.service';
import { SyncfusionService } from 'src/app/services/syncfusion.service';

@Component({
  selector: 'app-business-partner-list-molecule',
  templateUrl: './business-partner-list-molecule.component.html',
  styleUrls: ['./business-partner-list-molecule.component.scss']
})
export class BusinessPartnerListMoleculeComponent implements OnInit {
  currentClass: string = '';
  Heading: string = "Sales Point";
  helpText: string = "Drive smarter strategies with revenue and quantity trend analysis.";
  searchPartnerText: any;
  partnersList: any;
  partnersListHeaders: any[] = [];
  metaData: any[];
  frozenColumns: number = 0;
  tableDataFetched: boolean = false;
  count: any;
  searchPartnerSubject = new Subject<string>();
  payload = {
    "pageSize": 50,
    "pageNumber": 1,
  }

  constructor(
    private businessPartnerService: BusinessPartnerService, private syncfusion: SyncfusionService,
    private snack: MatSnackBar, private settingService: AppSettingService) { }

  ngOnInit(): void {
    this.getAttributes();
    this.getPartnersList(this.payload);
    this.searchPartnerSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      this.fetchFilteredPartners(searchTerm);
    });
  }
  fetchFilteredPartners(searchTerm: string) {
    const searchPayload = {...this.payload,searchTerm:searchTerm}
    if (searchTerm.trim().toLowerCase()) {
     this.getPartnersList(searchPayload)
    } else {
      this.getPartnersList(this.payload);
    }
  }
  filterPartners() {
    this.searchPartnerSubject.next(this.searchPartnerText);
  }
  getPartnersList(payload:any) {
    if (this.settingService.isRemoteGridMode()) {
      this.businessPartnerService.getBusinessPartnersList(payload).subscribe((res: any) => {
        if (res) {
          this.partnersList = (<DataResult>{
            result: res["items"],
            count: res['pageData'] ? res['pageData']['totalRecords'] : res['count'],
          });
          setTimeout(() => {
            this.tableDataFetched = true;
          });
        }
      });
    } else {
      this.businessPartnerService.getBusinessPartnersList(payload).subscribe((res: any) => {
        this.partnersList = res["items"];
        setTimeout(() => {
          this.tableDataFetched = true;
        });
      });
    }
  }
  getAttributes() {
    this.businessPartnerService.getMetaData().subscribe((response: any) => {
      this.metaData = response["metaData"];
      this.partnersListHeaders = this.getGridHeaders();
    }, (error) => {
      this.showSnackbar('Error while fetching attributes');
    })
  }
  getGridHeaders() {
    return this.metaData?.filter((obj: any) => obj?.listView === true).map(field => ({
      field: field.identifier,
      headerText: field.label,
      allowFiltering: field.allowFiltering ?? false,
      filter: { type: field.filterType || 'Menu' },
      width: field.width || '200px',
      navigate: field.identifier === 'companyName' ? true : false,
    }))
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  getHeadingStyle() {
    this.currentClass = '';
    if (!this.Heading?.includes("fetch")) {
      this.currentClass = 'fade-in';
      return {};
    } else {
      return {
        'font-style': 'italic',
        'color': 'grey',
        'font-weight': '200'
      };
    }
  }
  clearPartnerSearch() {
    this.searchPartnerText = '';
    this.getPartnersList(this.payload);
  }
  actionBegin(args: FilterSearchBeginEventArgs) {
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = this.count;
    }
    if (args.requestType === "filterbeforeopen") {
      setTimeout(() => {
        const filterDialog = (args as any).filterModel?.dlgDiv;
        if (filterDialog) {
          const filterElement = filterDialog.querySelector('.e-autocomplete');
          if (filterElement && filterElement.ej2_instances) {
            filterElement.ej2_instances[0].actionBegin = (args) => {
              args.cancel = true;
            }
          };
        }
      }, 0);
    }
  }
  navigateGrid(state: DataStateChangeEventArgs) {
    let pageData = this.syncfusion.convertGridStateToPageData(state);
    this.payload = pageData;
    let payload:any;
   if(this.searchPartnerText){
    payload  = {...pageData,searchTerm:this.searchPartnerText }
   }
   else{
    payload = {...pageData}
   }
    this.businessPartnerService.getBusinessPartnersList(payload).subscribe((res) => {
      if (res) {
        this.partnersList = (<DataResult>{
          result: res['items'],
          count: res['pageData'] ? res['pageData']['totalRecords'] : res['count']
        });
        setTimeout(() => {
          this.tableDataFetched = true;
        });
      }
    }, (error) => {
      this.tableDataFetched = true;
      console.error(error);
    })
  }

}
