<ejs-grid #grid class="full-width-table" [dataSource]='gridData' [allowPaging]='true' [allowFiltering]='true'
  [allowSorting]='true' [filterSettings]='filterOption' [allowSelection]='false' [enableHover]='false'
  [enableHeaderFocus]='true' [allowExcelExport]='true' [allowPdfExport]='true' [showColumnChooser]='true'
  [toolbar]='toolbar' (toolbarClick)='toolbarClick($event)' (actionBegin)='actionBegin($event)'
  [loadingIndicator]='loadingIndicator' [pageSettings]="pageSettings" [allowGrouping]='isGrouping'
  [groupSettings]='groupOptions' (dataStateChange)= 'dataStateChange($event)' allowTextWrap='true'
  [textWrapSettings]='wrapSettings' [enableStickyHeader]="true" height="400">
  <e-columns>
    <ng-container *ngFor="let header of dynamic_headers">
      <div *ngIf="header.image;then imageTemplate else default"></div>
      <ng-template #imageTemplate>
        <e-column [field]="header.field" [headerText]="header.headerText" [allowFiltering]="false"
                  width="120">
          <ng-template #template let-data>
            <div *ngIf="data[header.field]" class="info-icon cursor-pointer">
              <div (mouseenter)="data.showImage = true" (mouseleave)="data.showImage = false">
                <img style="float: left" src="{{data[header.field] | imageUrl}}" alt="Image" height="30" width="30" />
                <div class="{{data.showImage ? 'speech-bubble' : 'display-hide'}} left">
                  <img src="{{data[header.field] | imageUrl}}" alt="Image" />
                </div>
              </div>
            </div>
            <div *ngIf="!data[header.field]">
              <mat-icon>hide_image</mat-icon>
            </div>
          </ng-template>
        </e-column>
      </ng-template>
      <ng-template #default>
      <e-column *ngIf="header.headerText && header.headerText!='Description'"
        [enableGroupByFormat]="header.headerText=='buffer'?true:false" [field]="header.field"
        [headerText]="header.headerText" width="130">
      </e-column>
      <e-column *ngIf="header.headerText && header.headerText=='Description'" [field]="header.field"
        [headerText]="header.headerText" [allowFiltering]="false" width="380">
      </e-column>
      <e-column *ngIf="!header.field" [field]="header" width="150" [allowFiltering]="false">
        <ng-template #template let-data>
          <div class="status {{data[header]}}">
            {{ data[header] }}
          </div>
        </ng-template>
      </e-column></ng-template>
    </ng-container>
  </e-columns>
</ejs-grid>


<!-- *ngIf="!header.percentage && !header.state && header.allowFiltering" [field]="header.field"
        [headerText]="header.headerText" [allowFiltering]="header.allowFiltering" [filter]="header.filter"
        [width]="header.width" -->
<!-- [width]="header.width"
        [headerText]="header.headerText" -->
