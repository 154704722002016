import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SalesOrdersService {

  constructor(private http: HttpClient) { }

  searchCustomer(partner: any) {
    const url = `${api.host}/user/sales-order/partner/search/${partner}`;
    return this.http.get(url);
  }
  selectCustomer(partnerId: any) {
    const url = `${api.host}/user/sales-order/partner/select/${partnerId}`;
    return this.http.post(url, partnerId);
  }
  searchProducts(productName: string) {
    const url = `${api.host}/user/sales-order/product/search/${productName}`;
    return this.http.get(url);
  }
  addItemToCart(payload: any) {
    const url = `${api.host}/user/sales-order/cart/add-item`;
    return this.http.post(url, payload);
  }
  removeCartItem(cartId: any,productCode:any) {
    const url = `${api.host}/user/sales-order/cart/remove-item/${cartId}/${productCode}`;
    return this.http.delete(url,{responseType: 'text'});
  }
  viewCart(cartId: string) {
    const url = `${api.host}/user/sales-order/cart/items/${cartId}`;
    return this.http.get(url);
  }
  placeOrder(cartId: any) {
    const url = `${api.host}/user/sales-order/place-order/${cartId}`;
    return this.http.post(url, cartId,{responseType: 'text'});
  }
  customersList() {
    const url = `${api.host}/user/sales-order/partner/list`;
    return this.http.get(url);
  }
  productsList() {
    const url = `${api.host}/user/sales-order/product/list`;
    return this.http.get(url);
  }
  addInventory(payload:any){
    const url = `${api.host}/user/sales-order/add-inventory`;
    return this.http.post(url, payload,{responseType: 'text'});
  }
  updateItem(payload:any){
    const url = `${api.host}/user/sales-order/cart/update-item`;
    return this.http.post(url, payload);
  }
  getOrdersList(payload:any){
    const url = `${api.host}/user/sales-order/orders/list`;
    return this.http.post(url, payload);
  }
  getOrdersByPartner(payload,partnerId: any) {
    const url = `${api.host}/user/sales-order/orders/list/${partnerId}`;
    return this.http.post(url, payload);
  }
  getOrderDetails(orderId: string) {
    const url = `${api.host}/user/sales-order/order/entries/${orderId}`;
    return this.http.get(url);
  }
}
