<div class="sync-fusion-main-container">
  <app-header-content-help-text [Heading]="reportNameWithSpace" [helpText]="tagline"
    [showFilterButton]="showFilter" (filterButtonClicked)="toggleFilterOverlay()"></app-header-content-help-text>
  <div *ngIf="generatedDate" class="fs-hint-text flex-end fs-margin-0-1"><span>Reports are generated as on <strong>{{generatedDate |
        date}}</strong></span></div>
  <div *ngIf="reportLabelText" class="fs-hint-text flex-end fs-margin-0-1"><span>** {{reportLabelText}}</span></div>
  <ng-container *ngIf="tableDataFetched && appliedFilters">
    <app-filter-bay-reusable *ngIf="appliedFilters" [addFilters]="appliedFilters"></app-filter-bay-reusable>
  </ng-container>
  <div *ngIf="dynamic_headers?.length>0" class="grid-container" [ngStyle]="{'width': dynamic_headers.length <= 5 ? '90%' : '100%', 
  'margin': dynamic_headers.length <= 5 ? '0 auto' : '0'}">
    <app-syncfusion-grid [reportName]="reportName" [dynamic_headers]="dynamic_headers" [count]="count" [data]="data"
      *ngIf="tableDataFetched" (dataEmitForPOPUP)="handlePOPUP($event)"
      (navigateGrid)="navigateGrid($event)"></app-syncfusion-grid>
  </div>

  <app-popup-reuse-organsim *ngIf="fieldData && headerData" [showPOPUP]="visiblePOPUP" [fieldData]="fieldData"
    [headerData]="headerData" (POPUPFeedback)="visiblePOPUP=$event"></app-popup-reuse-organsim>

  <app-reusable-filter-molecule #childFilter *ngIf="filterData" [showFiltersPopup]="showFiltersPopup" [reportName]="reportName"
    [filterData]="filterData" (filterPopupClosed)="showFiltersPopup = $event"
    (filtersApplied)="handleFilterOutput($event)"
    (displayFilters)="selectedFilters($event)"></app-reusable-filter-molecule>
</div>