import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forecastHeader } from 'src/app/properties/forecasting_header';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ForecastingService } from 'src/app/services/forecasting.service';
import { fromEvent, interval, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ChartDataset, ChartOptions, Chart } from 'chart.js';
import { FilterBayService } from 'src/app/services/filter-bay.service';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { DataSharingService } from 'src/app/services/data-sharing.service';
// import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-forecastgyno-form-molecule',
  templateUrl: './forecastgyno-form-molecule.component.html',
  styleUrls: ['./forecastgyno-form-molecule.component.scss'],
  providers: [DatePipe]
})
export class ForecastgynoFormMoleculeComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formData;
  @ViewChild('inputField') inputField: ElementRef<HTMLInputElement>;
  @Input() categoryL1;
  @Input() scenarioDate: string;
  @Output() scenarioUpdated = new EventEmitter<string>();
  @Output() dataEmitForPOPUPOUTER = new EventEmitter<{}>();
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;

  gridData: any
  dynamicHeaders: any;
  formGroup: FormGroup;
  isEditable: boolean = false;
  name: FormControl = new FormControl('', [Validators.required, Validators.minLength(3)]);
  active: FormControl = new FormControl('');
  duration: FormControl = new FormControl({ value: 3, disabled: !this.isEditable }, [Validators.required, Validators.minLength(1)]);
  displayProjectionResult: boolean = true;
  timeoutId: any;
  intervalId: any;
  isCreateNewScenario: boolean
  statuses: string[] = [
    "Data Cleansing",
    "Feature Engineering",
    "Model Evaluation",
    "Running Predictions",
    "Completed",
  ];
  isEditing: boolean = false;
  private subscription: Subscription;
  showLastStep: boolean = true;
  isExpanded: boolean = true;
  panelOpenState = [true, false, false];
  // Could be in the possible states NEW|DEFINED|PREDICTED|APPROVED|DEMAND_APPLIED
  canPredict: boolean = false;
  canApprove: boolean = false;
  forecastStepState: boolean[] = [false, false, false];
  predictStepIndex: number = -1;
  isCommentsPopupOpen = false;
  commentsList: Comment[] = []; // Array to store comments
  newCommentText: string = ''
  scenarioId: string | null = null;
  userId: string | null = null;
  scenarioCode: string | null = null
  public revenueForecastData: any[] = [];
  public productDemandData: any[] = null;
  public productDemandHeaders: any[] = [];
  headerColumns: any[] = [];
  public clusterPerformanceData: any[] = []
  public gridColumns: any[] = [];
  public pageSettings: Object;
  // public editSettings: Object;
  @ViewChild('grid') grid: GridComponent;
  isTextareaFocused: boolean = false;
  dynamicNormForm: any;
  public editableColumns = ['avgMonthlyRevenue', 'clinicCount', 'perDoctorRevenue'];
  editSettings: { allowEditing: true, mode: 'Normal' };
  receivedData: any[] = [];
  selectedIndex = 0;
  editedRows: Set<number> = new Set();
  scrollPosition = 0;
  // panelOpenState: boolean[] = [true, false, false];

  constructor(private dataSharingService: DataSharingService, private authenticationService: AuthenticationService, private fb: FormBuilder, private forecastingService: ForecastingService, private filterBayService: FilterBayService,
    private snack: MatSnackBar, private authService: AuthenticationService, private datePipe: DatePipe, private settingService: AppSettingService) {
    this.pageSettings = { pageSize: 50 };
    this.editSettings = { allowEditing: true, mode: 'Normal' };
  }

  ngOnInit(): void {
    this.dataSharingService.editedData$.subscribe(data => {
      this.receivedData = data;
      this.editedRows = new Set(data.map(row => row.id));
    });
    this.headerColumns.forEach((column, index) => {
      // Make only the last 3 columns editable
      column.allowEditing = index >= this.headerColumns.length - 3;
    });
    this.getName()
    // this.fetchScenarios();
    if (this.formData?.code) {
      this.fetchComments(this.formData?.code);
    }
    if (this.formData) {
      this.name.setValue(this.formData?.name || '');
      this.active.setValue((this.formData?.active || this.scenarioDate) || false);
      this.duration.setValue(this.formData?.duration || 3);
      this.forecastingService.getForecastStatus(this.formData?.code).subscribe(response => {
        this.scenarioCode = response['code'];
        let currentStatus = response['currentStatus'];
        this.predictStepIndex = (currentStatus != 'Error' ? this.statuses.indexOf(currentStatus) : 99);
        if (currentStatus === 'Completed' || currentStatus === 'Error') {
          this.loadProjectionResult()
        }
      });
    }

    this.formGroup = new FormGroup({
      name: this.name,
      active: this.active,
      duration: this.duration
    });
    if (!this.formData) {
      this.createNewScenario();
    }
    this.computeFormState(this.formData);
    if (this.formData && this.formData.status && (this.formData.status == "Completed" || this.formData.demandConversionDate)) {
      this.openPanel(2);
    }


  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  onTabChange(event: any) {
    this.selectedIndex = event.index;
    if(this.selectedIndex === 2){
      this.fetchProductDemand();
    }else{
      this.loadProjectionResult()
    }
  }

  // Helper method to check if a column is editable
  isEditableColumn(field: string): boolean {
    return this.editableColumns.includes(field);
  }

  // Event to control editing of specific cells
  onCellEdit(args: any): void {
    if (!this.isEditableColumn(args.column.field)) {
      args.cancel = true; // Cancel editing for cells in non-editable columns
    }
  }
  actionEvent(event: any): void {
    // handle emitted event
  }
  onActionBegin(args: any) {
    if (args.requestType === 'beginEdit') {
      args.rowData.isEdited = true; // Mark row as edited
    }
  }
  toggleEdit(): void {
    this.isEditable = !this.isEditable;
    if (this.isEditable) {
      this.duration.enable(); // Enable the control
    } else {
      this.duration.disable(); // Disable the control
    }
  }
  onActionComplete(args: any) {
    if (args.requestType === 'save') {
      // Logic to handle saving data, if necessary
    }
  }
  saveRow() {
    this.scrollPosition = this.scrollContainer?.nativeElement ? this.scrollContainer?.nativeElement.scrollTop : 0;
    const transformedPayload = this.receivedData.map((item: any) => {
      const transformedItem: any = {
        revenueItemId: item.id
      };
      Object.keys(item).forEach(key => {
        if (key !== 'id' && key !== 'position') {
          transformedItem[key] = parseInt(item[key], 10);
        }
      });
      return transformedItem;
    });
    // Make the API call with the formatted payload
    this.forecastingService.overrideForecastedValue(transformedPayload).subscribe({
      next: (response) => {
        this.loadProjectionResult();
        this.showSnackbar("Data saved successfully!");
        this.editedRows.clear();
        this.receivedData = [];
        setTimeout(() => {
          this.restoreScrollPosition();
        }, 1000);
      },
      error: (error) => {
        console.error('Error saving data:', error);
        this.showSnackbar("Failed to save data. Please try again.");
      }
    });
    // this.editedRows.delete(data.id);
  }
  restoreScrollPosition() {
    if (this.scrollContainer.nativeElement) {
      this.scrollContainer.nativeElement.scrollTo({
        top: this.scrollPosition,
        behavior: 'smooth'
      });
      setTimeout(() => {
        this.dataSharingService.triggerGridAction();
      }, 100);
    }
  }
  // saveRow(data: any) {
  //   // Ensure receivedData is populated with the latest modified values
  //   console.log('clicked save')
  //   if (Object.keys(this.receivedData).length === 0) {
  //     alert('No changes detected');
  //     return;
  //   }

  //   // Pass receivedData directly as payload to the API call
  //   this.forecastingService.overrideForecastedValue(this.receivedData).subscribe({
  //     next: (response) => {
  //       console.log('Data saved successfully:', response);
  //       alert('Data saved successfully');
  //       this.receivedData = []; // Clear after saving
  //     },
  //     error: (error) => {
  //       console.error('Error saving data:', error);
  //       alert('Failed to save data');
  //     }
  //   });
  // }

  getName() {
    if (this.userId != null) {
      return this.userId;
    }
    try {
      let name = this.authenticationService.getUserName();
      let names = name?.split(" ");
      let nameId = names
        .map((value) => {
          return value.charAt(0);
        })
        .slice(0, 2)
        .join("")
        .toUpperCase();
      this.userId = nameId;
      return nameId;
    } catch (e) {
      console.error(e);
      return 'U';
    }
  }

  fetchComments(id: string) {
    this.getName()
    // this.forecastingService.getCommentsList('6720659ff9e61837fd3a7b6c').subscribe(
    this.forecastingService.getCommentsList(id).subscribe(
      (comments: Comment[]) => {
        this.commentsList = comments;
      },
      (error) => {
        console.error('Error fetching comments:', error);
      }
    );
  }
  fetchComments2(id: any) {
    this.forecastingService.getCommentsList(id).subscribe((res) => {
      this.commentsList = res;
    });
  }


  toggleCommentsPopup() {
    this.isCommentsPopupOpen = !this.isCommentsPopupOpen;
  }
  addComment(): void {
    if (this.newCommentText.trim()) {
      const payload = {
        code: this.formData.code,     // Unique identifier
        comment: this.newCommentText  // The typed comment
      };

      this.forecastingService.createComment(payload).subscribe(
        (newComment: Comment) => {
          // Add the new comment to the commentsList
          this.commentsList.push(newComment);
          // Clear the input field
          this.newCommentText = '';
          this.fetchComments(this.formData.code)
        },
        (error) => {
          console.error('Error creating comment:', error);
        }
      );
    }
  }
  createNewScenario() {
    this.name.setValue('');
    this.duration.setValue(3);
    this.active.setValue(this.formData?.active || false);
    this.isCreateNewScenario = true;
    this.scenarioDate = null;
    this.editScenario();
  }
  focusNameElement() {
    this.inputField.nativeElement.focus();
  }
  ngAfterViewInit() {
    if (this.isCreateNewScenario) {
      this.inputField.nativeElement.focus();
    }
  }
  editScenario() {
    this.isEditable = !this.isEditable;
    if (this.isEditable) {
      this.formGroup.get('duration').enable();
    } else {
      this.formGroup.get('duration').disable();
    }
  }
  handlePOPUP(event) {
    let header = event.header;
    let fieldData = event.fieldData;
    this.dataEmitForPOPUPOUTER.emit({ header, fieldData });
  }
  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    delete this.dynamicHeaders;
    delete this.gridData;
    delete this.revenueForecastData;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  openPanel(index: number) {
    this.panelOpenState = this.panelOpenState.map((_, i) => i === index);

  }

  createFormGroup(): FormGroup {
    return new FormGroup({
      category: new FormControl('', Validators.required),
      discountCutOff: new FormControl('', [Validators.required, Validators.minLength(1)]),
      marginCutoff: new FormControl('', [Validators.required, Validators.minLength(1)]),
      growthRate: new FormControl('', [Validators.required, Validators.minLength(1)]),
      returnRecoveryRate: new FormControl('', [Validators.required, Validators.minLength(1)]),
      conversionRate: new FormControl('', [Validators.required, Validators.minLength(1)]),
      revenuePerListing: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });
  }

  formatLabel(value: number): string {
    return `${value}`;
  }
  formatDate(date: string): string {
    return this.datePipe.transform(date, 'MMMM d, yyyy');
  }

  delete(event: any, code: string) {

    if (this.formData && this.formData.code) {
      this.forecastingService.deleteForecastScenario(code).subscribe(
        () => {
          const msg = `Deleted Successfully`;
          this.snack.open(msg, "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          this.scenarioUpdated.emit("");
        },
        (error) => {
          const msg = `Failed to delete scenario, please try again later`;
          this.snack.open(msg, "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      );
    }

  }


  approveForecastEvent(event: MatSlideToggleChange) {

    this.active.setValue(event.checked);
    if (event.checked) {
      this.approveForecast()
    }
    this.save();
  }

  fetchProductDemand() {
    this.productDemandHeaders = [];
    this.forecastingService.getProductDemand(this.formData.code).subscribe((response: any) => {
      if (response?.count) {
        this.productDemandData = response?.items;
        if (response?.headerContent) {
          this.productDemandHeaders = JSON.parse(response?.headerContent);
        }
      }
    })
  }

  approveForecast() {
    this.active.setValue(true);
    this.forecastingService.approveGynoForecast(this.formData.code).subscribe(res => {
      const msg = `Forecast Approved.Product demand will be generated in few minutes.`;
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
      this.displayProjectionResult = false;
      setTimeout(() => {
        this.loadProjectionResult();
      }, 20000);
      this.computeFormState(this.formData);
    }, (error) => {
      const msg = `Failed to generate demand from approved revenue.`;
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    });

  }


  save() {
    if (this.isCreateNewScenario) {
      this.forecastingService.createForecastScenario({ ...this.formGroup.value, categoryPrevisionList: null }).subscribe(res => {
        const msg = `Created Successfully`;
        this.scenarioUpdated.emit(res['code']);
        this.formData = res;
        this.isEditable = false;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        this.computeFormState(this.formData);
      }, (error) => {
        const msg = `Failed to create scenario, please try again later`;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      });
      this.isCreateNewScenario = false;
    } else {
      this.isCreateNewScenario = false;
      this.forecastingService.updateForecastScenario({ ...this.formData, ...{ ...this.formGroup.value } }).subscribe(res => {
        const msg = `Updated Successfully`;
        this.scenarioUpdated.emit(res['code']);
        this.computeFormState(res);
        this.isEditable = false;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      });
    }
  }

  computeFormState(formData) {
    if (formData == null) {
      //New Scenario
      this.canApprove = false;
      this.canPredict = false;
      this.formGroup.get('active').disable();
      this.forecastStepState = [false, false, false];
      return;
    }
    if (formData.demandConversionDate) {
      // Converted scenario should not be editable
      this.canApprove = false;
      this.canPredict = false;
      this.formGroup.get('active').disable();
      this.forecastStepState = [true, true, true];
      return;
    }

    if (!formData.active) {
      this.canPredict = true;
    } else {
      this.canPredict = false;
    }

    if ((formData.status && formData.status == "Completed" && !formData.active)) {
      this.canApprove = true;
      this.formGroup.get('active').enable();
    } else {
      this.canApprove = false;
      this.formGroup.get('active').disable();
    }

    if (formData.status == "Error") {
      //Error in Predict step 
      this.forecastStepState = [true, false, false];
    } else if (formData.status == "Completed" && !formData.active && !formData.demandConversionDate) {
      //Awaiting approval 
      this.forecastStepState = [true, true, false];
    } else if (formData.demandConversionDate || formData.active) {
      //All steps completed
      this.forecastStepState = [true, true, true];
    } else {
      this.forecastStepState = [true, false, false];
    }
  }

  uploadFile(event) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      // this.uploadName = event.target.files[0].name;
      this.forecastingService.uploadFile(event.target.files[0], this.authService.getUserName(), this.formData.code).subscribe((res) => {
        alert("File uploaded successfully...");
        // this.uploadName = 'upload File';
        // this.gridData = [];
        // this.dynamicHeaders = []
        // this.loadProjectionResult()
      }, (error) => {
        alert("Upload failed. Please try again");
        // this.uploadName = 'upload File';
      })
    }
  }
  generateForecast() {
    this.showLastStep = false;
    this.canPredict = false;
    this.forecastingService.generateGynoChannelRevenueForecast(this.formData.code).subscribe(response => {
      this.predictStepIndex = 0;
      this.gridData = [];
      this.revenueForecastData = [];
      this.pollStatus(3000);
    })
  }



  pollStatus(pollPeriod: number = 10000): void {
    this.subscription = interval(pollPeriod).pipe(
      switchMap(() => this.forecastingService.getForecastStatus(this.formData?.code)),
      takeWhile((response, index) => (response['currentStatus'] !== 'Completed' && response['currentStatus'] !== 'Error' && index < 10), true) // Include the last emitted value
    ).subscribe(response => {
      let currentStatus = response['currentStatus'];
      this.predictStepIndex = (currentStatus != 'Error' ? this.statuses.indexOf(currentStatus) : 99);
      if (currentStatus === 'Completed' || currentStatus === 'Error') {
        this.openPanel(2);
        this.loadProjectionResult();
        this.canPredict = true;
        this.canApprove = true;
        this.forecastStepState = [true, true, false];
      }
    });
  }

  loadProjectionResult(): void {
    this.displayProjectionResult = false;
    this.forecastingService.getChannelRevenueForecast(this.formData?.code).subscribe((response) => {
      this.revenueForecastData = response?.items
      if (response?.headerContent) {
        this.headerColumns = JSON.parse(response?.headerContent);
        setTimeout(() => {
          this.displayProjectionResult = true;
        });
      }
      this.fetchProductDemand();
    });
    this.forecastingService.getClusterPerformances().subscribe((response) => {
      this.clusterPerformanceData = response?.items;
      if (response?.headerContent) {
        this.gridColumns = JSON.parse(response?.headerContent);
      }
    })
  }

  deriveMonths(startMonth: string, duration: number): string[] {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const startDate = new Date(startMonth);
    const derivedMonths = [];
    for (let i = 0; i < duration; i++) {
      const monthIndex = (startDate.getMonth() + i) % 12;
      derivedMonths.push(months[monthIndex]);
    }
    return derivedMonths;
  }

}