<div class="charts-box" *ngIf="!filterResponse">
  <ng-container *ngFor="let number of [0]">
    <div class="mat-elevation-z4 fs-padding-1 chart-section">
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
      <p class="flowsmart-placeholder placeholder-template fs-padding-1"></p>
      <p class="flowsmart-placeholder placeholder-template fs-padding-2"></p>
      <p class="flowsmart-placeholder placeholder-template fs-padding-1"></p>
      <p class="flowsmart-placeholder placeholder-template fs-padding-2"></p>
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
    </div>
  </ng-container>
</div>
<div *ngIf="filterResponse && !visualizationData">
  <h3 style="text-align: center;">No Data Available</h3>
</div>
<div class="charts-box" *ngIf="filterResponse">
  <div class="selection-container">
    <div *ngFor="let option of options" class="selection-item" [class.active]="selectedOption === option"
    (click)="updateSelection(option)">
      {{ option | titlecase }}
    </div>
  </div>
  <app-dashboard-chatjs-molecule style="width: 80%;height: 60%;" [chartData]="visualizationData"
    *ngIf="filterResponse && visualizationData"></app-dashboard-chatjs-molecule>
</div>