import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  // Step 1: Create a BehaviorSubject to store and emit the edited data
  private editedDataSubject = new BehaviorSubject<any[]>([]);
  editedData$ = this.editedDataSubject.asObservable();
  private triggerActionSubject = new Subject<void>(); 
  triggerRestore$ = this.triggerActionSubject.asObservable();
  private positionSource = new BehaviorSubject<number>(0);
  currentPosition$ = this.positionSource.asObservable();


  // Method to update the edited data
  setEditedData(data: any[]) {
    this.editedDataSubject.next(data);
  }
  triggerGridAction() {
    this.triggerActionSubject.next();
  }
  updatePosition(position: number) {
    this.positionSource.next(position);
  }
}
