import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MoleculesModule } from "../molecules/molecules.module";
import { LoginFormComponent } from "./login-form/login-form.component";
import { MaterialModule } from "../../material.module";
import { MaterialOrganismComponent } from "./material-management/material-organism/material-organism.component";
import { MaterialFormOrganismComponent } from "./material-management/material-form-organism/material-form-organism.component";
// tslint:disable-next-line: max-line-length
import { ParameterManagementOrganismComponent } from "./parameters-management/parameter-management-material-organism/parameter-management-material-organism.component";
// tslint:disable-next-line: max-line-length
import { ParameterManagementFormOrganismComponent } from "./parameters-management/parameter-management-form-organism/parameter-management-form-organism.component";
// tslint:disable-next-line: max-line-length
import { ParameterManagementLocationsOrganismComponent } from "./parameters-management/parameter-management-locations-organism/parameter-management-locations-organism.component";
import { ReportsOrganismComponent } from "./reports/reports-organism/reports-organism.component";
import { StockCriteriaComponent } from "./stock-management/stock-criteria/stock-criteria.component";
import { StockFormOrganismComponent } from "./stock-management/stock-form-organism/stock-form-organism.component";
import { ShareOfBusinessOrganismComponent } from "./shareofbusiness-management/shareofbusiness-organism/shareofbusiness-organism.component";
import { ShareOfBusinessFormOrganismComponent } from "./shareofbusiness-management/shareofbusiness-form-organism/shareofbusiness-form-organism.component";
import { DynamicNormOrganismComponent } from "./dynamicnorm-management/dynamicnorm-organism/dynamicnorm-organism.component";
import { LocationsOrganismComponent } from "./locations-management/locations-organism/locations-organism.component";
import { LocationsFormOrganismComponent } from "./locations-management/locations-form-organism/locations-form-organism.component";
import { UserOrganismComponent } from "./user-management/user-organism/user-organism.component";
import { UserFormOrganismComponent } from "./user-management/user-form-organism/user-form-organism.component";
import { ParameterManagementStockCriteriaOrganismComponent } from "./parameters-management/parameter-management-stock-criteria-organism/parameter-management-stock-criteria-organism.component";
import { ParameterManagementShareOfBusinessOrganismComponent } from "./parameters-management/parameter-management-share-of-business-organism/parameter-management-share-of-business-organism.component";
import { BulkUploadOrganismComponent } from "./bulk-upload-organism/bulk-upload-organism.component";
import { DashboardTilesComponent } from "./dashboard-tiles/dashboard-tiles.component";
import { SsoCallbackComponent } from "./sso-callback/sso-callback.component";
import { ChartjsOrganismComponent } from "./chatjs-reports/chartjs-organism/chatjs-organism.component";
import { ChangePwdOrganismsComponent } from "./change-pwd-organisms/change-pwd-organisms.component";
import { UploadFilesComponent } from "./upload-files/upload-files.component";
import { SyncFusionOrganismsComponent } from "./sync-fusion-organisms/sync-fusion-organisms.component";
import { NgChartsModule } from "ng2-charts";
import { DashboardChartjsGridsOrgansimComponent } from "./dashboard-chartjs-grids-organsim/dashboard-chartjs-grids-organsim.component";
import { HistoricalReportsComponent } from "./historical-reports/historical-reports.component";
import { ScmManagementOrganismComponent } from "./scm-management-organisms/scm-management-organism/scm-management-organism.component";
import { ScmManagementFormOrganismComponent } from "./scm-management-organisms/scm-management-form-organism/scm-management-form-organism.component";
import { SalesForecastComponent } from "./sales-forecast/sales-forecast.component";
import { DialogModule } from "@syncfusion/ej2-angular-popups";
import { DynamicNormReviewOrganismComponent } from "./dynamic-norm-review-organism/dynamic-norm-review-organism.component";
import { BusinessIntelligenceOrgansimComponent } from "./business-intelligence-organsim/business-intelligence-organsim.component";
import { ProductsPerformanceAnalyzerComponent } from "./products-performance-analyzer/products-performance-analyzer.component";
import { ProductsSalesAnalyzerComponent } from "./products-sales-analyzer/products-sales-analyzer.component";
import { PipesModule } from "../pipes/pipes.module";
import { TrendsAndReportsOrgansimComponent } from "./trends-and-reports-organsim/trends-and-reports-organsim.component";
import { ReturnsAnalyzerOrganismsComponent } from "./returns-analyzer-organisms/returns-analyzer-organisms.component";
import { MatChipsModule } from "@angular/material/chips";
import { PuAccordianComponent } from "./pu-accordian/pu-accordian.component";
import { POPUPReuseOrgansimComponent } from "./popup-reuse-organsim/popup-reuse-organsim.component";
import { InventoryHealthOrganismComponent } from './inventory-health-organism/inventory-health-organism.component';
import { ProductionIntentPlanningOrganismsComponent } from './production-intent-planning-organisms/production-intent-planning-organisms.component';
import { BrokenSetReportOrganismComponent } from './broken-set-report-organism/broken-set-report-organism.component';
import { RmProcurementReportOrganismComponent } from './rm-procurement-report-organism/rm-procurement-report-organism.component';
import { ProductionIntentReportOrganismComponent } from './production-intent-report-organism/production-intent-report-organism.component';
import { ForecastingTemplateOrganismComponent } from './forecasting-template-organism/forecasting-template-organism.component';
import { RawMaterialDemandOrganismsComponent } from './rm-demand-allocation-organisms/rm-demand-allocation-organisms.component';
import {PredictPromotionListingOrganismsComponent}  from './predict-promotion-listing-organisms/predict-promotion-listing-organisms.component';
import { HeaderContentHelpTextComponent } from './header-content-help-text/header-content-help-text.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { WorkflowOrganismComponent } from "./workflow-organism/workflow-organism.component";
import { DiagramModule } from '@syncfusion/ej2-angular-diagrams';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { ContextMenuModule } from '@syncfusion/ej2-angular-navigations';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { GridModule } from "@syncfusion/ej2-angular-grids";
import { DashboardSummaryComponent } from './dashboard-summary/dashboard-summary.component';
import { TypingEffectDirective } from "../directives/typing-effect.directive";
import { MatExpansionModule } from "@angular/material/expansion";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InquiriesManagementOrganismComponent } from './inquiries-management-organism/inquiries-management-organism.component';
import { ForecastingGynovedaOrganismComponent } from "./forecasting-gynoveda-organism/forecasting-gynoveda-organism.component";
import { CustomObjectsOrganismComponent } from './custom-objects-organism/custom-objects-organism.component';
import {MatRadioModule} from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { DynamicCustomObjectOrganismComponent } from './dynamic-custom-object-organism/dynamic-custom-object-organism.component';
import { InventoryPivotOrganismComponent } from './inventory-pivot-organism/inventory-pivot-organism.component';
import { SizeRatioConverterOrganismComponent } from './size-ratio-converter-organism/size-ratio-converter-organism.component';
// import { SalesOrdersOrganismComponent } from './sales-orders-organism/sales-orders-organism.component';
import { SupplierPerformanceOrganismComponent } from './supplier-performance-organism/supplier-performance-organism.component';
import { ReOrderingOrganismComponent } from './re-ordering-organism/re-ordering-organism.component';
import { ReconciliationOrganismComponent } from './reconciliation-organism/reconciliation-organism.component';
import { BulkUploadFilesOrganismComponent } from './bulk-upload-files-organism/bulk-upload-files-organism.component';


const components = [
  LoginFormComponent,
  MaterialOrganismComponent,
  MaterialFormOrganismComponent,
  ParameterManagementOrganismComponent,
  ParameterManagementFormOrganismComponent,
  ParameterManagementLocationsOrganismComponent,
  ReportsOrganismComponent,
  StockCriteriaComponent,
  StockFormOrganismComponent,
  ShareOfBusinessOrganismComponent,
  ShareOfBusinessFormOrganismComponent,
  DynamicNormOrganismComponent,
  LocationsOrganismComponent,
  LocationsFormOrganismComponent,
  UserOrganismComponent,
  UserFormOrganismComponent,
  ParameterManagementStockCriteriaOrganismComponent,
  ParameterManagementShareOfBusinessOrganismComponent,
  BulkUploadOrganismComponent,
  DashboardTilesComponent,
  SyncFusionOrganismsComponent,
  HistoricalReportsComponent,
  DashboardChartjsGridsOrgansimComponent,
  ChartjsOrganismComponent,
  ReturnsAnalyzerOrganismsComponent,
  SalesForecastComponent,
  RawMaterialDemandOrganismsComponent,
  PredictPromotionListingOrganismsComponent,
  HeaderContentHelpTextComponent,
  ProductsSalesAnalyzerComponent,
  WorkflowOrganismComponent,
];
const directives = [
  TypingEffectDirective
]
@NgModule({
    exports: [...components, BusinessIntelligenceOrgansimComponent],
  declarations: [
    ...components,
    ...directives,
    SsoCallbackComponent,
    ChartjsOrganismComponent,
    ChangePwdOrganismsComponent,
    UploadFilesComponent,
    DashboardChartjsGridsOrgansimComponent,
    HistoricalReportsComponent,
    ScmManagementOrganismComponent,
    ScmManagementFormOrganismComponent,
    SalesForecastComponent,
    DynamicNormReviewOrganismComponent,
    BusinessIntelligenceOrgansimComponent,
    ProductsPerformanceAnalyzerComponent,
    TrendsAndReportsOrgansimComponent,
    ReturnsAnalyzerOrganismsComponent,
    PuAccordianComponent,
    POPUPReuseOrgansimComponent,
    InventoryHealthOrganismComponent,
    ProductionIntentPlanningOrganismsComponent,
    BrokenSetReportOrganismComponent,
    RmProcurementReportOrganismComponent,
    ProductionIntentReportOrganismComponent,
    ForecastingTemplateOrganismComponent,
    ChatbotComponent,
    DashboardSummaryComponent,
    InquiriesManagementOrganismComponent,
    ForecastingGynovedaOrganismComponent,
    CustomObjectsOrganismComponent,
    DynamicCustomObjectOrganismComponent,
    InventoryPivotOrganismComponent,
    SizeRatioConverterOrganismComponent,
    // SalesOrdersOrganismComponent,
    SupplierPerformanceOrganismComponent,
    ReOrderingOrganismComponent,
    ReconciliationOrganismComponent,
    BulkUploadFilesOrganismComponent,
  ],
  imports: [
    CommonModule,
    MoleculesModule,
    BrowserAnimationsModule,
    MaterialModule,
    RouterModule,
    NgChartsModule,
    DialogModule,
    PipesModule,
    MatChipsModule,
    FormsModule,
    MatSelectFilterModule,
    MatSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    DiagramModule,
    DragDropModule,
    MatMenuModule,
    ContextMenuModule,
    GridModule,
    MoleculesModule,
    MatExpansionModule,
    MatRadioModule,
    MatInputModule
  ],
})
export class OrganismsModule {}
