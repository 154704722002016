import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { FormBase } from '../../../interfaces/formInterface';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith , debounceTime, switchMap, catchError,} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dynamic-form-field-molecule',
  templateUrl: './dynamic-form-field-molecule.component.html',
  styleUrls: ['./dynamic-form-field-molecule.component.scss']
})
export class DynamicFormFieldMoleculeComponent implements OnInit, OnChanges {
  @Input() fieldData: FormBase<any>;
  @Input() form: UntypedFormGroup;
  @ViewChild('auto') auto!: MatAutocomplete;
  currentDate = new Date();
  // constructor(private fb: UntypedFormBuilder) { }
  // @Input() formData 
  locationControl = new FormControl('');
  filteredLocations$!: Observable<any[]>;

  constructor(private fb: UntypedFormBuilder, private http: HttpClient) {}

  ngOnChanges() {
  }
  removeObjects(index) {
    this.returnArray().removeAt(index);
  }
  returnArray(): UntypedFormArray {
    return this.form.get('components') as UntypedFormArray;
  }
  createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup({
      code: new UntypedFormControl('', Validators.required),
      qty: new UntypedFormControl('', [Validators.required, Validators.min(1)])
    });
  }
  addFormGroup() {
    (<UntypedFormArray>this.form.get('components')).push(this.createFormGroup());
  }

  onDateChange(event: any, fieldData: any){
    const date = new Date(event.value);
    if(fieldData.dynamic)
    {
      const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
      const selectedDate = adjustedDate.toISOString().split('T')[0];
      this.form.get(fieldData.identifier)?.setValue(selectedDate);
    }
  }

  ngOnInit() {
     this.filteredLocations$ = this.locationControl.valueChanges.pipe(
      debounceTime(300), 
      switchMap(value => this.fetchLocations(value)), 
      catchError(() => of([])) 
    );
  }
  get isValid() {
    return this.form.controls[this.fieldData.identifier].valid;
  }
 
  fetchLocations(search: string): Observable<any[]> {
    if (!search || search.trim() === '') {
      return of([]);
    }
    const apiUrl = `https://sandbox.api.flowsmart.ai/material/lookup/locations?limit=10&search=${search}`;
    return this.http.get<any>(apiUrl).pipe(
      map(response => response.map(item => item.label)), 
      catchError(() => of([])) 
    );
  }

  onLocationSelected(selectedEntity: any,data:any) {
    this.form.get(data.identifier)?.setValue(selectedEntity.code);
  }
}
