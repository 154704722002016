<div class="filters-list-wrapper fs-margin-0-1 fs-margin-bottom-1" *ngIf="filtersLength > 0">
  <div class="">
    <div class="display-flex" style="flex-wrap: wrap;gap: 5px;">
      <ng-container *ngFor="let filter of addFilters | keyvalue; let i = index">

        <ng-container *ngIf="filter.key !== 'filters'" class="single-filter-value">
          <span class="single-filter-value">
            <b>{{ filter.key=='DateRange'?'Date Range':filter.key }} :</b> <span>
              {{ filter.value }}
            </span>
            <!--<span class="material-icons" (click)="removeFilters(filter.key)">
              close</span>-->
          </span>
        </ng-container>
        <ng-container *ngIf="filter.key === 'filters'">
          <ng-container *ngFor="let value of filter.value | keyvalue; let j = index">
            <ng-container *ngIf="value.value != null">
              <span class="single-filter-value">
                <b><span>{{ value.key | capitalize }}</span>:</b><span> {{ value.value }}</span>
                <!--<span class="material-icons" (click)="removeFilters(value.key)">
                  close</span>-->
              </span>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>