<div class="sync-fusion-main-container">
  <div class="fs-section-block fs-margin-0 header-background">
    <div class="filter-visible">
      <div>
        <span class="mat-headline">Product Listings</span>
        <p class="fs-hint-text">A 360&deg; view of Product Performance</p>
      </div>
      <div class="d-flex mobile_screen">
        <div class="d-flex align-items-center form__fields">
          <mat-form-field class="floating-icon">
            <mat-label>Search with Text</mat-label>
            <div class="search-input">
            <input matInput [(ngModel)]="search_with_text" (keyup.enter)="searchWithText()"
              (ngModelChange)="onInputChange($event)">
            <mat-icon matTooltip="Search" class="click-icon" (click)="searchWithText()">search</mat-icon>
            </div>
          </mat-form-field>
          <div class="toggle-container">
            <button matTooltip="Grid View" mat-button (click)="setView('grid')" [class.active]="isTableView === false" class="left-button">
              <mat-icon>grid_on</mat-icon>
            </button>
            <button matTooltip="List View" mat-button (click)="setView('table')" [class.active]="isTableView === true" class="right-button">
              <mat-icon>table_rows</mat-icon>
            </button>
          </div>
          <mat-form-field class="floating-icon">
            <mat-label>Sort by</mat-label>
            <mat-select [(ngModel)]="sortByOption" (valueChange)="handleValueChange($event)">
              <mat-option *ngFor="let option of sortFilterOptions" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
            <div class="pointer__arrow" (click)="sortByAscOrDes()">
              <mat-icon matTooltip="Low to High" *ngIf="sortByAsc">arrow_upward</mat-icon>
              <mat-icon matTooltip="High to Low" *ngIf="!sortByAsc">arrow_downward</mat-icon>
            </div>
          </mat-form-field>

          <button style="height: fit-content;" (click)="showFilters()"
            class="mat-button mat-primary mat-raised-button">
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
            Filters
          </button>
          <!-- <button mat-button (click)="toggleView()" class="toggle-button">
            Switch to {{ isTableView ? 'Grid' : 'Table' }} View
          </button> -->
          <!-- <div style="width: 64%;display: flex;justify-content: flex-end;"> -->
            
          <!-- </div> -->
         
        </div>
      </div>
    </div>
      <app-filter-listing-bay-molecule [hidden]="!showOverlay" (closeFiltersOutput)="closeFilters()" (filterOutput)="getFilters($event)" (addFiltersOut)="selectedFilters($event)"
        [detectChangesFromParent]="detectChanges" [isDateFilter]="false"></app-filter-listing-bay-molecule>
  </div>

  <app-filter-bay-reusable *ngIf="addFilters" [addFilters]="addFilters"></app-filter-bay-reusable>
  <!-- <mat-paginator *ngIf="this.gridList && this.totalRecordCount >42" #paginator (page)="clickToNextPage($event)"
    [length]="totalRecordCount" [pageSize]="pageSize" [(pageIndex)]="currentPage" aria-label="Select page">
  </mat-paginator> -->
  <div *ngIf="!isTableView" class="grid-container mt-1">
    <div #productContainer>
      <div class="product-grid fs-section-block">
        <ng-container *ngIf="loading; else productGrid">
          <div class="loading-spinner">Loading Products...</div>
        </ng-container>

        <ng-template #productGrid>
          <ng-container *ngIf="gridList && gridList.length > 0; else noProducts">
            <ng-container *ngFor="let item of gridList">
              <div class="product-grid-item" [attr.data-code]="item.code">
                <app-product-card-molecule [item]="item" [showAdditionalInfo]="false"></app-product-card-molecule>
              </div>
            </ng-container>
          </ng-container>
          <!-- Template for "No Products Found" -->
          <ng-template #noProducts>
            <div class="no-products-found">
              No Products Found for "{{ search_with_text }}"
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="isTableView" class="m-2 grid-container">
    <ejs-grid [dataSource]="dataSource" allowTextWrap='true' [textWrapSettings]="textWrapSettings" [enableStickyHeader]="true" height="400">
      <!--(rowSelected)="onProductClick($event)" -->
      <e-columns>
        <e-column width="60" [isFrozen]="true">
          <ng-template #template let-data>
            <mat-icon style="cursor: pointer;" (click)="onQuickView(data)">visibility</mat-icon>
          </ng-template>
        </e-column>
        <e-column field="code" headerText="SKU Code" width="150" headerTextAlign="Left" [isFrozen]="true"></e-column>
        <e-column field="name" headerText="Name" width="150" headerTextAlign="Left"  [isFrozen]="!isSmallScreen"></e-column>
        <e-column field="lifeCycleStatus" headerText="Lifecycle Status" headerTextAlign="Left" width="120"
        [isFrozen]="!isSmallScreen"></e-column>
        <e-column [template]="imageTemplate" headerText="Image" headerTextAlign="Left" width="120"
        [isFrozen]="!isSmallScreen"></e-column>
        <e-column field="categoryLevel1" headerText="Category" headerTextAlign="Left" width="150"></e-column>
        <e-column field="shortTermRateOfSale" headerText="Weekly DROS" headerTextAlign="Left" width="120"></e-column>
        <e-column field="mediumTermRateOfSale" headerText="Monthly DROS" headerTextAlign="Left" width="120"></e-column>
        <e-column field="longTermRateOfSale" headerText="Quarterly DROS" headerTextAlign="Left" width="120"></e-column>
        <e-column field="conversion_rate" headerText="Conversion Rate" headerTextAlign="Left" width="120"></e-column>
        <e-column field="returnPercentage" headerText="Return Percentage" headerTextAlign="Left" width="120"></e-column>
        <e-column field="gross_margin" headerText="Gross Margin" headerTextAlign="Left" width="120"></e-column>
        <e-column field="maximum_retail_price" headerText="MRP" headerTextAlign="Left" width="120"
          [template]="currencyTemplate"></e-column>
        <e-column field="max_sell_price" headerText="Sell Price" headerTextAlign="Left" width="120"></e-column>
        <e-column field="costPrice" headerText="Cost Price" headerTextAlign="Left" width="120"
          [template]="currencyTemplate3"></e-column>
        <e-column field="max_discount_percent" headerText="Discount Percent" headerTextAlign="Left"
          width="120"></e-column>
        <e-column field="latestDateSold" headerText="Last Sold Date (in a year)" headerTextAlign="Left"
          width="200"></e-column>
      </e-columns>
    </ejs-grid>
    <ng-template #imageTemplate let-data>
      <div class="image-container">
        <img [src]="data.imageUrl" [alt]="data.name" width="50" height="50" />
        <!-- <div class="speech-bubble">
          <img [src]="data.imageUrl" [alt]="data.name" width="150" height="150" />
        </div> -->
      </div>
    </ng-template>
    <ng-template #currencyTemplate let-data>
      <span>₹{{ data.maximum_retail_price | floor | number:'1.0-0' }}</span>
    </ng-template>
    <ng-template #currencyTemplate2 let-data>
      <span>₹{{ data.max_sell_price | floor | number:'1.0-0' }}</span>
    </ng-template>
    <ng-template #currencyTemplate3 let-data>
      <span>₹{{ data.costPrice | floor | number:'1.0-0' }}</span>
    </ng-template>
    <ng-container *ngIf="selectedCode">
      <ejs-dialog  #dialog [width]="'1170px'" height="100%" [isModal]="true" [showCloseIcon]="true" [visible]="isDialogVisible"
        (close)="onDialogClose()" >
        <app-detail-page-molecule [showExtendedInformation]="true" [disableRoute]="true"
          [getCodeFromParent]="selectedCode"></app-detail-page-molecule>
      </ejs-dialog>
    </ng-container>
    <!-- <app-detail-page-molecule [disableRoute]="true"  [getCodeFromParent]=""></app-detail-page-molecule> -->
    <!-- <app-popup-reuse-organsim *ngIf="fieldData && headerData" [showPOPUP]="visiblePOPUP" [fieldData]="fieldData"
  [headerData]="headerData" (POPUPFeedback)="visiblePOPUP=$event"></app-popup-reuse-organsim> -->

    <!-- <ejs-grid
      [dataSource]="dataSource"
      [columns]="tableColumns"
      height="400"
      
    >
      <e-columns>
        <e-column *ngFor="let column of tableColumns" [field]="column.field" [headerText]="column.headerText" [width]="column.width" [isFrozen]="column.isFrozen" [template]="column.template"></e-column>
      </e-columns>
    </ejs-grid> -->
  </div>
  <mat-paginator *ngIf="this.gridList && this.gridList.length>10" (page)="clickToNextPage($event)"
    [length]="totalRecordCount" [pageSize]="pageSize" aria-label="Select page">
  </mat-paginator>
</div>