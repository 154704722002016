import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ListingGridService } from 'src/app/services/listing-grid.service';
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";
import { SalesOrdersService } from 'src/app/services/sales-orders.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sales-orders-organism',
  templateUrl: './sales-orders-organism.component.html',
  styleUrls: ['./sales-orders-organism.component.scss']
})
export class SalesOrdersOrganismComponent implements OnInit {
  @ViewChild('customerDialogTemplate') customerDialogTemplate!: TemplateRef<any>;
  isCreated: boolean = false;
  selectedCustomer: any;
  searchProductText: any = '';
  loading: boolean = false;
  filteredProductList: any[] = [];
  cartItems: any[] = [];
  showLargeImageId: string = '';
  showProducts: boolean = false;
  cartVisible: boolean = false;
  dialogRef!: MatDialogRef<any>;
  filteredCustomers: any = [];
  searchCustomerText: any = '';
  selectedCartId: any;
  isButtonEnabledMap: { [key: string]: boolean } = {};
  isStockTake: boolean = false;
  searchCustomerSubject = new Subject<string>();
  searchProductSubject = new Subject<string>();
  isViewingOrders: boolean = false;
  viewOrdersList: any[] = [];
  viewOrdersLoading: boolean = false;
  dateControl = new FormControl(null, [this.dateValidator]);
  orderCardFields: any[] = [
    { label: 'Order ID', key: 'thirdPartyOrderNumber' },
    { label: 'Sold to', key: 'partnerName' },
    { label: 'Qty', key: 'totalQuantity' },
    { label: 'Total Amount', key: 'totalAmount' },
    { label: 'Shipment Date', key: 'shipmentDate' },
    { label: 'Odered By', key: 'userId' }
  ];
  displayedColumns = [
    { key: 'materialName', label: 'Name' },
    { key: 'imageUrl', label: 'Image' },
    { key: 'quantity', label: 'Quantity' },
  ];
  displayedColumnKeys = this.displayedColumns.map(col => col.key);
  orderDetailList: any[] = [];
  selectedOrder: any = null;
  viewOrdersListResponse: any;
  pageIndex: number = 0;
  isOrderPlaced: boolean = false;
  isMobile: boolean = false;

  constructor(private snack: MatSnackBar, private dialog: MatDialog, private salesOrderService: SalesOrdersService) { }

  ngOnInit(): void {
    this.checkScreenSize();
    window.addEventListener('resize', () => this.checkScreenSize());
    this.selectedCartId = null;
    this.selectedCustomer = null;
    this.isCreated = false;
    this.showProducts = false;
    this.cartVisible = false;
    this.fetchCustomers();
    this.searchCustomerSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      this.fetchFilteredCustomers(searchTerm);
    });
    this.searchProductSubject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      this.fetchFilteredProducts(searchTerm);
    });
  }
  getDatePickerStyle() {
    return {
      width: window.innerWidth <= 600 ? '175px' : '',
    };
  }

  filterProducts() {
    this.searchProductSubject.next(this.searchProductText);
  }
  onSearchInputChange() {
    this.searchCustomerSubject.next(this.searchCustomerText);
  }
  fetchFilteredCustomers(searchTerm: string) {
    if (searchTerm.trim()) {
      this.salesOrderService.searchCustomer(searchTerm).subscribe(
        (res: any) => {
          this.filteredCustomers = res;
        },
        (error) => {
          this.showSnackbar('Error while searching customer. Please try again later');
        }
      );
    } else {
      this.fetchCustomers();
    }
  }
  fetchFilteredProducts(searchTerm: string) {
    if (searchTerm.trim()) {
      this.salesOrderService.searchProducts(searchTerm).subscribe((res: any) => {
        this.filteredProductList = res;
      }, (error) => {
        this.showSnackbar('Error while searching product. Please try again later');
      });
    } else {
      this.getProducts();
    }
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  checkScreenSize() {
    this.isMobile = window.innerWidth <= 900;
  }
  goBack() {
    if (this.isCreated && !this.cartVisible) {
      this.isCreated = false;
      this.selectedCustomer = null;
      this.cartItems = [];
    }
    if (this.isCreated && this.cartVisible) {
      this.cartVisible = false;
    }
    if (this.isStockTake) {
      this.isStockTake = false;
      this.isCreated = false;
      this.selectedCustomer = null;
    }
    if (this.isViewingOrders && !this.selectedOrder) {
      this.isViewingOrders = false;
      this.isCreated = false;
    }
    if (this.isViewingOrders && this.selectedOrder) {
      if (this.isOrderPlaced) {
        this.isViewingOrders = false;
        this.selectedOrder = null;
      }
      else {
        this.isViewingOrders = true;
        this.selectedOrder = null;
      }
    }
  }
  openCustomerDialog() {
    this.fetchCustomers();
    this.dialogRef = this.dialog.open(this.customerDialogTemplate, {
      width: '400px',
      maxWidth: '90vw',
      panelClass: 'custom-dialog',
      position: { top: '100px' }
    })
    this.dialogRef.afterClosed().subscribe(selectedCustomer => {
      if (selectedCustomer) {
        this.selectedCustomer = selectedCustomer;
        document.getElementById('customerButton')?.focus();
        if (this.isViewingOrders) {
          this.viewOrdersByPartner(selectedCustomer);
        } else {
          this.getProducts();
        }
      }
    });
  }
  selectCustomer(customer: any) {
    if (customer.partnerId) {
      this.salesOrderService.selectCustomer(customer.partnerId).subscribe((res: any) => {
        this.selectedCartId = res.cartId;
        this.dialogRef.close(customer);
        this.searchCustomerText = '';
      }, (error) => {
        this.showSnackbar('Error while selecting customer. Please try again later');
      });
    }

  }
  fetchCustomers() {
    this.salesOrderService.customersList().subscribe((data: any) => {
      this.filteredCustomers = data;
    });
  }
  clearSearch() {
    this.searchCustomerText = '';
    this.fetchCustomers();
  }

  changeActiveImage(args: string) {
    this.showLargeImageId = args;
  }

  getcustomerList(searchTerm: string) {
    this.salesOrderService.searchCustomer(searchTerm).subscribe((list: any) => {
      this.filteredCustomers = list;
    })
  }
  createOrder() {
    this.isCreated = true;
    this.selectedCustomer = null;
    this.showProducts = false;
    this.cartItems = [];
    this.selectedCartId = null;
  }
  stockTake() {
    this.isStockTake = true;
    this.isCreated = true;
    this.showProducts = false;
  }
  getProducts() {
    this.showProducts = true;
    this.loading = true;
    this.salesOrderService.productsList().subscribe((products: any) => {
      this.filteredProductList = products;
      this.loading = false;
    }, (error) => {
      this.showSnackbar('Error while fetching products. Please try again later');
    })
  }
  clearProductSearch() {
    this.searchProductText = '';
    this.getProducts();
  }
  updateStock(product: any) {
    const payload = {
      "materialCode": product.code,
      "locationCode": this.selectedCustomer.partnerId,
      "stock": product.orderQty,
    };
    this.salesOrderService.addInventory(payload).subscribe((res: any) => {
      this.isButtonEnabledMap[product.code] = false;
      this.showSnackbar(res);
    }, (error) => {
      this.showSnackbar('Failed to update')
    })
  }

  addToCart(product: any): void {
    if (product.orderQty > 0 || product.replacementQty > 0) {
      const payload = {
        "cartId": this.selectedCartId,
        "partnerId": this.selectedCustomer.partnerId,
        "materialCode": product.code || product.materialCode,
        "orderQty": product.orderQty,
        "replacementQty": product.replacementQty
      }
      this.salesOrderService.addItemToCart(payload).subscribe((res: any) => {
        this.cartItems = res;
        this.isButtonEnabledMap[product.code] = false;
      }, (error) => {
        this.showSnackbar('Error while adding to cart. Please try again later');
      })
    }
  }
  viewCart() {
    this.salesOrderService.viewCart(this.selectedCartId).subscribe((res: any) => {
      this.cartItems = res;
      this.cartVisible = true;
      if (this.cartItems.length <= 0) {
        this.cartVisible = false;
        this.getProducts();
      }
    }, (error) => {
      this.showSnackbar('Error while fetching cart items. Please try again later');
    })
  }
  onQuantityChange(product: any): void {
    this.isButtonEnabledMap[product.code] = product.orderQty > 0 || product.replacementQty > 0;
  }
  dateValidator(control: FormControl) {
    const selectedDate = control?.value;
    if (!selectedDate) {
      return { required: true };
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (new Date(selectedDate) < today) {
      return { invalidDate: true };
    }

    return null;
  }
  checkout() {
    if (!this.selectedCartId) {
      this.selectCustomer(this.selectedCustomer);
      return;
    }

    if (this.dateControl.invalid) {
      this.showSnackbar('Please select a valid delivery date');
      return;
    }
    const productToUpdate = this.cartItems[0];

    if (productToUpdate) {
      this.updateItem(productToUpdate, true);
    } else {
      this.showSnackbar('No items in cart to update');
    }
  }
  updateItem(product: any, checkoutTrigger: boolean = false) {
    // this.addToCart(product);
    const payload = {
      "cartId": this.selectedCartId,
      "partnerId": this.selectedCustomer.partnerId,
      "materialCode": product.code || product.materialCode,
      "orderQty": product.orderQty,
      "replacementQty": product.replacementQty,
      "deliveryDate": new Date(this.dateControl.value).toLocaleDateString('en-CA')
    };
    this.salesOrderService.updateItem(payload).subscribe((res: any) => {
      this.cartItems = res;
      if (checkoutTrigger) {
        this.placeOrder();
        this.isOrderPlaced = true;
      }
    }, (error) => {
      this.showSnackbar('Error while adding to cart. Please try again later');
    })
  }
  deleteItem(product: any): void {
    this.salesOrderService.removeCartItem(product.cartId, product.materialCode).subscribe((res: any) => {
      this.showSnackbar(res);
      this.viewCart();
    }, (error) => {
      this.showSnackbar('Error while deleting item. Please try again later');
    })
  }

  placeOrder() {
    if (this.selectedCartId) {
      this.salesOrderService.placeOrder(this.selectedCartId).subscribe((res: any) => {
        if (res) {
          const order = JSON.parse(res);
          this.viewOrderDetails(order);
          this.showSnackbar('Order created successfully');
          this.isCreated = false;
          this.showProducts = false;
          this.cartVisible = false;
          this.selectedCartId = null;
          this.isViewingOrders = true;
          this.dateControl.setValue(null);
        }
      }, (error) => {
        this.showSnackbar('Failed to create order');
      });
    } else {
      this.selectCustomer(this.selectedCustomer);
    }
  }
  clickToNextPage(event: any) {
    this.pageIndex = event.pageIndex;
    this.viewOrders();
  }
  viewOrders() {
    this.isViewingOrders = true;
    this.viewOrdersLoading = true;
    this.isCreated = false;
    this.cartVisible = false;
    const payload = {
      "pageSize": 10,
      "pageNumber": this.pageIndex + 1
    }
    this.salesOrderService.getOrdersList(payload).subscribe((response: any) => {
      this.viewOrdersList = response?.content;
      this.viewOrdersListResponse = response;
      this.viewOrdersLoading = false;
    }, (error) => {
      this.showSnackbar("Error while fetching orders")
    })
  }

  viewOrderDetails(order: any) {
    this.viewOrdersLoading = true;
    this.selectedOrder = order;
    this.orderDetailList = [];
    this.salesOrderService.getOrderDetails(order.orderId).subscribe((res: any) => {
      this.orderDetailList = res;
      this.viewOrdersLoading = false;
    }, (error) => {
      this.showSnackbar("Error while fetching order details");
    })
  }
  extractUserName(userId: string): string {
    if (!userId) return 'N/A';
    if (userId.includes('@')) {
      const namePart = userId.split('@')[0];
      return namePart.split('.')[0];
    }
    return userId;
  }
  viewOrdersByPartner(customer: any) {
    this.viewOrdersLoading = true;
    const payload = {
      "pageSize": 10,
      "pageNumber": 1
    }
    this.salesOrderService.getOrdersByPartner(payload, customer.partnerId).subscribe((response: any) => {
      this.viewOrdersList = response?.content;
      this.viewOrdersLoading = false;
    }, (error) => {
      this.showSnackbar('Error while fetching orders for Partner');
    })
  }
  clearFilter(event: any) {
    event.stopPropagation();
    this.selectedCustomer = null;
    this.viewOrders();
  }
  hasReplacementSku(): boolean {
    return this.orderDetailList.some(row => row.replacementSku);
  }

}
