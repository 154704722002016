import { animate, animateChild, query, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { FilterBayService } from 'src/app/services/filter-bay.service';
import { ClickStreamAnalyticsService, EventType } from 'src/app/services/clickstream-analytics-service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-reusable-filter-molecule',
  templateUrl: './reusable-filter-molecule.component.html',
  styleUrls: ['./reusable-filter-molecule.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease',
          style({ opacity: 1 })
        ),
        query("@*", [animateChild()], { optional: true })
      ]),
      transition(':leave', [
        query("@*", [animateChild()], { optional: false }),
      ]),
    ]),
    trigger('childAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('800ms cubic-bezier(0.2, 1, 0.3, 1)',
          style({ transform: 'translateX(0%)' })
        )
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('300ms ease',
          style({
            transform: 'translateX(100%)',
            boxShadow: '0px 0 00px 0px rgba(87,73,86,0.0)'
          })
        )
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class ReusableFilterMoleculeComponent implements OnInit, OnDestroy {
  @ViewChild('overlayPanel', { static: false }) overlayPanel!: TemplateRef<any>;
  @Input() showFiltersPopup: any;
  @Input() filterData: any;
  @Input() reportName: any;
  @Output() filterPopupClosed = new EventEmitter<boolean>();
  @Output() filtersApplied = new EventEmitter<any[]>();
  @Output() displayFilters = new EventEmitter<any[]>();
  overlayRef!: OverlayRef;
  selectedFilters: any = {};
  addFilters: any = {};
  appliedFilters: any[] = [];
  private filterSubscription!: Subscription;
  dateError: { [key: string]: boolean } = {};
  // Search term for filtering checkbox options.
  // searchTerm: string = '';
  searchTerms: { [key: string]: string } = {};
  isOpen: { [key: string]: boolean } = {};

  constructor(private cdr: ChangeDetectorRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private clickStream: ClickStreamAnalyticsService,
    private filterbayservice: FilterBayService,
    private snack: MatSnackBar) { }

  ngOnInit() {
    this.clearFilters();
    this.filterSubscription = this.filterbayservice.getFiltersFromParent
      .pipe(debounceTime(100))
      .subscribe((data) => {
        this.removeFilter(data);
      });
  }

  ngOnDestroy() {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

  createOverlay() {
    this.clearFilters();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    if (!this.overlayRef) {
      this.appliedFilters = this.filterbayservice.getReportFilters(this.reportName)["filters"];
      if (this.appliedFilters.length > 0) {
        this.selectedFilters = this.mapAppliedFiltersToSelection(this.appliedFilters);
      }
      this.overlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'overlay-backdrop',
        positionStrategy: this.overlay.position().global().right('0px').top('0px'),
        scrollStrategy: this.overlay.scrollStrategies.block(),
        height: '100vh',
        width: '400px'
      });
      const templatePortal = new TemplatePortal(this.overlayPanel, this.viewContainerRef);
      this.overlayRef?.attach(templatePortal);
      this.overlayRef.backdropClick().subscribe(() => {
        this.closeOverlay();
      });
    }

  }

  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null!;
    }
    this.filterPopupClosed.emit(false);
  }

  mapAppliedFiltersToSelection(appliedFilters: any[]) {
    let selected: any = {};
    this.filterData.forEach((filter: any) => {
      selected[filter.code] = filter.filterType === 'range' ? { from: null, to: null } : [];
    });

    appliedFilters.forEach((filter: any) => {
      if (filter.condition === 'GREATER_THAN_EQUAL') {
        selected[filter.column] = selected[filter.column] || { from: null, to: null };
        selected[filter.column].from = filter.value;
      }
      else if (filter.condition === 'LESS_THAN_EQUAL') {
        selected[filter.column] = selected[filter.column] || { from: null, to: null };
        selected[filter.column].to = filter.value;
      }
      else {
        if (!Array.isArray(selected[filter.column])) {
          selected[filter.column] = [];
        }
        if (Array.isArray(filter.value)) {
          selected[filter.column] = [...filter.value];
        } else {
          selected[filter.column].push(filter.value);
        }
      }
    });
    return selected;
  }

  toggleFilter(filterCode: string): void {
    this.isOpen[filterCode] = !this.isOpen[filterCode]; // Toggle state
  }

  validateDateRange(filterCode: string) {
    const fromDate = this.selectedFilters[filterCode]?.from;
    const toDate = this.selectedFilters[filterCode]?.to;
    if (fromDate && toDate && !(new Date(fromDate) < new Date(toDate))) {
      this.snack.open('Invalid range input', "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
      this.dateError[filterCode] = true;
    } else {
      this.dateError[filterCode] = false;
    }
  }

  applyFilters() {
    let isValid = true;
    Object.keys(this.selectedFilters).forEach(filterCode => {
      this.validateDateRange(filterCode);
      if (this.dateError[filterCode]) {
        isValid = false;
      }
    });
    if (isValid) {
      this.appliedFilters = [];
      this.filterData.forEach((filter: any) => {
        const column = filter.code;
        const type = filter.type.toUpperCase();
        if (filter.filterType === 'Checkbox') {
          const selectedValues = this.selectedFilters[column];
          if (selectedValues.length) {
            this.appliedFilters.push({
              column,
              condition: selectedValues.length === 1 ? 'EQUAL' : 'IN',
              value: selectedValues.length === 1 ? selectedValues[0] : selectedValues,
              type
            });
          }
        } else if (filter.filterType === 'range') {
          let from = this.selectedFilters[column].from;
          let to = this.selectedFilters[column].to;
          if (filter.type === 'Date') {
            if (from) from = new Date(from).toLocaleDateString('en-CA') + 'T00:00:00';
            if (to) to = new Date(to).toLocaleDateString('en-CA') + 'T00:00:00';
          }
          if (from) {
            this.appliedFilters.push({
              column,
              condition: 'GREATER_THAN_EQUAL',
              value: from,
              type
            });
          }
          if (to) {
            this.appliedFilters.push({
              column,
              condition: 'LESS_THAN_EQUAL',
              value: to,
              type
            });
          }
        }
      });
      this.addFilters = { filters: {} };
      const dateFilters: { [key: string]: { from?: string; to?: string } } = {};
      this.appliedFilters.forEach(({ column, condition, value, type }) => {
        if (type === 'DATE') {
          if (!dateFilters[column]) dateFilters[column] = {};
          if (condition === 'GREATER_THAN_EQUAL') dateFilters[column].from = value.split('T')[0];
          if (condition === 'LESS_THAN_EQUAL') dateFilters[column].to = value.split('T')[0];
        } else {
          this.addFilters.filters[column] = this.addFilters.filters[column] || [];
          this.addFilters.filters[column] = Array.isArray(value)
            ? [...this.addFilters.filters[column], ...value]
            : [...this.addFilters.filters[column], value];
        }
      });
      Object.entries(dateFilters).forEach(([column, { from, to }]) => {
        this.addFilters.filters[column] = from && to ? `${from} - ${to}` : from ? `From ${from}` : `Until ${to}`;
      });
      this.displayFilters.emit(this.addFilters);
      this.filtersApplied.emit(this.appliedFilters);
      this.filterbayservice.setReportFilters(this.reportName, this.addFilters, this.appliedFilters)
      this.clickStream.publishGAEvent(EventType.FILTER, {
        section: "Business Insights",
      });
      this.closeOverlay();
    }
  }

  onCheckboxChange(event: Event, filterCode: string, option: string) {

    const checked = (event.target as HTMLInputElement).checked;
    if (!this.selectedFilters[filterCode]) {
      this.selectedFilters[filterCode] = [];
    }
    if (checked) {
      // Avoid duplicates and trigger change detection
      this.selectedFilters[filterCode] = [...this.selectedFilters[filterCode], option];
    } else {
      // Remove the unchecked option and trigger change detection
      this.selectedFilters[filterCode] = this.selectedFilters[filterCode].filter(item => item !== option);
    }
    this.cdr.detectChanges();
  }

  trackByFn(index: number, item: string): string {
    return item;
  }

  /**
   * Filters the given list of options based on the searchTerm.
   * If searchTerm is empty, returns the original list.
   */
  // filteredOptions(options: string[], filterCode: string): string[] {
  //   if (!this.searchTerms[filterCode]) {
  //     return options;
  //   }
  //   const term = this.searchTerms[filterCode].toLowerCase();
  //   return options.filter(option => option.toLowerCase().includes(term));
  // }
  // filteredOptions(values: any[], code: string): any[] {
  //   if (!values) return [];
  
  //   const selected = this.selectedFilters[code] || [];
    
  //   return [...values].sort((a, b) => {
  //     const isSelectedA = selected.includes(a);
  //     const isSelectedB = selected.includes(b);
      
  //     return isSelectedB - isSelectedA; // Move selected values to the top
  //   });
  // }
  filteredOptions(values: any[], code: string): any[] {
    if (!values) return [];
  
    const selected = this.selectedFilters[code] || [];
    const searchTerm = this.searchTerms[code]?.toLowerCase() || '';
  
    // Filter values based on search term
    const filtered = values.filter(value => 
      value.toLowerCase().includes(searchTerm)
    );
  
    // Sort filtered values: selected items first
    return filtered.sort((a, b) => {
      const isSelectedA = selected.includes(a);
      const isSelectedB = selected.includes(b);
      return isSelectedB - isSelectedA; // Move selected values to the top
    });
  }
  
  
  removeFilter(column: any) {
    if (!this.selectedFilters[column]) return;
    if (Array.isArray(this.selectedFilters[column])) {
      this.selectedFilters[column] = [];
    }
    else if (typeof this.selectedFilters[column] === 'object' && this.selectedFilters[column] !== null) {
      this.selectedFilters[column] = { from: null, to: null };
    }
    else {
      delete this.selectedFilters[column];
    }
    this.applyFilters();
  }

  clearFilters() {
    this.selectedFilters = {};
    this.appliedFilters = [];
    this.filterData.forEach((filter: any) => {
      if (filter.filterType === 'Checkbox') {
        this.selectedFilters[filter.code] = [];
      } else if (filter.filterType === 'range') {
        this.selectedFilters[filter.code] = { from: null, to: null };
      }
    });
  }

  clearFilter(filterCode: string): void {
    // Clear the selected options for the given filter code
    this.filterData.get(filterCode).setValue([]);
    this.selectedFilters[filterCode] = [];
  }

  cancelFilters() {
    this.clearFilters();
    this.closeOverlay();
  }
}

