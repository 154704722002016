<mat-sidenav-container *ngIf="!isErrorInMenuService" class="sidenav-container">
  <mat-sidenav #drawer *ngIf="isLoggedIn && showNavTemplate" class="sidenav vertical-nav secondary-theme" fixedInViewport
    [attr.role]="((isHandset$ | async) || !isLoggedIn) ? 'dialog' : 'navigation'"
    [mode]="((isHandset$ | async) || !isLoggedIn) ? 'over' : 'side'"
    [opened]="((isHandset$ | async) || !isLoggedIn) === false">
    <!-- <mat-accordion> -->
    <app-new-navbar-template [openSidebar]="false" [mobile]="(isHandset$ | async)" [menu]="menu"
      (close)="drawer.close()"></app-new-navbar-template>
    <!-- </mat-accordion> -->
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="main-header mat-elevation-z4" color="primary" *ngIf="!isErrorInMenuService">
      <div class="top-nav" *ngIf="(isLoggedIn)">
        <div class="display-flex" style="gap: 10px;">
          <mat-icon *ngIf="(isHandset$ | async) && showNavTemplate" style="cursor:pointer" (click)="drawer.toggle()"
            aria-label="Side nav toggle icon">menu</mat-icon>
          <img class="logo" (error)="onImageError()" [src]="logo" alt="logo" routerLink='' />
        </div>
        <div style="display: flex;gap: 10px;align-items: center;">
          <div *ngIf="isRemote && role !== 'SalesAgent'">
            <app-download-molecule></app-download-molecule>
          </div>
          <div class="nav-links">
            <!-- <span class="chat_bot" *ngIf='role == "Admin"' (click)="navigateToChatbot()">
              <div class="bouncing-icon" title="Ask HAWK">
              <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve" width="30px" height="30px" fill="#EC9600" stroke="#EC9600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#EC9600;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <g> <path class="st0" d="M17.9,9.9c-4.6,0.9-6,2.3-6.9,6.9c-0.9-4.6-2.3-6-6.9-6.9C8.7,9,10.1,7.6,11,3C11.9,7.6,13.3,9,17.9,9.9z"></path> </g> <g> <path class="st0" d="M21.8,25c-3.2,0.6-4.1,1.6-4.8,4.8c-0.6-3.2-1.6-4.1-4.8-4.8c3.2-0.6,4.1-1.6,4.8-4.8 C17.6,23.4,18.6,24.4,21.8,25z"></path> </g> <g> <path class="st0" d="M29,15c-2.6,0.5-3.4,1.3-3.9,3.9c-0.5-2.6-1.3-3.4-3.9-3.9c2.6-0.5,3.4-1.3,3.9-3.9C25.6,13.7,26.4,14.5,29,15 z"></path> </g> <line class="st0" x1="5" y1="23" x2="5" y2="23"></line> <line class="st0" x1="28" y1="6" x2="28" y2="6"></line> </g></svg>
              </div>
            </span> -->
            <mat-icon *ngIf="showUploadIcon" class="cart-icon" routerLink ="/bulk-upload" aria-hidden="false">upload</mat-icon>
            <mat-icon *ngIf="showCart && role === 'Admin'" class="cart-icon" routerLink ="/sales-orders" aria-hidden="false">shopping_cart</mat-icon>
            <span class="chat_bot" *ngIf="role === 'Admin'" (click)="navigateToChatbot()">
              <!-- Search Bar for Larger Screens -->
              <div class="search-box-wrapper" *ngIf="!isMobileView">
                <div class="search-bar">
                  <span class="search-icon">
                    <svg
                      version="1.1"
                      id="Icons"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 32 32"
                      xml:space="preserve"
                      width="20px"
                      height="20px"
                      fill="#EC9600"
                      stroke="#EC9600"
                    >
                      <g id="SVGRepo_iconCarrier">
                        <style type="text/css">
                          .st0 {
                            fill: none;
                            stroke: #ec9600;
                            stroke-width: 2;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke-miterlimit: 10;
                          }
                        </style>
                        <circle class="st0" cx="14" cy="14" r="9"></circle>
                        <line class="st0" x1="27" y1="27" x2="20.5" y2="20.5"></line>
                      </g>
                    </svg>
                  </span>
                  <span class="search-placeholder">Ask HAWK...</span>
                </div>
              </div>
            
              <!-- Icon Only for Smaller Screens -->
              <div class="icon-only" *ngIf="isMobileView" title="Ask HAWK...">
                <svg
                  version="1.1"
                  id="Icons"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 32 32"
                  xml:space="preserve"
                  width="30px"
                  height="30px"
                  fill="#EC9600"
                  stroke="#EC9600"
                >
                  <g id="SVGRepo_iconCarrier">
                    <style type="text/css">
                      .st0 {
                        fill: none;
                        stroke: #ec9600;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-miterlimit: 10;
                      }
                    </style>
                    <circle class="st0" cx="14" cy="14" r="9"></circle>
                    <line class="st0" x1="27" y1="27" x2="20.5" y2="20.5"></line>
                  </g>
                </svg>
              </div>
            </span>
            
            <ul class="row top_nav_link">
              <li *ngIf='isLoggedIn'>
                <button mat-button [matMenuTriggerFor]="menu_app">
                  <span class="account_icon account_circle">{{getName()}}</span>
                </button>
                <mat-menu #menu_app="matMenu">

                  <button mat-menu-item (click)='logout()'>
                    <mat-icon>exit_to_app</mat-icon>
                    Sign out
                  </button>
                  <button mat-menu-item routerLink='/changepwd'>
                    <mat-icon>lock</mat-icon>
                    Change Password
                  </button>
                </mat-menu>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <img *ngIf="(!isLoggedIn)" class="col-xs-3 col-md-2 fs-margin-1 img-fluid h-100" src="assets/img/logo.png"
        alt="logo" />
    </mat-toolbar>
    <router-outlet></router-outlet>
    <footer>
      <p class="copyright fs-hint-text">
        Copyright &copy; {{year}} Flowsmart.</p>
    </footer>
  </mat-sidenav-content>

</mat-sidenav-container>



<app-my-nav-template *ngIf="isErrorInMenuService && isLoggedIn"></app-my-nav-template>