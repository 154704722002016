<!-- <h1 class="text-center">{{header}}</h1>
<canvas style="position: relative; height:40vh; width:80vw" #myChart></canvas> -->
<div class="mat-title fs-secondary-text">{{header}}</div>
<div class="charts-container" style="width: 100%;" [style.maxHeight]="maxHeight">
  <canvas style="display:inline;" #myChart></canvas>
  <ul *ngIf="this.htmlLegend" class="htmlLegend"></ul>
  <div class="zoom-tool">
    <mat-icon *ngIf="zoomInstruction" [matTooltip]="zoomInstruction" >zoom_in</mat-icon>
    <mat-icon *ngIf="zoomInstruction" (click)="isZoomed && resetZoom()" class="clickable" matTooltip="Reset Zoom">zoom_out</mat-icon>
  </div>
</div>
