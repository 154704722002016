<div class="fs-padding sync-fusion-main-container">
  <div class="fs-section-block fs-margin-0 header-background ">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="mat-headline">Explore Product Trends</span>
        <p class="fs-hint-text">Adapt availability to Market Dynamics and Consumer Preferences</p>
      </div>
      <button style="height: fit-content;" (click)="showFilters()" class="mat-button mat-primary mat-raised-button filter-button">
        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
        Filters
      </button>
    </div>
  </div>
    <app-material-master-filter-molecule [hidden]="!showOverlay" (closeFiltersOutput)="closeFilters()" analyticsFilter="true" [defaultDateRange]="appliedFilters" isDateFilter="true" changesDetected="false" [productTypes]="['TRADED','FG']"
      (emitAppliedFilters)="applyFilters($event)" ></app-material-master-filter-molecule>
      


  <ng-container *ngIf="tableDataFetched">
    <app-filter-bay-reusable [MaterialMaster]="true" *ngIf="displayAppliedFilters"
      [addFilters]="displayAppliedFilters"></app-filter-bay-reusable>
  </ng-container>
  <mat-tab-group class="custom-tab-group" [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="tabChanged($event)">
    <mat-tab label="Product Performance" [class.mat-elevation-z8]="selectedTabIndex == 0">
      <div *ngIf="selectedTabIndex == 0">
        <div class="graphFilter">
          <mat-form-field appearance="outline">
            <mat-label>Template</mat-label>
            <mat-select [(ngModel)]="selectedTemplate" (selectionChange)="onTemplateChange($event.value)">
              <mat-option *ngFor="let option of templateOptions" [value]="option.code">
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="!tableDataFetched" style="display:flex;justify-content: space-between;">
          <ng-container *ngFor="let number of [0, 1, 2,4,5]">
            <div style="width: 20%;margin-top:20px">
              <p style="
                font-style: normal;
                text-align: center;
                width: 80%;
                height: 10em;
                margin: 0px auto;
              " class="flowsmart-placeholder"></p>
            </div>
          </ng-container>
        </div>
        
        
        <div *ngIf="dynamic_headers?.length>0" class="fs-margin-0-1" style="height: 76%;">
          <app-syncfusion-grid [reportName]="reportName" [dynamic_headers]="dynamic_headers" [count]="count"
            [data]="data" [freezeColumns]="2" *ngIf="tableDataFetched"
            (dataEmitForPOPUP)="handlePOPUP($event)"></app-syncfusion-grid>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Performance by Product Attributes" [class.mat-elevation-z8]="selectedTabIndex == 0">
      <div *ngIf="selectedTabIndex == 1">
        <div *ngIf="!dimensionsLoaded" style="display:flex;justify-content: space-between;">
          <ng-container *ngFor="let number of [0, 1]">
            <div style="width: 20%;margin-top:20px">
              <p style="
                font-style: normal;
                text-align: center;
                width: 80%;
                height: 4em;
                margin: 0px auto;
              " class="flowsmart-placeholder"></p>
            </div>
          </ng-container>
        </div>
        <form *ngIf="dimensionsLoaded" class="visual-filter-form" [formGroup]="optionsFilter">
          <div class="visual-switcher" *ngIf="dimensionsSummaryData">
            <span class="icon" (click)="setTableView(false)"><mat-icon class="tool-tip" matTooltip="View as chart" [ngClass]="{
              'selected': !showTableView,
              'not-selected' : showTableView
            }">bar_chart</mat-icon></span>
            <span class="icon" (click)="setTableView(true)"><mat-icon class="tool-tip" matTooltip="View as table"  [ngClass]="{
              'selected': showTableView,
              'not-selected' : !showTableView
            }">table_chart</mat-icon></span>
          </div>
          <mat-form-field >
            <mat-label>Select Dimension</mat-label>
            <mat-select #matSelect formControlName="dimension" [multiple]="false"
              panelWidth="auto">
              <mat-icon class="close-button" (click)="matSelect.close()" color="primary">close</mat-icon>
              <mat-option *ngFor="let item of dimensions" value="{{ item.code }}">{{ item.label
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Select Metric</mat-label>
            <mat-select #matSelect formControlName="metric" [multiple]="false"
              panelWidth="auto">
              <mat-icon class="close-button" (click)="matSelect.close()" color="primary">close</mat-icon>
              <mat-option *ngFor="let item of metrics" value="{{ item.code }}">{{ item.label
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <div *ngIf="!dimensionOptionSelected || !dimensionOptionSelected.dimension || !dimensionOptionSelected.metric" style="display:flex;justify-content: space-between;">
          <ng-container *ngFor="let number of [1]">
            <div style="font-style: normal;text-align: center;width: 80%;margin: 60px auto;font-weight: bold;font-size: 1.3em;display: flex;justify-content: center;">
              <mat-icon color="primary" style="margin-bottom:16px;overflow: visible;">add_box</mat-icon>
              <p style="
                font-style: normal;text-align: center;font-size: 24px;line-height: 24px;
              ">Select product dimension and metric to get product insights.</p>
            </div>
          </ng-container>
        </div>
        <div *ngIf="!dimensionsSummaryData && dimensionOptionSelected && dimensionOptionSelected.dimension && dimensionOptionSelected.metric" style="display:flex;justify-content: space-between;">
          <ng-container *ngFor="let number of [0, 1, 2,4,5]">
            <div style="width: 20%;margin-top:20px">
              <p style="
                font-style: normal;
                text-align: center;
                width: 80%;
                height: 10em;
                margin: 0px auto;
              " class="flowsmart-placeholder"></p>
            </div>
          </ng-container>
        </div>
        <div class="chart-section fs-margin-1" *ngIf="chartData != null && !showTableView">
          <app-dashboard-chatjs-molecule enableZoom="true" header="" [chartData]="chartData"></app-dashboard-chatjs-molecule>
        </div>
        <div class="chart-section fs-margin-1" *ngIf="tableData != null && showTableView">
          <app-dashboard-grids-molecule class="chartjs-charts"
          [header]="tableData.header" [chartData]="tableData" [frozenColumns]="1"></app-dashboard-grids-molecule>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>


</div>