<div class="sales-main-container">
    <ng-container *ngIf="cartVisible; else showContent">
        <div class="cart-products-list-container">
            <div class="cart-header">
                <div class="cart-header-left">
                    <mat-icon class="back-arrow" (click)="goBack()">keyboard_arrow_left</mat-icon>
                    <span class="mat-headline">Items in Cart</span>
                </div>
                <mat-form-field appearance="outline" [ngStyle]="getDatePickerStyle()">
                    <mat-label>Delivery Date</mat-label>
                    <input matInput [matDatepicker]="picker" [formControl]="dateControl" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="dateControl.hasError('required')">
                        Please select a delivery date
                    </mat-error>
                    <mat-error *ngIf="dateControl.hasError('invalidDate')">
                        Invalid delivery date
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="sales-cart-list-overflow-container">
                <div *ngFor="let product of cartItems" class="product-card-cont">
                    <div class="product-card">
                        <div class="sales-product-image-container">
                            <img [src]="product.imageUrl ? product.imageUrl : '/assets/img/Image_Coming_Soon.jpg'"
                                alt="{{ product.name }}" (mouseenter)="changeActiveImage(product.imageUrl)"
                                (mouseleave)="changeActiveImage('')" />
                        </div>
                        <div *ngIf="product.imageUrl"
                            class="{{product.imageUrl === showLargeImageId ? 'speech-bubble' : 'display-hide'}}">
                            <img src="{{product.imageUrl}}" alt="Image" />
                        </div>
                        <div class="sales-product-details-container">
                            <p class="product-title" matTooltip="{{ product.materialName }}" matTooltipPosition="above">
                                {{ product.materialName }}</p>
                            <div class="quantity-input-container">
                                <mat-form-field class="quantity-mat-field green-input">
                                    <input type="number" matInput [(ngModel)]="product.orderQty" placeholder="Frsh Qty">
                                </mat-form-field>
                                <mat-form-field class="quantity-mat-field red-input">
                                    <input type="number" matInput [(ngModel)]="product.replacementQty"
                                        placeholder="Rpl Qty">
                                </mat-form-field>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <button (click)="updateItem(product)">Update Item</button>
                                <button (click)="deleteItem(product)">Delete Item</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <button class="mat-button mat-primary mat-raised-button" (click)="checkout()">Place Order</button>
        </div>
    </ng-container>
    <ng-template #showContent>
        <ng-container *ngIf="isCreated; else notCreated">
            <div class="sales-content-container">
                <!-- creating a order container -->
                <div class="sales-fields-search-container">
                    <div class="sales-fields-container">
                        <mat-icon class="back-arrow" (click)="goBack()">keyboard_arrow_left</mat-icon>
                        <span class="customer-context-container"
                            [ngClass]="selectedCustomer ? 'customer-selected' : ''">
                            <span class="label">Ordering for</span>
                            <button mat-button (click)="openCustomerDialog()" id="customerButton"
                                class="no-border-button">
                                {{ selectedCustomer?.companyName ? selectedCustomer.companyName :
                                selectedCustomer?.partnerId || 'Select Customer' }}
                            </button>
                        </span>
                    </div>
                    <div class="sales-search-cart-container">
                        <div class="search-container" *ngIf="showProducts">
                            <input matInput [(ngModel)]="searchProductText" (input)="filterProducts()"
                                placeholder="Search Products">
                            <mat-icon style="cursor: pointer;" matSuffix
                                (click)="searchProductText ? clearProductSearch() : filterProducts()">{{
                                searchProductText ? 'close' : 'search'}}</mat-icon>
                        </div>
                        <div class="cart-container" (click)="viewCart()" *ngIf="showProducts && !isStockTake">
                            <div class="cart-icon-wrapper">
                                <mat-icon class="cart-icon">shopping_cart</mat-icon>
                                <span class="cart-count">{{ cartItems.length }}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- rendering products below -->
                <ng-container *ngIf="showProducts">
                    <ng-container *ngIf="loading; else productGrid">
                        <div class="loading-text">Loading Products...</div>
                    </ng-container>
                    <ng-template #productGrid>
                        <div class="sales-product-list-overflow-container">
                            <ng-container *ngIf="filteredProductList.length > 0; else noProducts">
                                <div *ngFor="let product of filteredProductList" class="product-card-cont">
                                    <div class="product-card">
                                        <div class="sales-product-image-container">
                                            <img [src]="product.imageUrl ? product.imageUrl : '/assets/img/Image_Coming_Soon.jpg'"
                                                alt="{{ product.name }}"
                                                (mouseenter)="changeActiveImage(product.imageUrl)"
                                                (mouseleave)="changeActiveImage('')" />
                                        </div>
                                        <div *ngIf="product.imageUrl"
                                            class="{{product.imageUrl === showLargeImageId ? 'speech-bubble' : 'display-hide'}}">
                                            <img src="{{product.imageUrl}}" alt="Image" />
                                        </div>
                                        <div class="sales-product-details-container">
                                            <p class="product-title">{{ product.name }}</p>
                                            <div class="quantity-input-container">
                                                <mat-form-field class="quantity-mat-field"
                                                    [ngClass]="isStockTake ? '' : 'green-input'">
                                                    <input style="border: none;outline: none;" type="number" matInput
                                                        [(ngModel)]="product.orderQty"
                                                        (ngModelChange)="onQuantityChange(product)"
                                                        [placeholder]="isStockTake ? 'Stock' : 'Frsh Qty'">
                                                </mat-form-field>
                                                <mat-form-field class="quantity-mat-field red-input"
                                                    *ngIf="!isStockTake">
                                                    <input type="number" matInput [(ngModel)]="product.replacementQty"
                                                        (ngModelChange)="onQuantityChange(product)"
                                                        placeholder="Rpl Qty">
                                                </mat-form-field>
                                            </div>
                                            <button class="mat-button mat-primary mat-raised-button"
                                                (click)="isStockTake ? updateStock(product) : addToCart(product)"
                                                [disabled]="!isButtonEnabledMap[product.code]">
                                                {{ isStockTake ? 'Update Stock' : 'Add to Cart' }}
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <ng-template #noProducts>
                            <div class="no-customers-message">
                                <mat-icon>block</mat-icon>
                                <p>No products found</p>
                            </div>
                        </ng-template>
                    </ng-template>
                </ng-container>

            </div>
        </ng-container>
        <!-- welocme screen before order creation -->
        <ng-template #notCreated>
            <ng-container *ngIf="isViewingOrders; else welcomeScreen">
                <div class="view-orders-list-container">
                    <div class="view-orders-header">
                        <div class="cart-header-left">
                            <mat-icon class="back-arrow" (click)="goBack()">keyboard_arrow_left</mat-icon>
                            <span class="mat-headline">{{ selectedOrder ? 'Order Details' : 'Orders' }}</span>
                        </div>
                        <button *ngIf="!selectedOrder" class="mat-button mat-primary mat-raised-button"
                            (click)="openCustomerDialog()">
                            Filter by {{ selectedCustomer?.companyName ? selectedCustomer.companyName :
                            selectedCustomer?.partnerId || 'partner' }}
                            <span *ngIf="selectedCustomer" class="clear-icon" (click)="clearFilter($event)">✖</span>
                        </button>
                    </div>

                    <ng-container *ngIf="!selectedOrder">
                        <ng-container *ngIf="viewOrdersLoading; else viewOrders">
                            <div class="loading-text">Loading Orders...</div>
                        </ng-container>
                        <ng-template #viewOrders>
                            <div class="view-orders-list-overflow-container">
                                <ng-container *ngIf="viewOrdersList.length > 0; else noOrders">
                                    <div *ngFor="let order of viewOrdersList" class="order-card-cont">
                                        <div class="order-card">
                                            <div class="order-card-content-container"
                                                *ngFor="let field of orderCardFields">
                                                <label>{{ field.label }}</label>
                                                <span><ng-container
                                                        *ngIf="field.key === 'shipmentDate'; else normalField">
                                                        {{ order[field.key] ? (order[field.key] | date:'yyyy-MM-dd') :
                                                        'N/A' }}
                                                    </ng-container>
                                                    <ng-template #normalField>
                                                        <ng-container *ngIf="field.key === 'partnerName'">
                                                            {{ order.partnerName ? order.partnerName + ' (' +
                                                            order.partnerId + ')' : order.partnerId }}
                                                        </ng-container>
                                                        <ng-container *ngIf="field.key === 'userId'">
                                                            {{ extractUserName(order.userId) }}
                                                        </ng-container>

                                                        <ng-container
                                                            *ngIf="field.key !== 'partnerName' && field.key !== 'userId'">
                                                            {{ order[field.key] || 'N/A' }}
                                                        </ng-container>
                                                    </ng-template></span>
                                            </div>
                                            <button class="mat-button mat-primary mat-raised-button"
                                                (click)="viewOrderDetails(order)">
                                                View
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <mat-paginator *ngIf="viewOrdersListResponse && viewOrdersListResponse.content.length > 0" (page)="clickToNextPage($event)"
                                [length]="viewOrdersListResponse.totalElements" [pageIndex]="pageIndex"  [pageSize]="viewOrdersListResponse.size" aria-label="Select page">
                              </mat-paginator>
                            <ng-template #noOrders>
                                <div class="no-customers-message">
                                    <mat-icon>block</mat-icon>
                                    <p>No Orders found</p>
                                </div>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                    <ng-container *ngIf="selectedOrder">
                        <ng-container *ngIf="viewOrdersLoading; else viewOrderDetails">
                            <div class="loading-text">Loading order details...</div>
                        </ng-container>
                        <ng-template #viewOrderDetails>
                            <div class="order-detail-content-container">
                                <div class="order-detailedView-container" [ngStyle]="(hasReplacementSku() && !isMobile) ? {'margin-top': '20px'} : {}">
                                    <div *ngFor="let field of orderCardFields">
                                        <label>{{ field.label }}</label>
                                        <span><ng-container *ngIf="field.key === 'shipmentDate'; else normalField">
                                                {{ selectedOrder[field.key] ? (selectedOrder[field.key] |
                                                date:'yyyy-MM-dd')
                                                :
                                                'N/A' }}
                                            </ng-container>
                                            <ng-template #normalField>
                                                <ng-container *ngIf="field.key === 'partnerName'">
                                                    {{ selectedOrder.partnerName ? selectedOrder.partnerName + ' (' +
                                                    selectedOrder.partnerId + ')' : selectedOrder.partnerId }}
                                                </ng-container>
                                                <ng-container *ngIf="field.key === 'userId'">
                                                    {{ extractUserName(selectedOrder.userId) }}
                                                </ng-container>

                                                <ng-container
                                                    *ngIf="field.key !== 'partnerName' && field.key !== 'userId'">
                                                    {{ selectedOrder[field.key] || 'N/A' }}
                                                </ng-container>
                                            </ng-template></span>
                                    </div>
                                </div>
                                <div class="table-content-container">
                                    <div class="info" *ngIf="hasReplacementSku()">
                                        <span class="replacement-label">
                                            <div class="replacement-sku-header"></div>Replacement SKU*
                                        </span>
                                    </div>
                                    <div class="table-container">
                                        <table mat-table [dataSource]="orderDetailList" class="mat-elevation-z8">
                                            <ng-container *ngFor="let column of displayedColumns"
                                                [matColumnDef]="column.key">
                                                <th mat-header-cell *matHeaderCellDef> {{column.label}} </th>
                                                <td mat-cell *matCellDef="let row"
                                                    [ngClass]="{'red-text': column.key === 'materialName' && row.replacementSku}">
                                                    <ng-container *ngIf="column.key === 'imageUrl'; else normalColumn">
                                                        <img [src]="row[column.key]" alt="Image" class="table-image">
                                                    </ng-container>
                                                    <ng-template #normalColumn>
                                                        {{ row[column.key] }}
                                                    </ng-template>
                                                </td>
                                            </ng-container>
                                            <tr mat-header-row *matHeaderRowDef="displayedColumnKeys"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumnKeys;"
                                                [ngClass]="{'replacement-row': row.replacementSku}">
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </ng-container>
        </ng-template>
        <ng-template #welcomeScreen>
            <div class="create-order-button-container">
                <button class="mat-button mat-primary mat-raised-button" (click)="createOrder()">
                    <mat-icon style="overflow: visible;">add_shopping_cart</mat-icon>Create
                    Order</button>
                <button class="mat-button mat-primary mat-raised-button" (click)="stockTake()">
                    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                        width="24px" fill="#000">
                        <path
                            d="M440-183v-274L200-596v274l240 139Zm80 0 240-139v-274L520-457v274Zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11Zm200-528 77-44-237-137-78 45 238 136Zm-160 93 78-45-237-137-78 45 237 137Z" />
                    </svg>Stock
                    Take</button>
                <button class="mat-button mat-primary mat-raised-button" (click)="viewOrders()">
                    <mat-icon style="overflow: visible;">list_alt</mat-icon>View
                    Orders</button>
                <!-- <button class="mat-button mat-primary mat-raised-button" (click)="createOrder()">Update
                    Jio Code</button> -->
            </div>
        </ng-template>
    </ng-template>
    <ng-template #customerDialogTemplate>
        <mat-dialog-content class="customer-list-overlay-container">
            <div class="search-customer-container">
                <input matInput [(ngModel)]="searchCustomerText" (input)="onSearchInputChange()"
                    placeholder="Search Customer" />
                <mat-icon style="cursor: pointer;" matSuffix
                    (click)="searchCustomerText ? clearSearch() : onSearchInputChange()"> {{
                    searchCustomerText ? 'close' : 'search' }}</mat-icon>
            </div>
            <mat-list class="customer-list-container">
                <ng-container *ngIf="filteredCustomers.length > 0; else noCustomers">
                    <mat-list-item class="list-item" *ngFor="let customer of filteredCustomers"
                        (click)="selectCustomer(customer)">
                        <div class="customer-info">
                            <span class="contact-name">{{ customer.name}}</span>
                            <span class="company-name">{{ customer.partnerId }}</span>
                        </div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
            <ng-template #noCustomers>
                <div class="no-customers-message">
                    <mat-icon>person_off</mat-icon>
                    <p>No customers found</p>
                </div>
            </ng-template>
        </mat-dialog-content>
    </ng-template>
</div>