import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

@Component({
  selector: 'app-header-content-help-text',
  templateUrl: './header-content-help-text.component.html',
  styleUrls: ['./header-content-help-text.component.scss']
})
export class HeaderContentHelpTextComponent {
  @Input() Heading;
  @Input() helpText;
  @Input() showFilterButton: boolean = false;
  @Output() filterButtonClicked = new EventEmitter<void>();
  templateData: any;
  showTemplateBtn: boolean = false;
  reportName: string = '';
  isButtonDisabled: boolean = false;
  currentClass: string = '';
  showOverlay: boolean = false;
  constructor(private uploadService: UploadFilesService, private route: ActivatedRoute,private snack: MatSnackBar,) { }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.reportName = params['reportName'];
      this.getTemplateData();
    });
  }
  getTemplateData() {
    this.showTemplateBtn = false;
    const code = this.reportName === 'Store_Product_Performance'
      ? "RTW_Bulk_Upload" : this.reportName === 'In_Store_Replenishment_Plan'
        ? "in_store_replenishment"
        : null;
    if (code !== null) {
      this.uploadService.fetchTemplateDetails(code).subscribe((response: any) => {
        this.templateData = response;
        this.showTemplateBtn = true;
      })
    }
  }
  getHeadingStyle() {
    this.currentClass = '';
    if (!this.Heading?.includes("fetch")) {
      this.currentClass = 'fade-in';
      return {};
    } else {
      return {
        'font-style': 'italic',
        'color': 'grey',
        'font-weight': '200'
      };
    }
  }

  showFilters() {
    this.filterButtonClicked.emit();
  }
  regenerate(){
    if (this.isButtonDisabled) return; 
    const payload = {};
    this.uploadService.generateReplenishment(payload).subscribe((response:any)=>{
      const message = response.message;
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.isButtonDisabled = true;

      // Re-enable after 10 minutes (600000 ms)
      setTimeout(() => {
        this.isButtonDisabled = false;
      }, 600000);
    })
  }

}
