import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FilterSettingsModel } from '@syncfusion/ej2-angular-grids';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { ReconciliationServiceService } from 'src/app/services/reconciliation-service.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

@Component({
  selector: 'app-bulk-upload-files-organism',
  templateUrl: './bulk-upload-files-organism.component.html',
  styleUrls: ['./bulk-upload-files-organism.component.scss'],
  providers: [DatePipe]
})
export class BulkUploadFilesOrganismComponent implements OnInit, OnDestroy  {
  file: any
  selectedOption: string | null = null;
  dataSource: any = [];
  settings: any;
  pollingSubscription: Subscription | null = null;
  fileTypes = [];
  status: any;
  reportNameWithSpace: any;
  isProcessing: boolean = false;
  processingRowId: any;
  isUploading: boolean = false;
  dynamicHeaders: any = [];
  isDeleting: boolean = false;
  private intervalSubscription!: Subscription;
  public filterOption: FilterSettingsModel = { type: "Excel" };

  constructor(private snack: MatSnackBar, private uploadService: UploadFilesService,public datepipe: DatePipe,) { }

  ngOnInit() {
    this.reportNameWithSpace = "Just a moment, we're fetching the status of uploaded files...";
    this.dynamicHeaders = [
      {
        "field": "filename",
        "headerText": "File Name",
        "allowFiltering": true,
        "width": "300",
        "filter": {
          "type": "Menu"
        }
      },
      {
        "field": "subType",
        "headerText": "Type",
        "allowFiltering": false,
        "width": "120"
      },
      {
        "field": "uploadedBy",
        "headerText": "Uploaded By",
        "allowFiltering": false,
        "width": "100"
      },
      {
        "field": "status",
        "headerText": "Status",
        "allowFiltering": false,
        "width": "100"
      },
      {
        "field": "processMsg",
        "headerText": "Process Msg",
        "allowFiltering": false,
        "width": "120"
      },
      {
        "field": "recordDate",
        "headerText": "Record Date",
        "allowFiltering": false,
        "width": "120"
      },
      {
        "field": "Download",
        "headerText": "Download",
        "allowFiltering": false,
        "width": "100",
        "actions": true
      }
    ]
    this.fetchUploadedFiles();
    // this.startPolling(); 
  }
  startPolling() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.intervalSubscription = interval(1000) 
    .pipe(
      switchMap(() => this.uploadService.getFilesList()),
      tap(res => {
        this.dataSource = res; // Update data source
        if (res.some(file => file.status.includes('PROCESS'))) {
          this.reportNameWithSpace = "Just a moment, we're fetching the status of uploaded files...";
        } else {
          this.reportNameWithSpace = "Channel-wise Finance MIS";
          this.stopPolling();
        }
      }),
      takeWhile(res => res.some(file => file.status.includes('PROCESS')), true)
    )
    .subscribe();
  }
  getFile(event: any) {
    this.file = event.target.files[0];
  }
  
  stopPolling() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  formatFileSize(size: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return size.toFixed(0) + ' ' + units[index];
  }
  formatDate(dateString?) {
    const dateObj = new Date(dateString);
    return this.datepipe?.transform(dateObj, 'dd-MMM hh:mm a');
  }
  fetchUploadedFiles(){
    this.isUploading = false;
    this.uploadService.getFilesList().subscribe(res => {
      this.dataSource = res;
      this.reportNameWithSpace = "Bulk Upload";
      if (this.dataSource.some(file => file.status.includes("PROCESS"))) {
        this.startPolling();
      }
    });
  }
  ngOnDestroy(): void {
   this.stopPolling();
  }
  downloadFile(row) {
    this.uploadService.downloadFile(row.id,row.filename).subscribe(
      ({ blob, filename }) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
      },
      error => {
        console.error('Error downloading file:', error);
      }
    );
  }
}
