import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { dynamicNormReview } from "src/app/properties/dynamic-review-headers";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DynamicReviewService } from "src/app/services/dynamic-review.service";
import { FormControlService } from "src/app/services/form-control.service";
import { ListingGridService } from "src/app/services/listing-grid.service";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { Router } from '@angular/router';
import { AppSettingService } from "src/app/services/app-setting.service";
import { Chart, ChartData, ChartOptions } from "chart.js";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";

@Component({
  selector: "app-detail-page-molecule",
  templateUrl: "./detail-page-molecule.component.html",
  styleUrls: ["./detail-page-molecule.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailPageMoleculeComponent implements OnInit, OnDestroy {
  private chartInstance: Chart | null = null;
  @ViewChild("myChart", { static: false }) chartElement!: ElementRef;
  chart: Chart;
  myChart: Chart;
  @Input() getCodeFromParent: string;
  @Input() disableRoute: boolean = false;
  @Input() showExtendedInformation: boolean;
  itemData: any;
  config: any;
  dynamicHeaders: any;
  gridData: any;
  salesChannelTableData: [];
  salesChannelTableDataHeader: string[];
  displayName: any;
  dynamicNormForm: any;
  today: Date = new Date();
  chartData: {
    header: any;
    type: any;
    data: any;
    options: {
      pointRadius: number;
      scales: { y: { beginAtZero: boolean; grace: number } };
      tooltips: { enabled: boolean; mode: string; intersect: boolean };
      plugins: any;
    };
    plugins: {
      id: string;
      afterDatasetsDraw(chart: any, args: any, plugins: any): void;
    }[];
  };
  selectedPivot: any;
  salesChannelList: any;
  code: any;
  ChannelChange: boolean;
  page: any;
  // isFlipped: boolean = false;

  showAnalytics: boolean = false;
  activeCard: string = "card1";
  revenuePerImpressions: any;
  conversionRate: any;
  analyticsChartData: any;
  selectedProductCode: any;
  defaultChartLabels = ["DROS - 1", "DROS - 90", "DROS - 90-N"];
  accordianTabs: { label: string; content: any }[] = [];
  activeTabIndex: number = 0;
  tabInitialized: { [key: number]: boolean } = {};
  isLoading: boolean = false;

  constructor(
    private dynamicReviewServices: DynamicReviewService,
    private formControlService: FormControlService,
    private authenticationservice: AuthenticationService,
    private snack: MatSnackBar,
    private syncfusionService: SyncfusionService,
    private route: ActivatedRoute,
    private listinggridservice: ListingGridService,
    private router: Router,
    private settingService: AppSettingService,
    private cdr: ChangeDetectorRef,
    private clickStream: ClickStreamAnalyticsService
  ) {
    this.route.params.subscribe((params) => {
      if (params && params.code) {
        this.code = params.code;
        this.page = params.page;
        this.listinggridservice.setSelectedProduct(this.code);
        this.getProductDetails(params.code);
        this.listinggridservice
          .getdynamicNormByCode(params.code)
          .subscribe((res) => {
            if (res) {
              this.displayName = res["displayName"];
              this.dynamicHeaders = dynamicNormReview;
              this.gridData = res["items"];
            }
            this.dynamicNormForm = this.formControlsForTextFields();
          });
      }
    });
  }

  ngOnInit(): void {
    const forecastSetting = this.getForecastSetting();
    this.showAnalytics = forecastSetting ? true : false;
    this.selectedProductCode = this.code ? this.code : this.getCodeFromParent;
    if (this.getCodeFromParent) {
      this.getProductDetails(this.getCodeFromParent);
      this.getChartData(this.getCodeFromParent);
    }
  }
  ngAfterViewInit() {
    if (this.showAnalytics) {
      setTimeout(() => {
        this.createChart();
      }, 300);
    }
  }

  // onTabsLoaded(tabs: { label: string; content: any }[]) {
  //   this.accordianTabs = tabs; // Update the tabs dynamically
  // }
  triggerOpenEvent(tab) {
    this.clickStream.publishGAEvent(EventType.TAB_ACTION, {
      section: tab,
    });
  }
  onTabChange(event: any): void {
    const index = event.index;

    // Skip loader logic for the first tab (index 0)
    if (index !== 0 && !this.tabInitialized[index]) {
      this.tabInitialized[index] = true;
    }
  }

  getChartData(code: any) {
    this.listinggridservice.getProductAnalytics(code).subscribe((res: any) => {
      this.analyticsChartData = res;

      this.createChart();
      this.cdr.detectChanges();
    });
  }
  populateSalesChannelData() {
    if (this.itemData) {
      let channelList: string[] = this.itemData.salesChannelList;
      let dataList: any = [];
      let salesRos: any = {};
      if (this.itemData.mediumTermROSSet) {
        salesRos = JSON.parse(this.itemData.mediumTermROSSet);
      }
      salesRos.metric = "Rate of Sale - 30days";
      dataList.push(salesRos);
      let returnPercent: any = {};
      if (this.itemData.return_percent_channel_set) {
        returnPercent = JSON.parse(this.itemData.return_percent_channel_set);
      }
      returnPercent.metric = "Return Percentage";
      dataList.push(returnPercent);
      let broken: any = {};
      if (this.itemData.brokenSet) {
        let brokenData = JSON.parse(this.itemData.brokenSet);
        this.salesChannelList.forEach((element) => {
          if (brokenData[element] != null) {
            broken[element] = brokenData[element] ? "Broken" : "Available";
          } else {
            broken[element] = "";
          }
        });
      }
      broken.metric = "Available to sell";
      dataList.push(broken);
      let daysofStock: any = {};
      if (this.itemData.mediumTermDOSSet) {
        daysofStock = JSON.parse(this.itemData.mediumTermDOSSet);
      }
      daysofStock.metric = "Days of Stock";
      dataList.push(daysofStock);

      this.salesChannelTableData = dataList;

      this.salesChannelTableDataHeader = [
        "PerformanceIndicator",
        ...channelList,
      ];
    }
  }

  actionEvent(event) {
    const user = this.authenticationservice.getUserName();
    let payload: {};
    payload = {
      id: event.id,
      updatedBy: user,
      status: event.status.toUpperCase(),
    };
    if (event.status === "MODIFIED") {
      payload = {
        id: event.id,
        updatedBy: user,
        newNormValue: event.newNormValue,
        status: event.status.toUpperCase(),
      };
    }
    this.dynamicReviewServices.updateSuggestions(payload).subscribe(
      (res) => {
        if (res) {
          const message = `${payload["status"]} successfully`;
          this.snack.open(message, "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      },
      (error) => {
        const message = "Operation failed please try again later";
        this.snack.open(message, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      },
      () => {
        this.gridData = this.gridData.filter((item) => item.id != event.id);
      }
    );
  }
  private formControlsForTextFields() {
    const updatedData = this.gridData.map((item) => ({
      ...item,
      controlType: "String",
      value: item.customNormValue,
      identifier: item.id,
    }));
    const result = this.formControlService.toFormGroup(updatedData);
    return result;
  }

  convertToPercent(longTermROSSet: any[]) {
    const longTermValues = Object.values(longTermROSSet).map(function (
      x: any
    ): number {
      if (x == null || isNaN(x)) {
        return 0;
      } else {
        return x;
      }
    });
    const total = longTermValues.reduce(function (
      x: number | null,
      y: number | null
    ): number {
      return x + y;
    },
    0);
    return longTermValues.map(function (x: number | null) {
      if (x) {
        return Math.round((x * 100) / total);
      }
      return 0;
    });
  }
  /** Removed as rate of sale is shown as an accordian
   * onOptionSelection(event) {
    this.ChannelChange = false;
    this.syncfusionService
      .getSalesLineGraph(this.code, event.value, "product")
      .subscribe(
        (res) => {
          if (res) {
            this.chartData = {
              header: res["displayName"],
              type: res["type"],
              data: res["data"],
              options: {
                pointRadius: 0,
                scales: {
                  y: {
                    beginAtZero: true,
                    grace: 1,
                  },
                },
                tooltips: {
                  enabled: true,
                  mode: "index",
                  intersect: false,
                },
                plugins: {
                  legend: {
                    display: true,
                    position: "right"
                  }
                }
              },
              plugins: [],
            };
          }
        },
        (error) => { },
        () => {
          this.ChannelChange = true;
        }
      );
  }**/

  getProductDetails(code) {
    this.listinggridservice?.getDataByCode(code).subscribe((res) => {
      this.itemData = res;
      this.conversionRate = res["conversion_rate"];
      this.revenuePerImpressions = (
        (this.itemData?.conversion_rate * this.itemData?.min_sell_price) /
        100
      ).toFixed(2);
      const longTermROSSet = JSON.parse(res["longTermROSSet"]);
      const longTermROSValueSet = longTermROSSet; //this.convertToPercent(longTermROSSet);
      const mediumTermROSSet = JSON.parse(res["mediumTermROSSet"]);
      const mediumTermROSValueSet = mediumTermROSSet; //this.convertToPercent(mediumTermROSSet);
      const data = {
        labels: Object.keys(longTermROSSet),
        datasets: [
          {
            label: "30 days",
            data: mediumTermROSValueSet,
          },
          {
            label: "90 days",
            data: longTermROSValueSet,
          },
        ],
      };
      this.config = {
        type: "bar",
        data: data,
        options: {
          plugins: {
            legend: {
              display: false,
              position: "top",
            },
          },
        },
      };
      this.salesChannelList = res["salesChannelList"];
      this.populateSalesChannelData();
      /**
 
       this.selectedPivot = res["salesChannelList"][0];
       this.syncfusionService
         .getSalesLineGraph(
           params.code,
           res["salesChannelList"][0],
           "product"
         )
         .subscribe(
           (res) => {
             if (res) {
               this.chartData = {
                 header: res["displayName"],
                 type: res["type"],
                 data: res["data"],
                 options: {
                   pointRadius: 0,
                   scales: {
                     y: {
                       beginAtZero: true,
                       grace: 1,
                     },
                   },
                   tooltips: {
                     enabled: true,
                     mode: "index",
                     intersect: false,
                   },
                   plugins: {
                     legend: {
                       display: true,
                       position: "right"
                     }
                   }
                 },
                 plugins: [],
               };
             }
           },
           (error) => { },
           () => {
             this.ChannelChange = true;
           }
         );**/
    });
    this.getChartData(code);
    // this.isLoading = false
  }

  navigateBack() {
    window.history.back();
  }

  // flipCard() {
  //   if (this.showAnalytics) {
  //     this.isFlipped = !this.isFlipped;
  //   }
  // }
  bringToFront(card: string): void {
    this.activeCard =  card;
  }
  getForecastSetting(): string {
    const settings = this.settingService?.getSettings();
    return settings?.analytics || false;
  }

  createChart(): void {
    if (this.chartElement && this.chartElement.nativeElement) {
      const ctx = this.chartElement.nativeElement.getContext("2d");
      const chartConfig: any = {
        type: "line",
        data: {
          labels: this.analyticsChartData?.data?.labels || [],
          datasets: this.analyticsChartData?.data?.datasets || [],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
            // Uncomment this block if needed for x-axis label rotation prevention
            // x: {
            //   ticks: {
            //     maxRotation: 0, // Prevent label rotation
            //     minRotation: 0, // Prevent label rotation
            //   }
            // }
          },
        },
      };
      if (ctx) {
        if (this.myChart) {
          this.myChart.destroy();
        }
        this.myChart = new Chart(ctx, chartConfig);
      }

      // if (ctx) {
      //   // Destroy the existing chart instance if it exists
      //   if (this.chartInstance) {
      //     this.chartInstance.destroy();
      //     this.chartInstance = null;
      //   }

      //   // Create a new chart instance and store the reference
      //   this.chartInstance = new Chart(ctx, {
      //     type: 'line',
      //     data: {
      //       labels: this.analyticsChartData?.data?.labels || [],
      //       datasets: this.analyticsChartData?.data?.datasets || [],
      //     },
      //     options: {
      //       responsive: true,
      //       maintainAspectRatio: false,
      //       plugins: {
      //         legend: {
      //           display: false,
      //         },
      //       },
      //       scales: {
      //         y: {
      //           beginAtZero: true,
      //         },
      //         // Uncomment this block if needed for x-axis label rotation prevention
      //         // x: {
      //         //   ticks: {
      //         //     maxRotation: 0, // Prevent label rotation
      //         //     minRotation: 0, // Prevent label rotation
      //         //   }
      //         // }
      //       },
      //     },
      //   });
      // } else {
      //   console.error('Failed to get 2D context from chartElement.nativeElement');
      // }
    } else {
      console.error(
        "Failed to create chart: chartElement or nativeElement is undefined"
      );
    }
  }

  ngOnDestroy(): void {
    // Ensure the chart instance is destroyed when the component is destroyed
    if (this.chartInstance) {
      this.chartInstance.destroy();
      this.chartInstance = null;
    }
    if (this.myChart) {
      this.myChart.destroy();
    }
  }
  // createChart(): void {
  //   if (this.chartElement && this.chartElement.nativeElement) {
  //     const ctx = this.chartElement.nativeElement.getContext('2d');
  //     if (ctx) {
  //       new Chart(ctx, {
  //         type: 'line',
  //         data: {
  //           labels: this.analyticsChartData?.data?.labels,
  //           datasets: this.analyticsChartData?.data?.datasets,
  //         },
  //         options: {
  //           responsive: true,
  //           maintainAspectRatio: false,
  //           plugins: {
  //             legend: {
  //               display: false
  //             }
  //           },
  //           scales: {
  //             y: {
  //               beginAtZero: true
  //             },
  //             // x: {
  //             //   ticks: {
  //             //     maxRotation: 0, // Prevent label rotation
  //             //     minRotation: 0, // Prevent label rotation
  //             //   }
  //             // }
  //           }
  //         }
  //       });
  //     }
  //   } else {
  //     console.error('Failed to create chart: chartElement or nativeElement is undefined');
  //   }
  // }
}