import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { DataResult, DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Subscription } from "rxjs";
import { subHeadings } from "src/app/properties/map_sub_heading";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { AppSettingService } from 'src/app/services/app-setting.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReusableFilterMoleculeComponent } from "../../molecules/reusable-filter-molecule/reusable-filter-molecule.component";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";
import { FilterBayService } from "src/app/services/filter-bay.service";



@Component({
  selector: "app-sync-fusion-organisms",
  templateUrl: "./sync-fusion-organisms.component.html",
  styleUrls: ["./sync-fusion-organisms.component.scss"],
})
export class SyncFusionOrganismsComponent implements OnDestroy {
  reportName: any;
  reportNameWithSpace: any;
  tagline: any;
  data: any;
  dynamic_headers: [];
  today = new Date();
  count: any;
  tableDataFetched: boolean = false;
  subHeadings = subHeadings;
  private queryParamsSubscription: Subscription;
  visiblePOPUP: boolean;
  fieldData: any;
  headerData: any;
  generatedDate: any
  showFilter: boolean = false;
  filterData: any;
  showFiltersPopup: boolean = false;
  reportLabelText: string;
  appliedFilters: any = {};
  decisionAttribute:any;
  @ViewChild("childFilter", { static: false }) public childComponent !: ReusableFilterMoleculeComponent;
  constructor(
    private route: ActivatedRoute,
    private syncfusion: SyncfusionService,
    private cdr: ChangeDetectorRef,
    private settingsService: AppSettingService,
    private snack: MatSnackBar,
    private clickStream: ClickStreamAnalyticsService,
    private filterbayservice: FilterBayService,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.filterbayservice.setReportFilters(this.reportName, {}, {}); // Reset filters
      }
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        this.data = [];
        this.dynamic_headers = [];
        if (params && params.reportName) {
          this.reportName = params.reportName;
          this.reportNameWithSpace = "Just a moment, we're compiling your insights...";
          this.tableDataFetched = false;
          if (this.settingsService.isRemoteGridMode()) {
            this.syncfusion.getPaginatedSyncfusionData(this.reportName).subscribe((res) => {

              if (res) {
                this.getFilters();
                if (res.displayName && res.displayName.trim() !== '') {
                  this.reportNameWithSpace = res.displayName;
                }
                else {
                  this.reportNameWithSpace = params.reportName.replace(/_/g, " ");
                }
                if(res.displayTagLine){
                  this.tagline=res.displayTagLine;
                }else{
                this.tagline = this.subHeadings[this.reportNameWithSpace];
                }
                this.data = (<DataResult>{
                  result: res.items,
                  count: res.pageData ? res.pageData.totalRecords : res.count
                });
                
                this.appliedFilters = this.filterbayservice.getReportFilters(this.reportName)["displayFilters"];
                this.generatedDate = this.data?.result[0]?.recordDate;
                this.dynamic_headers = JSON.parse(res.headerContent);
                this.syncfusion.setExportData(res);
                this.reportLabelText = res.baseReportText;
                this.count = res.count;
                setTimeout(() => {
                  this.tableDataFetched = true;
                });
              }
            }, (error) => {
              this.tableDataFetched = true;
              console.error(error);
            })
          } else {
            this.syncfusion.getSyncfusionData(this.reportName).then((res) => {
              if (res) {
                if (res.displayName && res.displayName.trim() !== '') {
                  this.reportNameWithSpace = res.displayName;
                }
                else {
                  this.reportNameWithSpace = params.reportName.replace(/_/g, " ");
                }
                this.data = res.items;
                this.generatedDate = this.data[0]?.recordDate;
                this.dynamic_headers = JSON.parse(res.headerContent);
                this.count = res.count;
                setTimeout(() => {
                  this.tableDataFetched = true;
                });
              }
            }, (error) => {
              this.tableDataFetched = true;
              console.error(error);
            });
          }
        }
      }
    );
  }


  navigateGrid(state: DataStateChangeEventArgs) {
    if ((state as any).action?.requestType === 'filterchoicerequest' || (state as any).action?.requestType === 'stringfilterrequest' ||
      (state as any).action?.requestType === 'filtersearchbegin' || (state as any).action?.requestType === 'filterbeforeopen') {
      return;
    }
    this.syncfusion.getPaginatedSyncfusionData(this.reportName, this.syncfusion.convertGridStateToPageData(state)).subscribe((res) => {
      if (res) {
        this.data = (<DataResult>{
          result: res.items,
          count: res.pageData ? res.pageData.totalRecords : res.count
        });
        this.reportLabelText = res.baseReportText;
        this.syncfusion.setExportData(res);
        setTimeout(() => {
          this.tableDataFetched = true;
        });
      }
    }, (error) => {
      this.tableDataFetched = true;
      console.error(error);
    })
  }

  handlePOPUP(event) {
    this.fieldData = event.fieldData;
    this.headerData = event.header;
    this.visiblePOPUP = true;
  }
  ngOnDestroy(): void {
    this.filterbayservice.setReportFilters(this.reportName,{},{});
    this.appliedFilters = {};
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe(); // Unsubscribe to avoid memory leaks
    }
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  getFilters() {
    this.syncfusion.getAvailableFilters(this.reportName).subscribe((res: any) => {
      this.filterData = res?.filterDataList;
      this.filterData = this.filterData.sort((a, b) => a.position - b.position);
      this.showFilter = this.filterData.length > 0;
      this.clickStream.publishGAEvent(EventType.FILTER, {
        section: this.reportName
      });
    }, (error) => {
      this.showSnackbar('Error while fetching filters.Please try again later');
    })
  }
  toggleFilterOverlay() {
    this.showFiltersPopup = !this.showFiltersPopup;
    if (this.showFiltersPopup) {
      this.childComponent.createOverlay();
    }
  }
  selectedFilters(event) {
    this.appliedFilters = JSON.parse(JSON.stringify(event));
  }
  handleFilterOutput(data: any) {
    // this.
    const payload = { "gridFilters": data, "pageSize": 200, "pageNumber": 1 }
    this.reportNameWithSpace = "Just a moment, we're compiling your insights...";
    this.tableDataFetched = false;
    if (this.settingsService.isRemoteGridMode()) {
      this.syncfusion.getPaginatedSyncfusionData(this.reportName, payload).subscribe((res) => {
        if (res) {
          if (res.displayName && res.displayName.trim() !== '') {
            this.reportNameWithSpace = res.displayName;
          }
          else {
            this.reportNameWithSpace = this.reportName.replace(/_/g, " ");
          }
          this.data = (<DataResult>{
            result: res.items,
            count: res.pageData ? res.pageData.totalRecords : res.count
          });
          this.reportLabelText = res.baseReportText;
          this.generatedDate = this.data?.result[0]?.recordDate;
          this.dynamic_headers = JSON.parse(res.headerContent);
          this.syncfusion.setExportData(res);
          this.count = res.count;
          setTimeout(() => {
            this.tableDataFetched = true;
          });
        }
      }, (error) => {
        this.tableDataFetched = true;
        console.error(error);
      })
    }
  }
}
