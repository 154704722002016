<div>
    <button (click)="upload()" class="mat-button mat-primary mat-raised-button upload-btn">
        {{templateData.buttonText}}
    </button>
</div>

<ng-template #overlayTemplate>
    <div class="overlay-panel" [@childAnimation]>
        <div class="overlay-header">
            <div>Upload Files</div>
            <mat-icon class="close-icon" (click)="closeOverlay()">close</mat-icon>
        </div>
        <div class="overlay-body-scrollable-container">
            <mat-form-field appearance="outline">
                <mat-label class="label">Description</mat-label>
                <input matInput [value]="templateData.name" readonly class="text-color">
            </mat-form-field>
            <div class="template-container">
                <button (click)="downloadTemplate()" class="mat-button mat-primary mat-raised-button template-btn">
                    Download Template
                </button>
                <p class="info-text">
                    <mat-icon fontIcon="info" class="info-icon"></mat-icon>
                    Download a sample template to view the expected column structure
                </p>
            </div>
            <div *ngIf="!file" class="mt-3 mb-3 container d-flex align-items-center justify-content-center image-upload"
                (change)="getFile($event)">
                <div>
                    <div class="hv-centered  w-100">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-35 text-grey-shaded"
                                viewBox="0 0 24 24" fill="white" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                <polyline points="14 2 14 8 20 8"></polyline>
                                <path d="M12 16v-8"></path>
                                <polyline points="9 11 12 8 15 11"></polyline>
                            </svg>

                        </div>
                        <div class="font-small"><label class="text-muted drag-text">Drag and drop or</label> <br> <span
                                class="text-blue cursor-pointer">Click to upload</span></div>
                    </div>
                    <span id="upload"><input id="ember1684" class="upload ember-view form-control" type="file"></span>
                </div>
            </div>
            <div *ngIf="file" class="option-container mt-1">
                <div class="option-box">
                    <span><strong>File Name:</strong> {{file.name}}</span>
                    <br>
                    <br>
                    <span><strong>Size: </strong>{{formatFileSize(file.size)}}</span>
                </div>

            </div>
        </div>
        <div class="overlay-bottom-buttons-container">
            <button (click)="submit()" class="mat-button mat-primary mat-raised-button">
                <span><i class="bi bi-upload"></i> Upload</span>
            </button>
            <button (click)="cancel()" class="mat-button mat-primary mat-raised-button cancel-button">
                Cancel
            </button>
        </div>
    </div>
</ng-template>