<div class="level-container">
    <h2>In-Store Replenishment </h2>  <!--   - {{ currentLevel }}   -->
    <!-- <button mat-raised-button class="mt-2 create-level-btn" (click)="createStructure()">
        Create Level
      </button> -->
      <button mat-raised-button class="mt-2 create-level-btn" (click)="showCreateLevelInput = !showCreateLevelInput">
        <mat-icon class="plus-icon">add_circle</mat-icon> Create a merchandising template
      </button>
      <!-- <p style="font-size: 12px;">*Create a merchandising template</p> -->
      
      <!-- Create Level Input Fields -->
      <div *ngIf="showCreateLevelInput" class="create-level-container">
        <input type="text" [(ngModel)]="newLevelName" placeholder="Enter Level Name" class="level-input">
        <!-- <input type="text" [(ngModel)]="newLevelAttribute" placeholder="Enter Attribute (e.g., CategoryL1, CategoryL2)" class="level-input"> -->
        <mat-form-field appearance="outline">
            <mat-label>Select Attribute</mat-label>
            <mat-select [formControl]="selectedAttribute">
              <mat-option *ngFor="let option of dropdownOptions" [value]="option.identifier">
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="buttons-create">
        <button class="create-level-button" (click)="createStructure()">Create</button>
        <button class="create-level-button" (click)="showCreateLevelInput = !showCreateLevelInput">Cancel</button>
         </div>
      </div>
    <div class="structures-container">
      <ng-container *ngFor="let level of structures">
        <div class="level-box" (click)="navigateToStructure(level)" >    <!--*ngIf="level.attribute === 'CategoryL1' && level.name === 'root'"-->
          <p><strong></strong> {{ level.code }}</p>
          <!-- <button class="child-delete" mat-icon-button (click)="deleteConfig(level.id); $event.stopPropagation()">
            <mat-icon >delete</mat-icon>
          </button> -->
          <!-- <p><strong>Attribute:</strong> {{ level.attribute }}</p> -->
  
          <!-- <button mat-raised-button (click)="navigateToStructure(level)" class="next-level-btn">
            Next Level
          </button> -->
          <!-- Three-dot Menu Button -->
  <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button" (click)="$event.stopPropagation()">
    <mat-icon>more_vert</mat-icon>
  </button>
  <!-- Menu with Delete Option -->
  <mat-menu #menu="matMenu">
    <button mat-menu-item class="menu-delete" (click)="deleteConfig(level.id); $event.stopPropagation()">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
        </div>
      </ng-container>
    </div>
  
    
  </div>