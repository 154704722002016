import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root',
})
export class BusinessPartnerService {

  constructor(private http: HttpClient) { }

  getPartnerDetails(partnerId: any) {
    const url = `${api.host}/material/entity/business-partner/partnerId/${partnerId}`;
    return this.http.get(url);
  }
  getBusinessPartnersList(payload: any) {
    const url = `${api.host}/material/entity/business-partner`;
    return this.http.post(url, payload);
  }
  getMetaData() {
    const url = `${api.host}/material/entity/business-partner/metadata`;
    return this.http.get(url);
  }

  getChartDetails(payload:any) {
    let url = `${api.host}/reports/sales/linegraph/partner/${payload.partnerId}/${payload.startDate}`;
    if (payload.endDate) {
      url += `/${payload.endDate}`;
    }
    return this.http.get(url);
  }
}
