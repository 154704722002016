<!-- <mat-toolbar class="header display-flex justify-content-center">
  <span>{{displayName}}</span>
</mat-toolbar> -->

<!-- <div class="fs-section-block fs-margin-0 header-background"> -->
<div class="sync-fusion-main-container">
  <app-header-content-help-text [Heading]="displayName"
    [helpText]="'Optimized Inventory Design across the System'"></app-header-content-help-text>
  <!-- </div> -->
  <div class="text-right fs-margin-0-1"><span>Reports are generated as on <strong>{{generatedDate |
        date}}</strong></span></div>
  <div class="grid-container">
    <app-syncfusion-grid *ngIf="dynamicHeaders" [dynamic_headers]="dynamicHeaders" [data]="gridData"
      (dataEmitForDynamicNormReview)="actionEvent($event)" [dynamicNormForm]="dynamicNormForm"></app-syncfusion-grid>
  </div>
</div>