
<button *ngIf="!getCodeFromParent" class="mat-primary fs-margin-bottom-1 fs-margin-top-1" style="margin-left: 15px;"
  mat-raised-button (click)="navigateBack()">
  <mat-icon>arrow_back</mat-icon>Go back
</button>
<mat-tab-group (selectedTabChange)="onTabChange($event)" fitInkBarToContent class="custom-tab-group1" >
  <!-- Tab for the first div -->
  <mat-tab label="Product 360°">
    <div class="fs-padding-0-1 fs-margin-top-1 fs-margin-bottom-1">
      <div *ngIf="!itemData">
        <div class="product-overview fs-margin-bottom-1">
          <div class="product-detail-card fs-padding-0-1"
            style="border-radius:10px;background-color: rgb(211, 211, 211, 0.2)">
            <div style="font-weight: lighter;width: 60%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 40%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
          </div>
          <div class="mat-elevation-z4 fs-padding-1 chart-section">
            <div style="font-weight: lighter;width: 60%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 40%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
            <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
          </div>
        </div>
      </div>
      <div *ngIf="itemData">
        <div class="product-overview fs-margin-bottom-1">
          <div class="product-detail-card">
            <app-product-card-molecule [disableRoute]="disableRoute" [item]="itemData" [detailedView]="true"
              showAdditionalInfo="true"></app-product-card-molecule>
          </div>

          <div class="card-stack flip-card">
            
            <!-- First Card -->
            <div 
            class="stacked-card" 
            [class.front]="activeCard === 'card1'" 
            (click)="bringToFront('card1')">
            <div class="flip-card-inner">
              <div class="chart-section">
                <p class="chart-header">
                  Rate of sale <i style="font-size: 0.8em;" class="fs-hint-text">(30 v/s 90 days)</i>
                </p>
                <app-dashboard-chatjs-molecule [chartData]="config" [htmlLegend]="false" maxHeight="300px"></app-dashboard-chatjs-molecule>
              </div>
            </div>
          </div>
           <!-- Second Card -->
            <div  class="stacked-card" 
              [class.front]="activeCard === 'card2'" 
              (click)="bringToFront('card2')">
              <div class="flip-card-inner">
                <!-- Front side -->
                <div class="chart-section" >
                  <p class="chart-header">Conversion Trend</p>
                  <div style="padding: 0rem 1rem 0 1rem;">
                    <canvas #myChart style="height: 100%; width: 90%;"></canvas>
                  </div>
                  <div class="product-performance fs-flex">
                    <!-- Metrics -->
                    <div class="metric-score">
                      <div class="kpi"><strong>{{ analyticsChartData?.viewed || '-' }}</strong></div>
                      <label>Impressions</label>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="metric-score">
                      <div class="kpi"><strong>{{ analyticsChartData?.addedToCart || '-' }}</strong></div>
                      <label>Added to Cart</label>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="metric-score">
                      <div class="kpi"><strong>{{ conversionRate || '-' }}</strong></div>
                      <label>Conversion Rate</label>
                    </div>
                    <mat-divider [vertical]="true"></mat-divider>
                    <div class="metric-score">
                      <div class="kpi"><strong>{{ revenuePerImpressions || '-' }}</strong></div>
                      <label>Revenue per Impression</label>
                    </div>
                  </div>
                </div>
                <!-- Back side -->
              </div>
            </div>
          
           
            
          </div>
          
          

        </div>
        <!--
    <div class="mat-elevation-z4 fs-margin-bottom-1">
      <mat-toolbar *ngIf="salesChannelList.length>1 class="justify-content-between">
        <span class="mat-headline-2 fs-margin-0">Sales Momentum</span>
        <mat-form-field class="fs-padding-0-1 mat-body-1">
          <mat-select [(value)]="selectedPivot" [multiple]="false" panelWidth="max-content"
            (selectionChange)="onOptionSelection($event)">
            <mat-option *ngFor="let item of salesChannelList" [value]="item">{{
              item
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-toolbar>
      <app-dashboard-chatjs-molecule *ngIf="ChannelChange" [chartData]="chartData"
        chartType="{{ chartData.type }}"></app-dashboard-chatjs-molecule>
    </div>

    <app-grid-detail-sales-forecast-molecule [code]="code"></app-grid-detail-sales-forecast-molecule>-->

        <div class="fs-margin-2-0" *ngIf="!getCodeFromParent" style="margin-top: 5em;">
          <h3 class="fs-font-weight-700" style="text-align:center">Sales Channel Performance Metrics</h3>
          <table mat-table [dataSource]="salesChannelTableData" class="mat-elevation-z4">
            <ng-container matColumnDef="PerformanceIndicator">
              <th mat-header-cell *matHeaderCellDef> Performance Metrics </th>
              <td mat-cell *matCellDef="let element"> {{element.metric}} </td>
            </ng-container>
            <!-- Mertics Column -->
            <ng-container matColumnDef="{{column}}" *ngFor="let column of salesChannelList">
              <th mat-header-cell *matHeaderCellDef> {{column}} </th>
              <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="salesChannelTableDataHeader"></tr>
            <tr mat-row *matRowDef="let row; columns: salesChannelTableDataHeader;"></tr>
          </table>
        </div>
      </div>
    </div>
  </mat-tab>
   <!-- Dynamic Tabs -->
   <mat-tab label="Rate of Sale">
    <ng-container *ngIf="tabInitialized[1]">
      <app-pu-ros-molecule
        *ngIf="itemData"
        [data]="itemData"
        [code]="selectedProductCode"
        [defaultChartLabels]="defaultChartLabels"
        (opened)="triggerOpenEvent('Rate of Sale')">
      </app-pu-ros-molecule>
    </ng-container>
  </mat-tab>

  <!--<mat-tab label="Stock and Eco Trend">
    <ng-container *ngIf="tabInitialized[2]">
      <app-pu-stock-trend-molecule
        [code]="selectedProductCode"
        (opened)="triggerOpenEvent('Stock Trend')">
      </app-pu-stock-trend-molecule>
    </ng-container>
  </mat-tab>-->
  <mat-tab label="Variant Sales (%)">
    <ng-container *ngIf="tabInitialized[2]">
      <app-variant-ratio-trend-molecule
        [styleCode]="selectedProductCode"
        (opened)="triggerOpenEvent('Variant Mix')">
      </app-variant-ratio-trend-molecule>
    </ng-container>
  </mat-tab>
  

  <mat-tab label="Product Availability">
    <ng-container *ngIf="tabInitialized[3]">
      <app-pu-bpr-molecule
        [code]="selectedProductCode"
        (opened)="triggerOpenEvent('Product Availability')">
      </app-pu-bpr-molecule>
    </ng-container>
  </mat-tab>

  <mat-tab label="Review Dynamic Norm">
    <ng-container *ngIf="tabInitialized[4]">
      <app-syncfusion-grid
        *ngIf="dynamicHeaders"
        [dynamic_headers]="dynamicHeaders"
        [data]="gridData"
        [dynamicNormForm]="dynamicNormForm"
        (dataEmitForDynamicNormReview)="actionEvent($event)"
        (opened)="triggerOpenEvent('Dynamic Norm Review')">
      </app-syncfusion-grid>
    </ng-container>
  </mat-tab>
  <!-- <ng-container *ngFor="let tab of accordianTabs">
    <mat-tab [label]="tab.label">
      <ng-container *ngTemplateOutlet="tab.content"></ng-container>
    </mat-tab>
  </ng-container> -->
</mat-tab-group>


<!-- Include child component -->
<!-- <app-detail-page-accordian-molecule *ngIf="itemData && (!getCodeFromParent || showExtendedInformation)"
  [data]="itemData" [code]="selectedProductCode" [defaultChartLabels]="defaultChartLabels"
  (tabsLoaded)="onTabsLoaded($event)">
</app-detail-page-accordian-molecule> -->