<!-- Back Button -->
<button mat-icon-button (click)="goBack()" class="back-button" *ngIf="breadcrumbs.length <= 1">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <!-- Structure Details -->
  <div class="structure-container">
    <div class="breadcrumb">
      <nav class="breadcrumb-container">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
          <span 
            *ngIf="!last; else lastBreadcrumb"
            (click)="navigateToBreadcrumb(breadcrumb.path)"
            class="breadcrumb-link"
          >
            {{ breadcrumb.name }}
          </span>
          <ng-template #lastBreadcrumb>
            <span class="breadcrumb-current">{{ breadcrumb.name }}</span>
          </ng-template>
          <span *ngIf="!last"> | </span>
        </ng-container>
      </nav>
      </div>
      
    <!-- <h2 class="structure-title">CategoryL{{ breadcrumbs.length + 1 }}</h2> -->
    <!-- <h3 class="structure-code">{{ structure?.code }}</h3> -->
     <div class="add-child-element">
    <mat-form-field appearance="outline">
      <mat-label>Select Attribute</mat-label>
      <mat-select class="hover-detail" [formControl]="selectedAttribute" [disabled]="isAttributeSelected" matTooltip='"Attribute modification is not allowed. To make changes, the entire template must be deleted and rebuilt. To proceed with deletion, please navigate to the In-Store Home."'
      [matTooltipDisabled]="!isAttributeSelected">
        <mat-option *ngFor="let option of dropdownOptions" [value]="option.identifier">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    
    <!-- Add Child -->
    <h4 class="add-child-title" (click)="showInput = !showInput">
      <mat-icon class="plus-icon">add_circle</mat-icon> Set Up Merchandising Model
    </h4>
    <span *ngIf="showInput" (click)="cancelAdd()" style="margin: 0 0 16px 40px;cursor:pointer; color: #F59B00;" >Cancel</span>
  </div>
    <div *ngIf="showInput" class="add-child-container">
      <!-- <input type="text" [(ngModel)]="newChildName" placeholder="Enter template name" class="child-input"> -->
      <mat-form-field appearance="outline" *ngIf="categoryValues.length > 0">
        <mat-label>Select Category Value</mat-label>
        <mat-select [formControl]="newChildName">
          <mat-option *ngFor="let value of categoryValues" [value]="value">
            {{ value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <input type="number" [(ngModel)]="newChildShareValue" placeholder="Enter share value" class="child-input"> 
    <button class="add-child-button" (click)="addChild()">Add</button>
    <button class="add-child-button" (click)="showInput = !showInput">Cancel</button>
    </div>
    <!-- Show Children -->
    <!-- <h4 class="children-title">CategoryL{{ breadcrumbs.length + 1 }}</h4> -->
    <!-- <div class="children-grid">
      <div *ngFor="let child of structure?.children" class="child-card" (click)="navigateToChild(child.name)">
        <mat-icon class="child-icon">folder</mat-icon>
        <p class="child-name">{{ child.name }}</p>
      </div>
    </div> -->
    <div class="children-grid">
      <div *ngFor="let child of structure?.children" class="child-card" (click)="navigateToChild(child.name)">
        <div class="child-content">
          <!-- <mat-icon class="child-icon">folder</mat-icon> -->
           <div class="name-card">
          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10.58 21H6C4.93913 21 3.92172 20.5785 3.17157 19.8284C2.42142 19.0782 2 18.0609 2 17V7C2 5.93913 2.42142 4.92165 3.17157 4.17151C3.92172 3.42136 4.93913 3 6 3" stroke="#F59B00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M18 22V14" stroke="#F59B00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 18H22" stroke="#F59B00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
      
          <!-- Child Name & Share Value (Displayed when not editing) -->
          <p *ngIf="!child.isEditing" class="child-name" [attr.data-value]="child.shareValue">
            {{ child.name }}{{ child.shareValue !== null && child.shareValue !== undefined ? ' (' + child.shareValue + '%)' : '' }}
          </p>
        </div>
      
          <!-- Edit Button -->
          <button class="child-edit" mat-icon-button (click)="editChild(child, $event); $event.stopPropagation()">
            <mat-icon *ngIf="!child.isEditing">edit</mat-icon>
          </button>
          
          <!-- Fullscreen Edit Overlay inside the inner div -->
          
        </div>
        <div *ngIf="child.isEditing" class="edit-overlay">
          <div class="edit-container">
            <mat-form-field style="width: 90%;" appearance="outline" (click)="$event.stopPropagation()">
              <!-- <mat-label>Template Name</mat-label> -->
              <!-- <input matInput [(ngModel)]="editedName" (click)="$event.stopPropagation()" /> -->
              <!-- <mat-form-field appearance="outline" *ngIf="categoryValues.length > 0" (click)="$event.stopPropagation()"> -->
                <mat-label>Select Category Value</mat-label>
                <mat-select [formControl]="newChildName">
                  <mat-option *ngFor="let value of categoryValues" [value]="value">
                    {{ value }}
                  </mat-option>
                </mat-select>
              <!-- </mat-form-field> -->
            </mat-form-field>
    
            <mat-form-field style="width: 90%;" appearance="outline">
              <mat-label>Share Value (%)</mat-label>
              <input matInput type="number" [(ngModel)]="shareValue" (click)="$event.stopPropagation()" />
            </mat-form-field>
    
            <div class="edit-actions">
              <button mat-button (click)="saveChildName(child, $event)">Save</button>
              <button mat-button (click)="cancelEdit(child, $event);$event.stopPropagation()">Cancel</button>
            </div>
          </div>
        </div>
      </div> 
  </div>