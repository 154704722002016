export class FormBase<T> {
    id?:string;
    value?: T;
    identifier: string;
    label: string;
    dataType: string;
    optional: boolean;
    controlType: string;
    isReadOnly: boolean;
    dynamic: boolean;
    options: any;
    lookupEntity : any;

    constructor(options: {
        id?:string;
        value?: T;
        identifier?: string,
        key?: string,
        label?: string,
        optional?: boolean,
        order?: number,
        dataType?: string,
        controlType?: string,
        isReadOnly?: boolean,
        lookupEntity?:string,
        dynamic?: boolean
        options?: Array<2>
      } = {}) {
      this.id = options.id || null;
      this.value = options.value;
      this.identifier = options.identifier;
      this.label = options.label || '';
      this.dataType = options.dataType || '';
      this.optional = !!options.optional;
      this.controlType = options.controlType || '';;
      this.isReadOnly = options.isReadOnly || false;
      this.dynamic = options.dynamic;
      this.options =  options.options || [];
      this.lookupEntity = options.lookupEntity || null
    }
  }
