<mat-dialog-content class="dialog-content">
    <div class="overlay-header">
        <div>Work Order Details</div>
        <mat-icon class="close-icon" (click)="closeDialog()">close</mat-icon>
    </div>
    <mat-tab-group (selectedTabChange)="onTabChange($event)" fitInkBarToContent class="custom-tab-group1">
        <mat-tab label="Assigned WO">
            <ng-container *ngIf="tabInitialized[0] && assignedWO !== null && assignedWO !== undefined; else loading">
                <ng-container *ngIf="assignedGroupKeys.length > 0; else noAssignedWO">
                    <mat-accordion class="order-list">
                        <mat-expansion-panel *ngFor="let poCode of assignedGroupKeys">
                            <mat-expansion-panel-header class="pt-2 pb-2">
                                <mat-panel-title>
                                    <div class="d-flex justify-content-between align-items-center w-100">
                                        <span>{{ poCode }}</span>
                                        <strong>{{ earliestOrders[poCode].estimatedDeliveryDate }}</strong>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div class="orders-container">
                                <div class="assigned-table-cont">
                                    <table mat-table [dataSource]="assignedGroups[poCode]">
                                        <ng-container matColumnDef="materialCode">
                                            <th mat-header-cell *matHeaderCellDef> Material Code </th>
                                            <td mat-cell *matCellDef="let order"> {{ order.materialCode }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="locationCode">
                                            <th mat-header-cell *matHeaderCellDef> Location Code </th>
                                            <td mat-cell *matCellDef="let order"> {{ order.locationCode }} </td>
                                        </ng-container>

                                        <ng-container matColumnDef="fulfilledQty">
                                            <th mat-header-cell *matHeaderCellDef> Fulfilled Qty </th>
                                            <td mat-cell *matCellDef="let order"> {{ order.fulfilledQty }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="qty">
                                            <th mat-header-cell *matHeaderCellDef> Quantity </th>
                                            <td mat-cell *matCellDef="let order"> {{ order.qty }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="code">
                                            <th mat-header-cell *matHeaderCellDef> Code </th>
                                            <td mat-cell *matCellDef="let order"> {{ order.code }} </td>
                                        </ng-container>
                                        <ng-container matColumnDef="estimatedDeliveryQty">
                                            <th mat-header-cell *matHeaderCellDef> Delivery Qty </th>
                                            <td mat-cell *matCellDef="let order">
                                                <input matInput type="number"
                                                    style="width: 70px;border: 1px solid #000;"
                                                    [formControl]="getAssignedForm(order.code).controls['estimatedDeliveryQty']"
                                                    required>
                                            </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedUnassignedColumnsWithExpectedQty">
                                        </tr>
                                        <tr mat-row
                                            *matRowDef="let row; columns: displayedUnassignedColumnsWithExpectedQty;">
                                        </tr>
                                    </table>
                                </div>

                                <div class="work-order-details">
                                    <mat-form-field style="width: 150px;">
                                        <input matInput placeholder="PO Code" [value]="earliestOrders[poCode]?.poCode"
                                            readonly class="text-color">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Original Delivery Date</mat-label>
                                        <input class="text-color" matInput [value]="earliestOrders[poCode]?.originalDeliveryDate ?? '-'"
                                            readonly>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>PI Code</mat-label>
                                        <input class="text-color" matInput [value]="earliestOrders[poCode]?.parentPICode ?? '-'" readonly>
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Status</mat-label>
                                        <input matInput
                                            [formControl]="getAssignedForm(earliestOrders[poCode].code).controls['status']">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Estimated Delivery Date</mat-label>
                                        <input matInput [matDatepicker]="picker"
                                            [formControl]="getAssignedForm(earliestOrders[poCode].code).controls['estimatedDeliveryDate']"
                                            placeholder="Choose a date" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-error
                                            *ngIf="getAssignedForm(earliestOrders[poCode].code).controls['estimatedDeliveryDate'].hasError('required')">
                                            Estimated Delivery Date is required
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill" style="flex-grow: 1;">
                                        <mat-label>Remarks</mat-label>
                                        <textarea matInput
                                            [formControl]="getAssignedForm(earliestOrders[poCode].code).controls['remark']"
                                            placeholder="Add comments" rows="2"></textarea>
                                    </mat-form-field>
                                    <button class="save-button mat-button mat-primary mat-raised-button mb-3"
                                        [disabled]="!isAnyFieldDirty(poCode)"
                                        (click)="saveWorkOrders(poCode)">Save</button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
                <ng-template #noAssignedWO>
                    <div class="no-data-message p-1">
                        <p>No assigned work orders available</p>
                    </div>
                </ng-template>
            </ng-container>
        </mat-tab>
        <mat-tab label="Unassigned WO" class="tab1">
            <ng-container
                *ngIf="tabInitialized[1] && unassignedWO !== null && unassignedWO !== undefined; else loading">
                <ng-container *ngIf="unassignedWO.length > 0; else noUnassignedWO">
                    <div class="table-container mat-elevation-z8">
                        <table mat-table [dataSource]="unassignedWO" class="mat-elevation-z8" multi="true">
                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row">
                                    <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? toggleRow(row) : null" [checked]="selection.isSelected(row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of displayedUnassignedColumns">
                                <ng-container [matColumnDef]="column.key">
                                    <th mat-header-cell *matHeaderCellDef> {{column.label}} </th>
                                    <td mat-cell *matCellDef="let row"> {{ row[column.key]}} </td>
                                </ng-container>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedUnassignedColumnKeys"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedUnassignedColumnKeys;"
                                (click)="toggleRow(row)">
                            </tr>
                        </table>
                    </div>
                    <div class="bottom-overlay" *ngIf="selection.hasValue()" [formGroup]="poForm">
                        <mat-form-field style="width: 150px;" floatLabel="never">
                            <input matInput placeholder="PO Code" formControlName="poCode" required>
                            <mat-error *ngIf="poForm.get('poCode')?.invalid && poForm.get('poCode')?.touched">
                                PO Code is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field floatLabel="never">
                            <input matInput [matDatepicker]="picker" formControlName="estimatedDeliveryDate"
                                placeholder="Estimated Delivery Date" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error
                                *ngIf="poForm.get('estimatedDeliveryDate')?.invalid && poForm.get('estimatedDeliveryDate')?.touched">
                                Estimated Delivery Date is required
                            </mat-error>
                        </mat-form-field>

                        <button mat-raised-button color="primary" (click)="assignPo()"
                            [disabled]="poForm.invalid">Assign
                            PO</button>
                    </div>
                </ng-container>
                <ng-template #noUnassignedWO>
                    <div class="no-data-message p-1">
                        <p>No unassigned work orders available</p>
                    </div>
                </ng-template>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
    <ng-template #loading>
        <div class="no-data-message p-1">
            <p>Loading work orders...</p>
        </div>
    </ng-template>
</mat-dialog-content>