import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataResult } from '@syncfusion/ej2-angular-grids';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { subHeadings } from 'src/app/properties/map_sub_heading';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ReOrderingService } from 'src/app/services/re-ordering.service';
import { SyncfusionService } from 'src/app/services/syncfusion.service';

@Component({
  selector: 'app-re-ordering-organism',
  templateUrl: './re-ordering-organism.component.html',
  styleUrls: ['./re-ordering-organism.component.scss']
})
export class ReOrderingOrganismComponent implements OnInit, OnDestroy {
  reportNameWithSpace: any;
  data: any;
  dynamic_headers: any;
  summaryData: any;
  summaryDynamicHeaders: [];
  tableDataFetched: boolean = false;
  summaryTableFetched: boolean = false;
  selectedTabIndex: number = 0;
  status: any;
  isGenerateEnabled: boolean = false;
  isUploadEnabled: boolean = false;
  isReComputeEnabled: boolean = false;
  pollingSubscription: Subscription | null = null;
  selectedFile: File | null = null;
  userName: string = '';
  loadingState = {
    generate: false,
    upload: false,
    recompute: false
  };
  reOderingGeneratedDate: any;
  summarygGeneratedDate: any;
  today: Date = new Date();

  constructor(private syncfusion: SyncfusionService, private settingsService: AppSettingService, private reOrderingService: ReOrderingService,
    private snack: MatSnackBar, private authenticationService: AuthenticationService,) { }

  ngOnInit(): void {
    this.reportNameWithSpace = "Just a moment, we're compiling your insights...";
    this.userName = this.authenticationService.getUserName();
    this.checkStatus(500);
  }
  checkStatus(durationInMilliSeconds = 15000) {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
    this.tableDataFetched = false;
    this.summaryTableFetched = false;
    this.data = [];
    this.summaryData = [];
    this.pollingSubscription = interval(durationInMilliSeconds)
      .pipe(
        switchMap(() => this.reOrderingService.getStatus()),
        takeWhile((response: any) => response && response.status !== 'COMPLETED', true) // Continue until status is COMPLETED
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.status = response.status;
            if (this.status === 'COMPLETED') {
              this.reportNameWithSpace = "Neemans Re Ordering"
              this.fetchReOrderingData();
              this.fetchReOrderingSummary();
              this.isGenerateEnabled = true;
              this.stopPolling();
            } else if (this.status === 'RUNNING') {
              this.reportNameWithSpace = "We're almost there! Just fetching your data...";
            }
          }
        },
        (error) => {
          this.showSnackbar('Error while fetching status. Please try again later');
        }
      );
  }
  stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
      this.setLoadingState('upload', false);
      this.setLoadingState('generate', false);
      this.setLoadingState('recompute', false);
    }
  }
  generateReOrdering() {
    this.setLoadingState('generate', true);
    this.isUploadEnabled = false;
    this.isReComputeEnabled = false;
    this.reOrderingService.generateReOrdering({}).subscribe((response) => {
      if (response) {
        this.reportNameWithSpace = "Your report is being generated! Just a moment while we fetch the details.."
        this.checkStatus();
      }
    }, (error) => {
      this.showSnackbar('Error while generating the report.Please try again later');
    })
  }
  ngOnDestroy(): void {
    this.stopPolling();
    this.isGenerateEnabled = false;
    this.isUploadEnabled = false;
    this.isReComputeEnabled = false;
  }
  fetchReOrderingData() {
    this.reOrderingService.getReOrderingData().subscribe((response: any) => {
      if (response) {
        this.data = response["items"];
        this.dynamic_headers = JSON.parse(response['headerContent']) || [];
        this.tableDataFetched = true;
        this.isReComputeEnabled = this.data.length > 0 ? true : false;
        this.isUploadEnabled = true;
        this.reOderingGeneratedDate = this.data[0].recordDate || this.today;
        if (this.dynamic_headers.length > 0) {
          const hasForecastKey = Object.keys(this.data[0]).some(key => key.startsWith('c_'));
          if (!hasForecastKey) {
            this.dynamic_headers = this.dynamic_headers.filter((header: any) => !header.field.startsWith('c_forecast_month')
            );
          } else {
            this.dynamic_headers = this.dynamic_headers.filter((header: any) =>!header.field.startsWith('forecast_month')
            );
          }
        }

      }
    }, (error) => {
      this.tableDataFetched = false;
      this.showSnackbar('Error while fetching the report.Please try again later');
    })
  }
  fetchReOrderingSummary() {
    this.reOrderingService.getReOrderingSummary().subscribe((response: any) => {
      if (response) {
        this.summaryData = response["items"];
        this.summaryDynamicHeaders = JSON.parse(response['headerContent']) || [];
        this.summaryTableFetched = true;
        this.isReComputeEnabled = this.summaryData.length > 0 ? true : false;
        this.isUploadEnabled = true;
        this.summarygGeneratedDate = this.summaryData[0].recordDate || this.today;
      }
    }, (error) => {
      this.summaryTableFetched = false;
      this.showSnackbar('Error while fetching the report.Please try again later');
    })
  }

  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.selectedFile = input.files[0];
      this.upload();
      input.value = '';
    } else {
      this.showSnackbar("Please select a file to upload")
    }
  }
  triggerFileInput(fileInput: HTMLInputElement) {
    fileInput?.click();
  }

  upload() {
    this.setLoadingState('upload', true);
    this.isGenerateEnabled = false;
    this.isReComputeEnabled = false;
    if (!this.selectedFile) {
      console.error('No file selected');
      this.showSnackbar("Please select a file to upload");
      return;
    }
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('uploadedBy', this.userName);
    this.reOrderingService.upload(formData).subscribe((res: any) => {
      if (res) {
        this.showSnackbar("File uploaded successfully");
        this.reportNameWithSpace = "Just a moment, we're fetching your report...";
        this.checkStatus();
      }
    },
      (error: any) => {
        console.error('File upload failed:', error);
        this.showSnackbar("File upload failed, please try again later");
      }
    )
  }
  reCompute() {
    this.setLoadingState('recompute', true);
    this.isGenerateEnabled = false;
    this.isUploadEnabled = false;
    this.reOrderingService.generateReComputing({}).subscribe((response) => {
      if (response) {
        this.showSnackbar(response['message']);
        this.checkStatus();
      }
    }, (error) => {
      this.showSnackbar('Error while generating the report.Please try again later');
    })
  }
  onTabChange(index: any) {
    this.selectedTabIndex = index;
  }
  private setLoadingState(action: string, state: boolean) {
    this.loadingState[action] = state;
  }
}
