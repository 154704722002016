import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FilterSettingsModel } from '@syncfusion/ej2-angular-grids';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { listUploadedUser } from 'src/app/properties/tableConfig';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataProcessingService } from 'src/app/services/data-processing.service';
import { ReconciliationServiceService } from 'src/app/services/reconciliation-service.service';

@Component({
  selector: 'app-reconciliation-organism',
  templateUrl: './reconciliation-organism.component.html',
  styleUrls: ['./reconciliation-organism.component.scss'],
  providers: [DatePipe]
})
export class ReconciliationOrganismComponent {
  file: any
  selectedOption: string | null = null;
  dataSource: any = [];
  settings: any;
  pollingSubscription: Subscription | null = null;
  fileTypes = [];
  status: any;
  reportNameWithSpace: any;
  isProcessing: boolean = false;
  processingRowId: any;
  isUploading: boolean = false;
  dynamicHeaders: any = [];
  public filterOption: FilterSettingsModel = { type: "Excel" };
  isDeleting: boolean = false;

  constructor(public datepipe: DatePipe, private uploadfiles: DataProcessingService, private authenticationService: AuthenticationService,
    private reconciliationService: ReconciliationServiceService, private snack: MatSnackBar, private settingsService: AppSettingService) { }

  ngOnInit() {
    this.selectedOption = '';
    this.reportNameWithSpace = "Just a moment, we're compiling your insights...";
    this.dynamicHeaders = [
      {
        "field": "filename",
        "headerText": "File Name",
        "allowFiltering": true,
        "width": "300",
        "filter": {
          "type": "Menu"
        }
      },
      {
        "field": "subType",
        "headerText": "Type",
        "allowFiltering": false,
        "width": "120"
      },
      {
        "field": "uploadedBy",
        "headerText": "Uploaded By",
        "allowFiltering": false,
        "width": "100"
      },
      {
        "field": "status",
        "headerText": "Status",
        "allowFiltering": false,
        "width": "100"
      },
      {
        "field": "processMsg",
        "headerText": "Process Msg",
        "allowFiltering": false,
        "width": "120"
      },
      {
        "field": "recordDate",
        "headerText": "Record Date",
        "allowFiltering": false,
        "width": "120"
      },
      {
        "field": "Download",
        "headerText": "Download",
        "allowFiltering": false,
        "width": "100",
        "actions": true
      },
      {
        "field": "Actions",
        "headerText": "Actions",
        "allowFiltering": false,
        "width": "180",
        "actions": true
      },
    ]
    this.loadUserTable();
    this.settings = this.settingsService.getSettings();
    if (this.settings.finance_file_upload_sub_types) {
      this.fileTypes = this.settings.finance_file_upload_sub_types;
    }
  }

  getFile(event: any) {
    this.file = event.target.files[0];
  }
  checkStatus(id: any, durationInMilliSeconds = 15000,) {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
    }
    this.pollingSubscription = interval(durationInMilliSeconds)
      .pipe(
        switchMap(() => this.reconciliationService.getStatus(id)),
        takeWhile((response: any) => response && response.status !== 'Processed', true) // Continue until status is Processed
      )
      .subscribe(
        (response: any) => {
          if (response) {
            this.status = response.status;
            if (this.status === 'Processed' || this.status.includes('Delete')) {
              this.stopPolling();
              this.loadUserTable();
            } else {
              this.reportNameWithSpace = "We're almost there! Just fetching your data...";
            }
          }
        },
        (error) => {
          this.showSnackbar('Error while fetching status. Please try again later');
        }
      );
  }
  stopPolling() {
    if (this.pollingSubscription) {
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = null;
      this.isProcessing = false;
      this.processingRowId = null;
      this.isUploading = false;
      this.isDeleting = false;
    }
  }
  processItem(itemId: any) {
    this.processingRowId = itemId;
    this.reconciliationService.processFile({ id: itemId }).subscribe((res: any) => {
      this.showSnackbar('File processing triggered successfully');
      this.reportNameWithSpace = "Just a moment, we're compiling your insights..";
      if (!this.isDeleting) {
        this.isProcessing = true;
      }
      this.checkStatus(itemId);
    });
  }

  deleteItem(id: any) {
    this.reconciliationService.deleteFile(id).subscribe((res: any) => {
      this.showSnackbar('Workflow created successfully!');
      this.isDeleting = true;
      this.processItem(id);
    }, (error) => {
      this.showSnackbar("Failed to delete file.please try again later");
    });
  }
  loadUserTable() {
    this.isUploading = false;
    this.reconciliationService.getFiles().subscribe(res => {
      this.dataSource = res;
      this.reportNameWithSpace = "Channel-wise Finance MIS";
    })
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  fileUpload(event: any) {
    this.isUploading = false;
    if (this.file.name) {
      this.isUploading = true;
      this.reportNameWithSpace = "Just a moment...Your file is uploading!"
      this.reconciliationService.uploadFiles(this.file, this.authenticationService.getUserName(), "Finance", this.selectedOption).subscribe(res => {
        this.showSnackbar(`${this.file.name} uploaded Successfully`);
        this.loadUserTable();
        this.file = undefined;
        this.selectedOption = '';
      }, (error) => {
        this.showSnackbar(`Failed to upload ${this.file.name}`);

      })
    }
  }
  formatFileSize(size: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return size.toFixed(0) + ' ' + units[index];
  }
  formatDate(dateString?) {
    const dateObj = new Date(dateString);
    return this.datepipe?.transform(dateObj, 'dd-MMM hh:mm a');
  }
  addSpaces(str: string): string {
    if (str === 'filename') {
      return 'File Name'
    }
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  downloadFile(row) {
    this.uploadfiles.downloadFile(row.filename).subscribe(
      ({ blob, filename }) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
      },
      error => {
        console.error('Error downloading file:', error);
      }
    );
  }
  ngOnDestroy(): void {
    this.stopPolling();
  }
}
