import { Component , ChangeDetectorRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Distribution2Service } from 'src/app/services/distribution2.service';
import { MaterialsService } from 'src/app/services/materials.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-instore-config-molecule',
  templateUrl: './instore-config-molecule.component.html',
  styleUrls: ['./instore-config-molecule.component.scss']
})
export class InstoreConfigMoleculeComponent {
  structures: any[] = [];
  currentLevel: string = '';
  showCreateLevelInput = false;
  newLevelName: string = '';
  // newLevelAttribute: string = '';
  selectedAttribute = new FormControl('', Validators.required);
  metaData: any[] = [];
  dropdownOptions: { label: string; identifier: string }[] = [];
  constructor(private snackBar: MatSnackBar,private dialog: MatDialog,private distributionService: Distribution2Service, private router: Router, private materialService: MaterialsService,private cdr: ChangeDetectorRef ) { }
  ngOnInit(): void {
    this.getAllCategories();
    this.getMetaData();
  }
  getAllCategories() {
    this.distributionService.getDistributionConfigList().subscribe((data) => {
      this.structures = data;
      console.log('this.structures', this.structures)
      this.currentLevel = this.structures[data.length - 1].name; // later change to first object name because all are going to be root;
    });
  }
  getMetaData() {
    this.materialService.getMaterialForm().subscribe((response: any) => {
      this.metaData = response['metaData'].filter((item: any) => item.inStoreMerchandise);
      console.log(this.metaData, "metaData");
      
      this.dropdownOptions = this.metaData.map((item) => ({
        label: item.label,
        identifier: item.identifier,
      }));
    });
  }
  // deleteConfig(id : string) {
  
  //     this.distributionService.deleteDistributionConfigById(id).subscribe(
  //       response => {
  //         console.log('Deleted Successfully:', response);
  //       },
  //       error => {
  //         console.error('Error deleting:', error);
  //       }
  //     );
  //     this.getAllCategories();
  //      this.getMetaData();
    
  // }
  // deleteConfig(id: string) {
  //   if (confirm('Are you sure you want to delete this item?')) {
  //     this.distributionService.deleteDistributionConfigById(id).subscribe(
  //       response => {
  //         console.log('Deleted Successfully:', response);
  //         this.getAllCategories();
  //       }
  //     );
  //   }
  // }
  deleteConfig(id: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.distributionService.deleteDistributionConfigById(id).subscribe(
          response => {
            console.log('Deleted Successfully:', response);
            this.getAllCategories();
            this.snackBar.open('Item deleted successfully', 'Close', {
              duration: 3000, // The duration the snackbar will be visible
              panelClass: ['success-snackbar']
            });
          },
          error => {
            console.error('Error deleting:', error);
            this.snackBar.open('Error deleting item', 'Close', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        );
      }
    });
  }
  
  // createStructure() {
  //   const name = prompt('Enter Level Name:');
  //   const attribute = prompt('Enter Attribute (e.g., CategoryL1, CategoryL2):');
  //   console.log(name, attribute);
  //   const payload = {
  //     code: name,
  //     name: "root",
  //     attribute: attribute,
  //     children: [],
  //   }
  //   this.distributionService.saveDistributionConfig(payload).subscribe((response: any) => {
  //     console.log(response,"created structure")
  //     this.getAllCategories();
  //   })
  // }
  createStructure() {
    if (!this.newLevelName || !this.selectedAttribute.value) {
      alert('Please enter both Level Name and Attribute.');
      return;
    }
    const payload = {
      code: this.newLevelName,
      name: "root",
      attribute: this.selectedAttribute.value,
      children: [],
    };
    this.distributionService.saveDistributionConfig(payload).subscribe((response: any) => {
      console.log(response, "created structure");
      this.getAllCategories();
      // Reset inputs and hide form after submission
      this.newLevelName = '';
      // this.newLevelAttribute = '';
      this.selectedAttribute.setValue('')
      this.showCreateLevelInput = false;
    });
  }
  navigateToStructure(structure: any) {
    console.log(structure, "structure in navigation")
    this.router.navigate(['/instore-config', structure.id, structure.name]); // Navigate with ID & Name
  }
}